import {ACTA, B2B, QUARTER} from '../common/constants'
import {State} from '../useAppState'
import {useEffect, useState} from 'react'

export const isQuarterlyPayment = (appState: State, billingPeriod: string): boolean => {
    const urlParams = new URLSearchParams(window.location.search)
    const actaParam = urlParams.get(ACTA)
    const {
        applicationData: {featureConfig: {isQuarterlyPaymentEnabled = false} = {}} = {},
    } = appState
    const isB2B = actaParam ? actaParam.includes(B2B) : false
    return isQuarterlyPaymentEnabled && isB2B && billingPeriod === QUARTER
}

export const useQuarterlyPayment = (
    appState: State,
    subtotal: number,
    taxAmount: number,
    totalPrice: number,
    billingPeriod: string
) => {
    const [calculatedSubtotal, setCalculatedSubtotal] = useState<number | undefined>(undefined)
    const [calculatedTaxAmount, setCalculatedTaxAmount] = useState<number | undefined>(undefined)
    const [calculatedTotalPrice, setCalculatedTotalPrice] = useState<number | undefined>(undefined)

    useEffect(() => {
        const isNegativeSubTotal = (subtotal ?? 0) < 0
        const isQuarterly = isNegativeSubTotal ? false : isQuarterlyPayment(appState, billingPeriod)

        const calculateAmount = (amount?: number) => {
            return isQuarterly ? (amount ?? 0) / 4 : amount
        }

        setCalculatedSubtotal(calculateAmount(subtotal))
        setCalculatedTaxAmount(calculateAmount(taxAmount))
        setCalculatedTotalPrice(calculateAmount(totalPrice))
    }, [appState, subtotal, taxAmount, totalPrice])

    return {calculatedSubtotal, calculatedTaxAmount, calculatedTotalPrice}
}
