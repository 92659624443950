import React from 'react'
import {Routes, Route} from 'react-router-dom'
import {NotFoundErrorPage} from '@pluralsight/ps-design-system-errors'
import {AnalyticsPageView} from './Analytics-page-view'
import {PlanAdminAuth} from '../Router/Plan-Admin-Auth/PlanAdminAuth'
import {SubscriptionProvisioningAuth} from '../Router/Subscription-Provisioning-Auth/SubscriptionProvisioningAuth'
import {CacheProvisioningAuth} from '../Router/Cache-Provisioning-Auth/CacheProvisioningAuth'
import {useAppContext} from '../useAppState'
import {Subscription} from '../Subscription/Subscription'
import {AdminPlanList} from '../Plan/Admin-Plan-List'
import AccountNavBar from '../Account-Nav-Bar/Account-Nav-Bar'
import {IndividualAdmin} from '../Individual-Admin/Individual-Admin'
import {SetDefaultPlan} from '../Router/Set-Default-Plan/SetDefaultPlan'
import CpqExpand from '../Cpq-Expand/Cpq-Expand'
import Prism from '../Prism/Prism'
import TeamSupport from '../Team-Support/team-support'
import B2bUpgradeLanding from '../Upgrade-Landing/B2b-Upgrade-Landing'
import ContactSalesForm from '../Contact-Sales-Form/Contact-Sales-Form'
import styles from '../main-content.module.scss'
import Profitwell from '../Profitwell/Profitwell'
import B2bRoutesV2 from '../Router/B2B-RoutesV2'
import CpqExpired from '../Cpq-Expand/Cpq-Expired'

export function MainContent() {
    return (
        <AnalyticsPageView>
            <Profitwell>
                <Prism>
                    <Routes>
                        <Route path='' element={<InterstitialLandingPage />} />
                        <Route
                            path='plans/:planId/product/:productId/expand/cpq-expand'
                            element={
                                <PlanAdminAuth>
                                    <CpqExpand />
                                </PlanAdminAuth>
                            }
                        />
                        <Route
                            path='plans/:planId/cpq-expired'
                            element={
                                <PlanAdminAuth>
                                    <CpqExpired />
                                </PlanAdminAuth>
                            }
                        />
                        <Route
                            path='plans/:planId/contact-sales/renew'
                            element={
                                <PlanAdminAuth>
                                    <ContactSalesForm requestType={'renew'} />
                                </PlanAdminAuth>
                            }
                        />
                        <Route
                            path='plans/:planId/*'
                            element={
                                <PlanAdminAuth>
                                    <B2bRoutesV2 />
                                </PlanAdminAuth>
                            }
                        />
                        <Route path='plans' element={<SetDefaultPlan />} />
                        <Route
                            path='admin/individual/:handle'
                            element={
                                <SubscriptionProvisioningAuth>
                                    <IndividualAdmin />
                                </SubscriptionProvisioningAuth>
                            }
                        />
                        <Route
                            path='team/*'
                            element={
                                <CacheProvisioningAuth>
                                    <TeamSupport />
                                </CacheProvisioningAuth>
                            }
                        />
                        <Route path='business/upgrade' element={<B2bUpgradeLanding />} />
                        <Route
                            path='*'
                            element={<NotFoundErrorPage size={NotFoundErrorPage.sizes.large} />}
                        />
                    </Routes>
                </Prism>
            </Profitwell>
        </AnalyticsPageView>
    )
}

function InterstitialLandingPage() {
    const {state} = useAppContext()
    const showPlans = state.applicationData.planInfo.admin.length !== 0
    return (
        <div>
            <AccountNavBar />
            <div className={styles.appContent}>
                <div className={styles.sectionWrap}>
                    <Subscription />
                </div>
                {showPlans && <AdminPlanList />}
            </div>
        </div>
    )
}
