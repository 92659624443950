const trySetLastAccessedFromCachedPlans = (userHandle, planId) => {
  const userPlans = JSON.parse(localStorage.getItem(`userPlans_${userHandle}`))
  const newLastAccessedPlan =
    userPlans &&
    userPlans
      .map(plan => ({planId: plan.planId, displayName: plan.displayName}))
      .find(plan => plan.planId === planId)
  if (!newLastAccessedPlan) return null

  localStorage.setItem(
    `lastAccessedPlan_${userHandle}`,
    JSON.stringify(newLastAccessedPlan)
  )
  return newLastAccessedPlan
}

const isLocalEnvironment = () =>
  location.host.toLowerCase().indexOf('local') >= 0

const fetchLastAccessedPlan = async (
  userHandle,
  plansApiHost,
  contextName,
  planId = null,
  customHeaders = null
) => {
  const response = await fetch(`${plansApiHost}/users/plans`, {
    method: 'POST',
    headers: Object.assign(
      {},
      planId && {'Content-Type': 'application/json'},
      customHeaders
    ),
    body: planId
      ? JSON.stringify({data: {planId, clientName: {contextName}}})
      : undefined,
    credentials: isLocalEnvironment() ? 'include' : undefined
  })

  if (response.status !== 200) throw new Error('Request failed')

  const json = await response.json()
  sessionStorage.setItem(`hasFetchedLastAccessedPlan_${userHandle}`, 'true')
  localStorage.setItem(
    `lastAccessedPlan_${userHandle}`,
    JSON.stringify(json.data.currentPlan)
  )
  localStorage.setItem(
    `userPlans_${userHandle}`,
    JSON.stringify(json.data.plans)
  )
  return json.data.currentPlan
}

const getLastAccessedFromCache = userHandle => {
  const lastAccessedPlan = JSON.parse(
    localStorage.getItem(`lastAccessedPlan_${userHandle}`)
  )
  return lastAccessedPlan
}

const hasFetchedLastAccessedPlan = userHandle =>
  !!sessionStorage.getItem(`hasFetchedLastAccessedPlan_${userHandle}`)

export const getLastAccessedPlanId = async (
  userHandle,
  contextName,
  plansApiHost,
  customHeaders
) => {
  if (hasFetchedLastAccessedPlan(userHandle)) {
    const lastAccessedPlan = getLastAccessedFromCache(userHandle)
    return lastAccessedPlan ? lastAccessedPlan.planId : null
  }

  try {
    const currentPlan = await fetchLastAccessedPlan(
      userHandle,
      plansApiHost,
      contextName,
      customHeaders
    )
    return currentPlan ? currentPlan.planId : null
  } catch {
    const lastAccessedPlan = getLastAccessedFromCache(userHandle)
    return lastAccessedPlan ? lastAccessedPlan.planId : null
  }
}

export const updateLastAccessedPlan = async (
  planId,
  contextName,
  userHandle,
  plansApiHost
) => {
  const lastAccessedPlan = getLastAccessedFromCache(userHandle)
  if (
    hasFetchedLastAccessedPlan(userHandle) &&
    lastAccessedPlan.planId === planId
  )
    return lastAccessedPlan

  try {
    const currentPlan = await fetchLastAccessedPlan(
      userHandle,
      plansApiHost,
      contextName,
      planId
    )
    return currentPlan
  } catch {
    return trySetLastAccessedFromCachedPlans(userHandle, planId)
  }
}

export const getCachedPlans = userHandle =>
  JSON.parse(localStorage.getItem(`userPlans_${userHandle}`))
