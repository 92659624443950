import React from 'react'
import {bool} from 'prop-types'
import {CheckIcon} from '@pluralsight/ps-design-system-icon'
import * as accountSwitcherPropTypes from './prop-types'
import css from './plan.css'

export function Plan({plan, isSelected, displayLicenses}) {
  return (
    <div title={plan.displayName} className={css.planContainer}>
      <div className={css.planDetails}>
        <div className={css.planName}>{plan.displayName}</div>
        {displayLicenses && !!plan.totalLicenses && (
          <div className={css.licenses}>Licenses: {plan.totalLicenses}</div>
        )}
      </div>
      {isSelected ? (
        <div className={css.check}>
          <CheckIcon />
        </div>
      ) : null}
    </div>
  )
}

Plan.propTypes = {
  plan: accountSwitcherPropTypes.plan,
  isSelected: bool,
  displayLicenses: bool
}
