import {UserInfo} from '../User-Info/User-Info'
import {useAppContext} from '../useAppState'
import React, {useEffect, useState} from 'react'
import {DumbSubscription} from '../Subscription/Subscription'
import {CreateSubscriptionModal} from '../Create-Subscription-Modal/create-subscription-modal'
import {ExtendSubscriptionModal} from '../Extend-Subscription/Extend-Subscription-Modal'
import {CallToAction} from '../Subscription/CallToAction'
import {useParams} from 'react-router-dom'
import {api} from '../api/api'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import {FreeSubscriptionType} from '../Create-Subscription-Modal/FreeSubscriptionTypeEnum'
import Banner from '@pluralsight/ps-design-system-banner'
import AccountNavBar from '../Account-Nav-Bar/Account-Nav-Bar'
import Link from '@pluralsight/ps-design-system-link'
import {ParamTypes} from '../../../definitions/param-types'
import {hasAutoRenew} from '../Subscription/has-auto-renew'
import {SupportUserData} from '../../../definitions/support-user-data'
import {getNextChargeDate} from '../Subscription/get-next-charge-date'
import styles from './Individual-Admin.module.scss'

export function IndividualAdmin() {
    const {state, setters} = useAppContext()
    const [showCreateSubModal, setShowCreateSubModal] = useState(false)
    const [showExtendModal, setShowExtendModal] = useState(false)
    let {handle} = useParams() as ParamTypes
    const [info, setInfo] = useState({} as SupportUserData)
    const [showLoadingDecorator, setShowLoadingDecorator] = useState(true)

    useEffect(() => {
        setters.hideCTAsForSupport(true)
        function getUserInfo() {
            api.get(`support/user-info/${handle}`)
                .then((res) => {
                    if (res.status.success) {
                        setInfo(res.data)
                        setShowLoadingDecorator(false)
                    } else {
                        console.error(res.status.errorMessage)
                        setters.setAppHasError(true)
                    }
                    setShowLoadingDecorator(false)
                })
                .catch((error) => {
                    console.error(error)
                    setters.setAppHasError(true)
                })
        }
        getUserInfo()
        return () => {
            setters.hideCTAsForSupport(false)
        }
    }, [state.reRenderSupportTooling, state.supportToolingError, handle, setters])

    function mainContent() {
        const subscriptions = info.subscriptionAccount.subscriptions
        const products = info.productState.products
        const isPlanMember = info.planMemberInfo.isMember
        const planName = info.planMemberInfo.displayName
        const isAdmin = info.isPlanAdmin
        const hasFullLibrarySubscription =
            subscriptions.filter((s: any) => s.fullLibrarySubscription).length > 0
        const allowSubCreation = !isPlanMember && !hasFullLibrarySubscription
        const hasSubscription = subscriptions.length > 0
        const autoRenew = hasAutoRenew(subscriptions)
        const nextChargeDate = getNextChargeDate(subscriptions)

        return (
            <div>
                <AccountNavBar />
                <div className={styles.appContent}>
                    {state.supportToolingError && (
                        <div className={styles.row}>
                            <Banner color={Banner.colors.red}>{state.supportToolingError}</Banner>
                        </div>
                    )}
                    <div className={styles.row}>
                        <UserInfo
                            handle={info.user.handle}
                            email={info.user.email}
                            firstName={info.user.firstName}
                            lastName={info.user.lastName}
                        />
                    </div>
                    <div className={styles.row}>
                        <DumbSubscription
                            memberInfo={{isMember: isPlanMember, planName: planName}}
                            products={products}
                            isAdmin={isAdmin}
                            autoRenew={autoRenew}
                            nextChargeDate={nextChargeDate}
                        />
                    </div>
                    {hasSubscription && (
                        <div className={`${styles.row} ${styles.extend}`}>
                            <Link>
                                <div onClick={() => setShowExtendModal(true)}>
                                    Extend subscription
                                </div>
                            </Link>
                        </div>
                    )}
                    {allowSubCreation && (
                        <div className={styles.button}>
                            <div className={styles.content}>
                                <CallToAction
                                    buttonText={'Create Subscription'}
                                    onClick={() => setShowCreateSubModal(true)}
                                />
                            </div>
                        </div>
                    )}
                    {showExtendModal && (
                        <ExtendSubscriptionModal
                            extendSubscription={(expirationDate, notes) =>
                                extendSubscription(expirationDate, notes)
                            }
                            handleToggleModal={() => setShowExtendModal(false)}
                            userName={info.user.username}
                            products={products.filter((p: any) => p.inTerm)}
                        />
                    )}
                    {showCreateSubModal && (
                        <CreateSubscriptionModal
                            createSubscription={(subscriptionType, expirationDate, notes) =>
                                createSubscription(subscriptionType, expirationDate, notes)
                            }
                            handleToggleModal={() => setShowCreateSubModal(false)}
                            userName={info.user.username}
                        />
                    )}
                </div>
            </div>
        )
    }

    function extendSubscription(expirationDate: string, notes: string) {
        const body = {
            newExpirationDate: expirationDate,
            note: notes,
        }

        return new Promise((resolve: any, reject: any) => {
            api.post(`support/${handle}/extend`, body)
                .then((res) => {
                    if (res.status.success) {
                        resolve(res)
                    } else {
                        console.error(res.status.errorMessage)
                        reject(res.status.errorMessage)
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
        })
    }

    function createSubscription(
        subscriptionType: FreeSubscriptionType,
        expirationDate: string,
        notes: string
    ) {
        const body = {
            origin: 'lifecycle-admin',
            accountInformation: {
                handle: handle,
            },
            subscriptionInformation: {
                notes: notes,
                expirationDate: expirationDate,
                products: [
                    {
                        productOptionId: subscriptionType,
                        quantity: 1,
                    },
                ],
            },
        }
        return new Promise((resolve: any, reject: any) => {
            api.post(`internal-api/no-payment-checkout`, body)
                .then((res) => {
                    if (res.status.success) {
                        resolve(res)
                    } else {
                        console.error(res.status.errorMessage)
                        reject(res.status.errorMessage)
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
        })
    }

    function loadingDecoratorCheck() {
        return showLoadingDecorator ? <CenteredLoadingDecorator /> : mainContent()
    }
    return !!handle ? loadingDecoratorCheck() : <div>Handle required</div>
}
