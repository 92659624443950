import {useUpgradeContext} from './Upgrade-Context'
import React from 'react'
import styles from '../expand-v2/Expand-Terms-And-Conditions.module.scss'
import {ANNUAL, QUARTER} from '../common/constants'

export function UpgradeTermsAndConditions(props: {lowOnDark?: boolean}) {
    const taxText =
        '✝✝ Pluralsight is required by law to collect sales tax on purchases in some states. The actual tax amount will be calculated based on the applicable state and local sales tax when your order is processed.'

    let {state} = useUpgradeContext()
    let {productBillingPeriod = ''} = state

    const billingCycleText = `✝✝✝ You agree to be billed per ${
        productBillingPeriod === QUARTER ? QUARTER.toLowerCase() : ANNUAL
    }. Your subscription will automatically renew unless you choose to cancel it.`
    function getUpgradeText(renewDate: string) {
        return `✝ Price has been pro-rated for the remainder of your plan, based on the agreed upon price per subscription. All Business Professional subscriptions for this plan will renew on ${renewDate}, unless you cancel before then.`
    }
    return (
        <div className={`${styles.termsAndConditions} ${props.lowOnDark ? styles.lowOnDark : ''}`}>
            <div>{getUpgradeText(state.currentSubscription.subscriptionEndDate)}</div>
            <div>{taxText}</div>
            <div>{billingCycleText}</div>
        </div>
    )
}
