import * as React from 'react'
import {formatPrice} from '../../common/utils/formatPrice'
import {useRenewState} from '../Renew-Context/renew-context'
import '../styles/thank-you.scss'
import {useAppContext} from '../../useAppState'
import {useQuarterlyPayment} from '../../utils/quarterly-payment'
import {autoApplyWallet} from '../../common/utils/autoApplyWallet'
import {calculateFinalPrice} from '../../common/utils/calculateFinalPrice'
import {getLineItemIndexOfRenewingToSku} from '../../common/utils/renew-utils'
import {shouldShowBulkDiscount} from '../../utils/bulk-discount'
import {
    BulkDiscountInfoIcon,
    DiscountProrationInfoIcon,
} from '../../common/Bulk-Discount/bulk-discount'

export default function OrderReceiptSummary() {
    const {state} = useRenewState()
    const currency = state.renew.data.cart.currencyCode
    const paymentMethodUsed = state.payment.savedPayments.filter(
        (data) => state.newPayment.paymentMethodId === data.id
    )[0]

    let lineItems
    const lineItem = state.renew.data.cart.cartItems.filter((cartItem) =>
        cartItem.hasOwnProperty('childItems')
    )
    const {currentProductSKU} = state.renew.data
    const lineItemIndex = getLineItemIndexOfRenewingToSku(currentProductSKU)
    lineItems = lineItem[lineItemIndex].childItems

    const {state: appState} = useAppContext()
    const {productBillingPeriod = ''} = appState
    const {tax = {data: {subTotal: 0, tax: 0, totalWithTax: 0}}} = state
    const {
        calculatedSubtotal = 0,
        calculatedTaxAmount = 0,
        calculatedTotalPrice = 0,
    } = useQuarterlyPayment(
        appState,
        tax?.data?.subTotal,
        tax?.data?.tax,
        tax?.data?.totalWithTax,
        productBillingPeriod
    )
    const totalPrice = calculatedSubtotal + calculatedTaxAmount
    const walletAmount = appState.walletAmount
    const walletFeature = appState.applicationData.featureConfig.enableWalletFeature
    const creditAmount =
        Number(walletAmount) > Number(totalPrice) ? Number(totalPrice) : Number(walletAmount)
    const isAutoApplyWallet = autoApplyWallet(walletAmount, totalPrice, walletFeature)
    const targetCustomerDiscountAmount = state.renew.data.cart.targetCustomerDiscountAmount
    const showBulkDiscount = shouldShowBulkDiscount(
        appState.applicationData.featureConfig.smoothDiscountingFlag,
        targetCustomerDiscountAmount,
        state.renew.data.currentProductSKU
    )
    const [isHoveringDiscount, setIsHoveringDiscount] = React.useState<boolean>(false)
    const [isHoveringSubtotal, setIsHoveringSubtotal] = React.useState<boolean>(false)

    return (
        <>
            <div className='receipt'>
                <h4 className='header'>Order summary</h4>
                <div className='summary'>
                    <table>
                        <tbody>
                            <tr>
                                <th>Plan</th>
                                <th>Licenses</th>
                                <th>Price</th>
                            </tr>
                            {lineItems.map((lineItem: any) => {
                                return formatLineItemProduct(lineItem, currency, showBulkDiscount)
                            })}
                        </tbody>
                    </table>
                </div>

                <hr />

                <div className='subTotal'>
                    <div className='label'>
                        {showBulkDiscount && (
                            <div>
                                Bulk Discount
                                {BulkDiscountInfoIcon(
                                    isHoveringDiscount,
                                    setIsHoveringDiscount,
                                    true
                                )}
                            </div>
                        )}
                        <div>
                            Subtotal
                            {showBulkDiscount &&
                                DiscountProrationInfoIcon(
                                    isHoveringSubtotal,
                                    setIsHoveringSubtotal,
                                    false,
                                    true
                                )}
                        </div>
                        <div>Tax</div>
                        {isAutoApplyWallet && <div>PS Wallet Credit Applied</div>}
                    </div>
                    <div className='values'>
                        {showBulkDiscount && (
                            <div>
                                {formatPrice(targetCustomerDiscountAmount)} {currency}
                            </div>
                        )}
                        <div>
                            {formatPrice(calculatedSubtotal)} {currency}
                        </div>
                        <div>
                            {formatPrice(calculatedTaxAmount || 0)} {currency}
                        </div>
                        {isAutoApplyWallet && (
                            <div>
                                -{formatPrice(creditAmount)} {currency}
                            </div>
                        )}
                    </div>
                </div>

                <hr />

                <div className='total'>
                    <div className='amountLabel'>Total</div>
                    <div className='amount'>
                        {isAutoApplyWallet
                            ? `${formatPrice(
                                  calculateFinalPrice(calculatedTotalPrice, Number(walletAmount))
                              )} ${currency}`
                            : calculatedTotalPrice === 0
                            ? '--'
                            : `${formatPrice(calculatedTotalPrice)} ${currency}`}
                    </div>
                </div>
            </div>
            {getPaymentMethodSummary(paymentMethodUsed)}
        </>
    )
}

function getPaymentMethodSummary(paymentMethod: any) {
    if (!paymentMethod) return
    const IsPayPal = paymentMethod.type === 'PayPal'
    return (
        <div className='paymentText'>
            {IsPayPal ? (
                <div>
                    Charged to PayPal account: <span>{paymentMethod.details.paypalEmail}</span>{' '}
                </div>
            ) : paymentMethod.type === 'ACH' ? (
                <div>
                    Charged to ACH Payment account:{' '}
                    <span style={{display: 'block'}}>{paymentMethod.details.id}</span>
                </div>
            ) : paymentMethod.type === 'BankTransfer' ? (
                <div>
                    Charged to SEPA Payment account:{' '}
                    <span style={{display: 'block'}}>{paymentMethod.details.id}</span>
                </div>
            ) : (
                <div>{`Charged to Visa ending in ${paymentMethod.details.cardNumber.slice(
                    -4
                )}`}</div>
            )}
        </div>
    )
}

function formatLineItemProduct(lineItem: any, currency: string, showBulkDiscount: boolean) {
    return (
        <tr key={lineItem.productId}>
            <td>{lineItem.productName}</td>
            <td>{lineItem.quantity}</td>
            <td>
                {formatPrice(showBulkDiscount ? lineItem.listPrice : lineItem.netPrice)} {currency}
            </td>
        </tr>
    )
}
