import React from 'react'
import {ReactComponent as Envelope} from '../../images/envelope.svg'
import styles from './contact-support.module.scss'

export default function ContactSupport() {
    return (
        <div className={styles.contactSupportContent}>
            <Envelope />
            <div className={styles.supportInfo}>
                <a href='https://help.pluralsight.com/help/contact-us'>Contact Support</a>
                <div className={styles.availability}>AVAILABLE 24/7</div>
            </div>
        </div>
    )
}
