import React from 'react'
import styles from './bulk-discount.module.scss'
import Tooltip from '@pluralsight/ps-design-system-tooltip'
import {Below} from '@pluralsight/ps-design-system-position'
import {ReactComponent as InfoIcon} from '../../images/bulk-discount-info-icon.svg'
import {BULK_DISCOUNT_TOOLTIP_TEXT, PRORATION_DISCOUNT_TOOLTIP_TEXT} from '../constants'

const BulkDiscountToolTip = (
    <div className={styles.tooltipTextContainer}>
        <Tooltip tailPosition={Tooltip.tailPositions.topCenter}>
            <p>{BULK_DISCOUNT_TOOLTIP_TEXT}</p>
        </Tooltip>
    </div>
)

const DiscountProrationToolTip = (isUpgrade: boolean) => (
    <div className={styles.tooltipTextContainer}>
        <Tooltip tailPosition={Tooltip.tailPositions.topCenter}>
            <p>{PRORATION_DISCOUNT_TOOLTIP_TEXT(isUpgrade)}</p>
        </Tooltip>
    </div>
)

export const BulkDiscountInfoIcon = (
    isHovering: boolean,
    setIsHovering: (enable: boolean) => void,
    isThankYouPage: boolean
) => {
    return (
        <Below show={BulkDiscountToolTip} when={isHovering}>
            <InfoIcon
                className={isThankYouPage ? styles.infoIconThankYou : styles.infoIconOrderSummary}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            />
        </Below>
    )
}

export const DiscountProrationInfoIcon = (
    isHovering: boolean,
    setIsHovering: (enable: boolean) => void,
    isUpgrade: boolean,
    isThankYouPage: boolean
) => {
    return (
        <Below show={DiscountProrationToolTip(isUpgrade)} when={isHovering}>
            <InfoIcon
                className={isThankYouPage ? styles.infoIconThankYou : styles.infoIconOrderSummary}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            />
        </Below>
    )
}
