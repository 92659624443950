import {ACTA, B2B} from '../constants'

export const autoApplyWallet = (
    walletAmount: Number | null,
    calculatedTotal: Number | undefined,
    walletFeature: boolean
) => {
    const urlParams = new URLSearchParams(window.location.search)
    const actaParam = urlParams.get(ACTA)

    const isB2B = actaParam ? actaParam.includes(B2B) : false
    return isB2B && walletFeature && Number(walletAmount) > 0 && Number(calculatedTotal) > 0
}
