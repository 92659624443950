import React from 'react'
import {colorsBlue} from '@pluralsight/ps-design-system-core'
import Link from '@pluralsight/ps-design-system-link'
import {ReactComponent as EnvelopeLogo} from '../../../src/images/envelope1.svg'
import {ReactComponent as PhoneLogo} from '../../../src/images/phone.svg'
import './Ps-Contact-Info.scss'

export default function PsContactInfo(prop: any) {
    const {firstName, lastName, email: contactEmail, phone} = prop.PluralsightContact
    const hasName = !!firstName || !!lastName
    const email = !!contactEmail
        ? contactEmail !== 'sfdc-admin@pluralsight.com' &&
          contactEmail !== 'mulesoft@pluralsight.com'
            ? contactEmail
            : 'sales@pluralsight.com'
        : 'sales@pluralsight.com'
    const hasNumber = !!phone
    return (
        <div className='psContact'>
            <div className='contactHeading'>Pluralsight Contact</div>
            <div className='contactContainer'>
                {hasName && (
                    <>
                        <div className='nameLabel'>Name</div>
                        <div className='contactName'>{`${firstName ?? ''}${
                            !!firstName && !!lastName ? ' ' : ''
                        }${lastName ?? ''}`}</div>
                    </>
                )}
                <div className='contactInfo'>
                    <div className='contactCell'>
                        <div style={{marginTop: '3px', marginRight: '14px'}}>
                            <EnvelopeLogo />
                        </div>
                        <Link>
                            <a href={`mailto:${email}`} style={{color: colorsBlue[6]}}>
                                {email}
                            </a>
                        </Link>
                    </div>
                    {hasNumber && (
                        <div className='contactCell'>
                            <span className='contactSeparator' />
                            <div style={{marginTop: '3px', marginRight: '14px'}}>
                                <PhoneLogo />
                            </div>
                            <div>{phone}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
