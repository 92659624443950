import React, {useEffect} from 'react'
import {ModifyErrorMessage} from '../../../../definitions/modify'
import GenericTransactionError from '../../common/Transaction-Error-Boundary/generic-transaction-error'
import TransactionErrorContent from '../../common/Transaction-Error-Boundary/transaction-error-content'
import UnpaidInvoice from '../../common/Unpaid-Invoice/Unpaid-Invoice'
import ContactSalesForm from '../../Contact-Sales-Form/Contact-Sales-Form'
import {config} from '../../environment-config'
import Pricing from '../../Upgrade-V2/Blocked-Upgrade/Pricing'
import {useAppContext} from '../../useAppState'
import {Navigate} from 'react-router'

export default function ModifyBlocked(props: {
    errorMessage: ModifyErrorMessage
    planId: string
    data: any
}) {
    const {state, actions} = useAppContext()
    let product = state?.businessAccountMap[props.planId]?.data?.productState.products.filter(
        (product) => product.productInfo.inTerm
    )[0]
    let productName = state?.businessAccountMap[props.planId]?.data?.productState.products.filter(
        (product) => !product.productInfo.inTerm
    )[0]
    let productQuantity = product?.productInfo?.quantity
    let previousProductName = productName?.productCatalogInfo?.product.name
    useEffect(() => {
        if (!!props.planId) {
            actions.loadBusinessAccount(props.planId)
        }
    }, [props.planId, actions])
    const pricingPage = `${config.www.url}/pricing/skills?type=business`
    const [open] = React.useState(true)
    switch (props.errorMessage) {
        case 'unpaid invoice':
            return (
                <TransactionErrorContent>
                    <UnpaidInvoice invoiceIds={props.data} message={'purchasing Add on'} />
                </TransactionErrorContent>
            )
        case 'cancelled subscription':
            window.location.assign(pricingPage)
            return <></>
        case 'no active subscription number':
        case 'no active subscription':
        case 'Add on in term':
        case 'multi year contract':
        case 'billing period not supported':
        case 'No products found':
            return <ContactSalesForm requestType={'upsell'} />
        case 'reseller':
            return (
                <Navigate
                    to={`${config.subscriptionLifecycle.browserPath}/plans/${props.planId}`}
                    replace
                />
            )
        case 'pilot subscription':
        case 'No in term base product':
        case 'expired subscription':
        case 'Free base Product':
            return (
                <Pricing
                    isOpen={open}
                    subscription={productQuantity}
                    previousProduct={previousProductName}
                    errorMessage={props.errorMessage}
                    requestType={'upsell'}
                />
            )
        default:
            return <GenericTransactionError />
    }
}
