import {useExpandContext} from './Expand-Context'
import React from 'react'
import styles from './Expand-Terms-And-Conditions.module.scss'
import {useAppContext} from '../useAppState'
import {ANNUAL, QUARTER} from '../common/constants'

export function ExpandTermsAndConditions(props: {lowOnDark?: boolean}) {
    const {state} = useExpandContext()
    const {state: appState} = useAppContext()
    const {productBillingPeriod = ''} = appState
    const taxText =
        '✝✝ Pluralsight is required by law to collect sales tax on purchases in some states. The actual tax amount will be calculated based on the applicable state and local sales tax when your order is processed.'

    const billingCycleText = `✝✝✝ You agree to be billed per ${
        productBillingPeriod === QUARTER ? QUARTER.toLowerCase() : ANNUAL
    }. Your subscription will automatically renew unless you choose to cancel it.`

    function getAutoRenewText(productName: string, renewDate: string) {
        return `✝ Price has been pro-rated for the remainder of your plan, based on the agreed upon price per subscription. All ${productName} subscriptions for this plan will renew on ${renewDate}, unless you cancel before then.`
    }

    return (
        <div className={`${styles.termsAndConditions} ${props.lowOnDark ? styles.lowOnDark : ''}`}>
            <div>
                {getAutoRenewText(
                    state.currentSubscription.productName,
                    state.currentSubscription.formattedAutoRenewDate
                )}
            </div>
            <div>{taxText}</div>
            <div>{billingCycleText}</div>
        </div>
    )
}
