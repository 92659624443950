import {arrayOf, bool, func, string} from 'prop-types'
import React, {useCallback, useEffect, useState} from 'react'
import {getCachedPlans, updateLastAccessedPlan} from './util'
import {AccountSwitcherDropdown} from './dropdown'
import * as accountSwitcherPropTypes from './prop-types'

import css from './account-switcher.css'

export function AccountSwitcher({
  currentPlan,
  displayLicenses = true,
  plans = [],
  onPlanChange = () => {}
}) {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpenClosed = () => setIsOpen(!isOpen)

  return (
    <div className={css.accountSwitcherContainer}>
      <AccountSwitcherDropdown
        currentPlan={currentPlan}
        isOpen={isOpen}
        displayLicenses={displayLicenses}
        onRequestToggleVisibility={toggleOpenClosed}
        onSelect={planId => {
          toggleOpenClosed()
          onPlanChange(planId)
        }}
        plans={plans}
      />
    </div>
  )
}

AccountSwitcher.propTypes = {
  currentPlan: accountSwitcherPropTypes.currentPlan,
  displayLicenses: bool,
  plans: arrayOf(accountSwitcherPropTypes.plan),
  onPlanChange: func
}

/**
 * AccountSwitcher component.
 *
 * @param {string} currentPlanId - The id of the current plan being viewed.
 * @param {string} userHandle - The userHandle of the current user.
 * @param {string} contextName - The name of the context(Bounded Context) in which this component is being used.
 * @param {string} [plansApiHost='https://app.pluralsight.com/plans-data'] - The base URL for the plans API. Defaults to 'https://app.pluralsight.com/plans-data'.
 * @param {function} [onPlanChange=() => {}] - A callback function that is triggered when the user switches plans. Defaults to an empty function.
 */
export function AccountSwitcherContainer({
  currentPlanId,
  userHandle,
  contextName,
  plansApiHost = 'https://app.pluralsight.com/plans-data',
  onPlanChange = () => {}
}) {
  const [currentPlan, setCurrentPlan] = useState(null)
  const [plans, setPlans] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (currentPlanId && userHandle) {
      updateLastAccessedPlan(
        currentPlanId,
        contextName,
        userHandle,
        plansApiHost
      )
        .then(newPlan => {
          if (newPlan) {
            setCurrentPlan(newPlan)
            setPlans(getCachedPlans(userHandle))
          }
          setIsLoading(false)
        })
        .catch(() => setIsLoading(false))
    }
  }, [userHandle, currentPlanId, plansApiHost])

  const handlePlanChange = useCallback(
    planId => {
      return updateLastAccessedPlan(
        planId,
        contextName,
        userHandle,
        plansApiHost
      ).then(newPlan => {
        if (newPlan) {
          setCurrentPlan(newPlan)
          setPlans(getCachedPlans(userHandle))
          onPlanChange(newPlan.planId)
        }
      })
    },
    [userHandle, plansApiHost, onPlanChange]
  )

  if (currentPlanId && userHandle && contextName && !isLoading) {
    return (
      <AccountSwitcher
        currentPlan={currentPlan}
        plans={plans}
        onPlanChange={handlePlanChange}
      />
    )
  }

  return null
}

AccountSwitcherContainer.propTypes = {
  currentPlanId: string.isRequired,
  userHandle: string,
  contextName: string.isRequired,
  plansApiHost: string.isRequired,
  onPlanChange: func
}
