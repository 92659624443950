import * as React from 'react'
import {useEffect} from 'react'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../definitions/param-types'
import RenewUnBlocked from './Renew-Unblocked/renew-unblocked'
import {useRenewState} from './Renew-Context/renew-context'
import {useAsyncError} from '../useAsyncError'
import ProcessRenew from './Thank-You-Page/process-renew'
import {LinearLoadingDecorator} from '../Loading-Decorator/Linear-Loading-Decorator'
import {useAppContext} from '../useAppState'
import {handleRenew} from './Renew-Context/renewHandlers'

export default function Renew() {
    const {state, setter, actions} = useRenewState()
    const {state: appState, actions: appActions} = useAppContext()
    let {planId} = useParams() as ParamTypes
    let {renewQuantity} = useParams() as ParamTypes
    const throwError = useAsyncError()
    let {subscriptionId} = useParams() as ParamTypes
    const urlParams = new URLSearchParams(window.location.search)
    const shouldUpgrade = urlParams.get('shouldUpgrade') === 'true'
    useEffect(() => {
        if (!!planId) {
            appActions.loadBusinessAccount(planId)
        }
    }, [planId, appActions])

    const account = appState.businessAccountMap[planId]

    const baseProduct = account?.data?.productState?.products.filter(
        (p) => p.productCatalogInfo.product.category === 'Base Products'
    )
    const isPilot = baseProduct && baseProduct[0]?.productCatalogInfo?.productOption?.freeTrial
    // if there is an interm product, take its quantity rather than the first base products quantity, since the first could be expired as well
    const quantity =
        baseProduct?.length &&
        (baseProduct.find((p) => p.productInfo.inTerm)?.productInfo.quantity ||
            baseProduct[0]?.productInfo?.quantity)

    useEffect(() => {
        if (!!quantity && typeof isPilot !== 'undefined') {
            actions
                .getSubscription(subscriptionId, planId)
                .then((data) => {
                    const subscriptionId = data.subscriptions[0].subscriptionId
                    const SKU = data.subscriptions[0].productSKU
                    const contractId = data.subscriptions[0].contractId
                    const isB2BPilot = isPilot
                    const additionalQuantity = Number(renewQuantity)

                    handleRenew({
                        actions,
                        planId,
                        contractId,
                        quantity,
                        SKU,
                        subscriptionId,
                        isB2BPilot,
                        additionalQuantity,
                        shouldUpgrade,
                        setter,
                    })
                })
                .catch((e) => {
                    throwError(e.message)
                })
        }
    }, [setter, actions, throwError, subscriptionId, planId, isPilot, renewQuantity, quantity])
    return (
        <>
            {!state.renew.isInitialized ? (
                state.renewError ? (
                    <>
                        <ProcessRenew />
                    </>
                ) : (
                    <>
                        <LinearLoadingDecorator centerVertically={true} setDefault={1} />
                    </>
                )
            ) : (
                <>{<RenewUnBlocked />}</>
            )}
        </>
    )
}
