import React, {ReactNode, useEffect} from 'react'
import {useExpandContext} from './Expand-Context'
import {useParams} from 'react-router'
import {useAppContext} from '../useAppState'
import {formatDate} from '../utils/date-utils/format-date'
import {ParamTypes} from '../../../definitions/param-types'

export default function ExpandDataLoaderV2(props: {children: ReactNode}) {
    const {state: appState, actions: appActions} = useAppContext()
    const {state, actions, setters} = useExpandContext()
    const walletFeature = appState.applicationData.featureConfig.enableWalletFeature
    const walletAmount = appState.walletAmount

    let {subscriptionId} = useParams() as ParamTypes
    let {planId} = useParams() as ParamTypes

    useEffect(() => {
        const hasPlanId = !!planId
        const hasGuardResponse = !!state.guardResponse
        const planIdMismatch = planId !== state.guardResponse?.planId
        if ((hasPlanId && !hasGuardResponse) || planIdMismatch) {
            actions.expandGuardCheck(planId)
        }
    }, [actions, planId, state.guardResponse])

    useEffect(() => {
        if (!!planId) {
            appActions.loadBusinessAccount(planId)
        }
    }, [planId, appActions])

    useEffect(() => {
        const today = new Date()
        const account = appState.businessAccountMap[planId]
        const accountLoaded = (account && account.loaded) || false
        if (!!planId && accountLoaded) {
            const inTermProducts = account.data.productState.products.filter(
                (p) =>
                    p.productCatalogInfo.product.category === 'Base Products' &&
                    p.productCatalogInfo.product.type === 'Skills' &&
                    today < new Date(p.productInfo.expiresAt)
            )
            let latestInTermProducts = inTermProducts.sort((a, b) =>
                a.productInfo.expiresAt > b.productInfo.expiresAt ? -1 : 1
            )

            const product = latestInTermProducts[0]
            let currentSubscription = {
                sfdcProductId: product.productCatalogInfo.productOption.sfdcProductId,
                isLoaded: true,
                currentSubscriptionQuantity: product?.productInfo.quantity | 0,
                currency: account.data.billingInfo.currency,
                productName: product?.productCatalogInfo.product.name,
                formattedAutoRenewDate: formatDate(product?.productInfo.expiresAt),
                pricePerLicense: product.productCatalogInfo.priceOption.price,
            }
            setters.setCurrentSubscription(currentSubscription)
        }
    }, [planId, setters, appState])

    useEffect(() => {
        if (!!subscriptionId) {
            actions.getSubscription(subscriptionId, planId)
        }
    }, [planId, subscriptionId, actions])

    useEffect(() => {
        if (!!planId && !state.paymentMethodsInitialized) {
            actions.loadPaymentMethods(planId)
        }
    }, [planId, state.paymentMethodsInitialized, actions])

    useEffect(() => {
        if (!!state.paymentAddress.addressLine1) {
            // const pricePerLicence =
            //     state.pricePerLicense || state.currentSubscription.pricePerLicense
            const taxRequest = {
                userCurrency: state.currentSubscription.currency,
                cartItems: [
                    {
                        sku: state.currentSubscription.sfdcProductId,
                        quantity: state.quantityToAdd, // should this be total?
                        subTotal: state.subTotal,
                    },
                ],
                shipTo: {
                    streetAddress: state.paymentAddress.addressLine1,
                    city: state.paymentAddress.city,
                    stateProvince: state.paymentAddress.stateProvince,
                    country: state.paymentAddress.country,
                    postalCode: state.paymentAddress.postalCode,
                },
            }
            if (!!state.subTotal) {
                actions.getTax(taxRequest, planId)
            }
        }
    }, [
        state.paymentAddress,
        state.currentSubscription,
        state.quantityToAdd,
        state.pricePerLicense,
        state.currentSubscription.pricePerLicense,
        state.subTotal,
        actions,
        planId,
    ])

    useEffect(() => {
        const zuoraId: string | null = state.subscription.zuoraId || null
        if (zuoraId && walletFeature) {
            appActions.loadWalletAmount(zuoraId)
        }
    }, [walletFeature, state.subscription.zuoraId])

    useEffect(() => {
        setters.setPlanId(planId)
        setters.setHandle(appState.applicationData.user.handle)
    }, [planId, setters, subscriptionId, appState.applicationData.user.handle])

    return <div>{props.children}</div>
}
