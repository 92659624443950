import React, {useState} from 'react'
import {UpgradePost, useUpgradeContext} from './Upgrade-Context'
import {useAppContext} from '../useAppState'
import Button from '@pluralsight/ps-design-system-button'
import {config} from '../environment-config'
import styles from './upgrade-thank-you-page.module.scss'
import {PaymentMethod} from '../../../definitions/payment-methods'
import {Heading} from '@pluralsight/ps-design-system-text'
import GenericUpgradeError from './Blocked-Upgrade/Generic-Upgrade-Error'
import {upgradeLineItemUnitPrice} from './upgrade-line-items'
import getUpgradedProductName from '../utils/upgrade-product-name'
import {isQuarterlyPayment, useQuarterlyPayment} from '../utils/quarterly-payment'
import {QUARTER, TOTAL, TOTAL_ANNUAL, TOTAL_QUARTERLY} from '../common/constants'
import UpgradeWalletMessage from './Upgrade-Wallet-Message'
import {autoApplyWallet} from '../common/utils/autoApplyWallet'
import {calculateFinalPrice} from '../common/utils/calculateFinalPrice'
import Banner from '@pluralsight/ps-design-system-banner'
import DisplayPriceLockMessage from './Upgrade-PriceLock-Message'
import {shouldShowBulkDiscount} from '../utils/bulk-discount'
import {
    BulkDiscountInfoIcon,
    DiscountProrationInfoIcon,
} from '../common/Bulk-Discount/bulk-discount'

export default function UpgradeThankYouPage() {
    const {state} = useUpgradeContext()
    const {state: appState} = useAppContext()
    const {firstName, email} = appState.applicationData.user
    const {productName} = state.currentSubscription
    const quantity = state.planSubscriptionQuantity
    const viewInvoiceLink = `${config.billing.url}/billing/history`
    const backToSubscriptionLink = `/subscription/plans`
    const upgrade = {...state.upgrade, taxAmount: state.tax?.tax}
    let upgradeUnitPrice = upgradeLineItemUnitPrice(state.upgradePreview.lineItems)
    const currentUnitPrice = state.upgradePreview.currentPlan?.netPrice
    const paymentMethodUsed = state.existingPaymentMethods.filter(
        (paymentMethod) => paymentMethod.id === state.paymentMethodId
    )[0]
    const upgradeSuccess = state.upgrade.loaded
    const showNewProducts = appState?.applicationData?.featureConfig?.acgiIntegrationActive
    const upgradeProductName = getUpgradedProductName(productName, showNewProducts)
    const {displayPsWallet} = state.upgradePreview
    const walletFeature = appState?.applicationData?.featureConfig?.enableWalletFeature
    const targetCustomerDiscountAmount = state.targetCustomerDiscountAmount
    const showBulkDiscount = shouldShowBulkDiscount(
        appState?.applicationData?.featureConfig?.smoothDiscountingFlag,
        targetCustomerDiscountAmount,
        state?.currentSubscription?.productSKU
    )

    if ((walletFeature && displayPsWallet) || state.upgradePreview.isB2BLegacyUpgrade) {
        // Considering upgrade to new p&p flow, display list price
        upgradeUnitPrice = state.upgradePreview.newPlan?.netPrice
    }

    const isPriceLockUpgrade =
        state.upgradePreview.isB2BLegacyUpgrade && state.upgradePreview.amount === 0
    const isWalletCredit = state.upgradePreview.amount < 0

    return (
        <>
            {upgradeSuccess ? (
                <div className={styles.layout}>
                    <Heading size={Heading.sizes.large} className={styles.heading}>
                        <h2>Thank you, {firstName}!</h2>
                    </Heading>
                    <div className={styles.subHeading}>
                        Your plan has been upgraded to <b>{upgradeProductName}</b>.
                    </div>
                    <div className={styles.buttonRow}>
                        <div className={styles.button}>
                            <Button appearance={Button.appearances.stroke} href={viewInvoiceLink}>
                                View Invoice
                            </Button>
                        </div>
                        <div className={styles.button}>
                            <Button
                                appearance={Button.appearances.primary}
                                href={backToSubscriptionLink}
                            >
                                Back to Subscription
                            </Button>
                        </div>
                    </div>
                    <hr className={styles.ruling} />
                    <div className={styles.emailDisplay}>
                        Your full receipt has been sent to {email}
                    </div>
                    {state.upgradePreview.isB2BLegacyUpgrade && state.upgradePreview.amount === 0 && (
                        <div className={styles.priceLockMessage}>
                            <Banner>
                                {DisplayPriceLockMessage(
                                    state?.currentSubscription?.subscriptionEndDate
                                )}
                            </Banner>
                        </div>
                    )}
                    <OrderSummary
                        upgrade={upgrade}
                        quantity={quantity}
                        productName={productName}
                        upgradeProductName={upgradeProductName}
                        currentUnitPrice={currentUnitPrice}
                        upgradeUnitPrice={upgradeUnitPrice}
                        billingPeriod={state.productBillingPeriod}
                        displayPsWallet={displayPsWallet}
                        showBulkDiscount={showBulkDiscount}
                        targetCustomerDiscount={targetCustomerDiscountAmount}
                    />
                    {!isPriceLockUpgrade && !isWalletCredit && (
                        <PaymentMethodFormat paymentMethod={paymentMethodUsed} />
                    )}
                </div>
            ) : (
                <GenericUpgradeError />
            )}
        </>
    )
}

function PaymentMethodFormat(props: {paymentMethod: PaymentMethod}) {
    if (props.paymentMethod.type === 'PayPal') {
        return (
            <div className={styles.note}>
                Charged to PayPal account:{' '}
                <span style={{display: 'block'}}>props.paymentMethod.details.paypalEmail</span>
            </div>
        )
    } else if (props.paymentMethod.type === 'ACH') {
        return (
            <div className={styles.note}>
                Charged to ACH Payment account:{' '}
                <span style={{display: 'block'}}>{props.paymentMethod.id}</span>
            </div>
        )
    } else if (props.paymentMethod.type === 'BankTransfer') {
        return (
            <div className={styles.note}>
                Charged to SEPA Payment account:{' '}
                <span style={{display: 'block'}}>{props.paymentMethod.id}</span>
            </div>
        )
    } else {
        return (
            <div className={styles.note}>
                Charged To Visa ending in {props.paymentMethod.details.cardNumber.slice(-4)}
            </div>
        )
    }
}

function OrderSummary(props: {
    upgrade: UpgradePost
    quantity: number
    productName: string
    upgradeProductName: string
    currentUnitPrice: number
    upgradeUnitPrice: number
    billingPeriod?: string
    displayPsWallet: boolean
    showBulkDiscount: boolean
    targetCustomerDiscount: number
}) {
    const {state: appState} = useAppContext()
    const billingPeriod = props.billingPeriod
    const {
        calculatedSubtotal = 0,
        calculatedTaxAmount = 0,
        calculatedTotalPrice = 0,
    } = useQuarterlyPayment(
        appState,
        props.upgrade?.subtotal ?? 0,
        props.upgrade.taxAmount ?? 0,
        props.upgrade.amount ?? 0,
        billingPeriod ?? ''
    )
    const newPlanUnitPrice = props.upgradeUnitPrice
    const currentPlanUnitPrice = props.currentUnitPrice
    const displayPsWallet = props.displayPsWallet
    const walletFeature = appState?.applicationData?.featureConfig?.enableWalletFeature
    const walletAmount = appState.walletAmount
    const totalPrice = calculatedSubtotal + calculatedTaxAmount
    const creditAmount = Number(walletAmount) > Number(totalPrice) ? totalPrice : walletAmount
    const isAutoApplyWallet = autoApplyWallet(walletAmount, totalPrice, walletFeature)
    const [isHoveringDiscount, setIsHoveringDiscount] = useState<boolean>(false)
    const [isHoveringSubtotal, setIsHoveringSubtotal] = useState<boolean>(false)

    return (
        <div className={styles.orderSum}>
            <div className={styles.orderSummaryHeading}>Order summary</div>
            <div className={styles.row}>
                <div>Plan</div>
                <div>Licenses</div>
                <div>Price</div>
            </div>
            <div className={styles.row}>
                <p>
                    <span style={{color: '#aaaaaa'}}>
                        {walletFeature && displayPsWallet ? 'New Plan: ' : ''}
                    </span>
                    {props.upgradeProductName}
                </p>
                <p className={styles.second}>{props.quantity}</p>
                <p>
                    {formatMoney(newPlanUnitPrice)} {props.upgrade.currency}
                </p>
            </div>
            {walletFeature && displayPsWallet && (
                <div className={styles.row}>
                    <p>
                        <span style={{color: '#aaaaaa'}}>Old Plan: </span>
                        {props.productName}
                    </p>
                    <p className={styles.second}>{props.quantity}</p>
                    <p>
                        {formatMoney(currentPlanUnitPrice)} {props.upgrade.currency}
                    </p>
                </div>
            )}

            <hr className={styles.ruling1} />

            <div className={styles.midrow}>
                {props.showBulkDiscount && (
                    <>
                        <div className={styles.first}>
                            Bulk Discount
                            {BulkDiscountInfoIcon(isHoveringDiscount, setIsHoveringDiscount, true)}
                        </div>
                        <div>
                            {formatMoney(props.targetCustomerDiscount)} {props.upgrade.currency}
                        </div>
                    </>
                )}
                <div className={styles.first}>
                    Subtotal
                    {props.showBulkDiscount &&
                        DiscountProrationInfoIcon(
                            isHoveringSubtotal,
                            setIsHoveringSubtotal,
                            true,
                            true
                        )}
                </div>
                <div>
                    {formatMoney(calculatedSubtotal)} {props.upgrade.currency}
                </div>
                <div className={styles.first}>
                    Tax<span className={styles.sym}>✝✝</span>
                </div>
                <div>
                    {formatMoney(calculatedTaxAmount)} {props.upgrade.currency}
                </div>
                {isAutoApplyWallet && (
                    <>
                        <div className={styles.first}>PS Wallet Credit Applied</div>
                        <div>
                            -{creditAmount?.toFixed(2)} {props.upgrade.currency}
                        </div>
                    </>
                )}
            </div>

            <hr className={styles.ruling2} />

            <div className={styles.total}>
                <div>
                    <div>
                        {isQuarterlyPayment(appState, billingPeriod ?? '')
                            ? billingPeriod === QUARTER
                                ? TOTAL_QUARTERLY
                                : TOTAL_ANNUAL
                            : TOTAL}
                    </div>
                </div>
                <div>
                    {isAutoApplyWallet
                        ? `${formatMoney(
                              calculateFinalPrice(calculatedTotalPrice, Number(walletAmount))
                          )} ${props.upgrade.currency}`
                        : calculatedTotalPrice ?? 0}{' '}
                    {props.upgrade.currency}
                </div>
            </div>
            {walletFeature && displayPsWallet && (
                <div className={styles.walletMessageDiv}>
                    <hr className={styles.ruling2} />
                    <UpgradeWalletMessage
                        amount={calculatedTotalPrice}
                        currency={props.upgrade.currency}
                    />
                </div>
            )}
        </div>
    )
}

function formatMoney(amount: number) {
    return amount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
