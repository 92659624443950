import {Integrations} from '../Integrations/Integrations'
import Billing from '../Billing/Billing'
import React, {useEffect} from 'react'
import {Routes, Route, useParams} from 'react-router-dom'
import B2bNavBar from '../B2b-Nav-Bar/B2b-Nav-Bar'
import './B2b-Container.scss'
import {ParamTypes} from '../../../definitions/param-types'
import {useAppContext} from '../useAppState'
import {AdminPlanV2} from '../Plan/Admin-PlanV2'
import CpqExpired from '../Cpq-Expand/Cpq-Expired'

export default function B2bRoutesV2() {
    let {planId} = useParams() as ParamTypes
    const {state} = useAppContext()

    useEffect(() => {
        if (state.PrismInited) {
            window.Prism.update({
                currentPlanId: planId,
            })
        }
    }, [state.PrismInited, planId])

    return (
        <div className='b2bContainer'>
            <div>{!state.subscribeRoute ? <B2bNavBar /> : <></>}</div>
            <div>
                <Routes>
                    <Route
                        path={'*'}
                        element={!state.subscribeRoute ? <AdminPlanV2 /> : <CpqExpired />}
                    />
                    <Route path='integrations' element={<Integrations />} />
                    <Route path='billing' element={<Billing />} />
                </Routes>
            </div>
        </div>
    )
}
