import {
    ACHPaymentButton,
    CreditCardPaymentButton,
    ExpiredCreditCardDisplay,
    PayPalPaymentButton,
    SEPAPaymentButton,
} from '../../Payment-Drawer/payment-method'
import React from 'react'
import {
    ACHPaymentMethod,
    CreditCardPaymentMethod,
    PaymentMethod as PaymentMethodType,
    PaypalPaymentMethod,
    SEPAPaymentMethod,
} from '../../../../definitions/payment-methods'
import styles from './Existing-Payment-Method-Selector.module.scss'

export function ExistingPaymentMethodsSelector(props: {
    existingPaymentMethods: PaymentMethodType[]
    selectFunction: (paymentMethodId: string) => void
    selectedPaymentId: string
}) {
    const creditCards: CreditCardPaymentMethod[] = props.existingPaymentMethods.filter(isCreditCard)
    const expiredCreditCards = creditCards.filter(isExpiredCreditCard)
    const hasExpiredCreditCard = expiredCreditCards.length > 0
    const expiredCardNumber = hasExpiredCreditCard
        ? getExpiredCard(expiredCreditCards).details.cardNumber
        : ''
    const activeCreditCards = creditCards.filter(isActiveCreditCard)
    const hasActiveCreditCards = activeCreditCards.length > 0
    const paypalPaymentMethods: PaypalPaymentMethod[] = props.existingPaymentMethods.filter(
        isPaypalPaymentMethod
    )
    const achPaymentMethods: ACHPaymentMethod[] = props.existingPaymentMethods.filter(
        isACHPaymentMethod
    )
    const sepaPaymentMethods: SEPAPaymentMethod[] = props.existingPaymentMethods.filter(
        isSEPAPaymentMethod
    )

    return (
        <div>
            <h4>Select a saved payment method</h4>
            <hr className={styles.existingPaymentHR} />
            <div className={styles.paymentRow}>
                {hasActiveCreditCards ? (
                    activeCreditCards.map((paymentMethod) => {
                        return (
                            <CreditCardPaymentButton
                                key={paymentMethod.id}
                                selectFunction={() => props.selectFunction(paymentMethod.id)}
                                selected={props.selectedPaymentId === paymentMethod.id}
                                cardNumber={paymentMethod.details.cardNumber}
                                expires={`${paymentMethod.details.expirationMonth}/${paymentMethod.details.expirationYear}`}
                            />
                        )
                    })
                ) : (
                    <ExpiredCreditCardDisplay
                        cardNumber={expiredCardNumber}
                        disabled={!hasActiveCreditCards}
                    />
                )}
                {paypalPaymentMethods.map((paypalPaymentMethod) => {
                    return (
                        <PayPalPaymentButton
                            key={paypalPaymentMethod.id}
                            selectFunction={() => props.selectFunction(paypalPaymentMethod.id)}
                            selected={props.selectedPaymentId === paypalPaymentMethod.id}
                            paypalEmail={paypalPaymentMethod.details.paypalEmail}
                        />
                    )
                })}
                {achPaymentMethods.map((achPaymentMethod) => {
                    return (
                        <ACHPaymentButton
                            key={achPaymentMethod.id}
                            selectFunction={() => props.selectFunction(achPaymentMethod.id)}
                            selected={props.selectedPaymentId === achPaymentMethod.id}
                            bankName={achPaymentMethod.details.achBankName}
                            bankAccountType={achPaymentMethod.details.achBankAccountType}
                            bankAccountNumber={achPaymentMethod.details.achBankAccountNumber}
                        />
                    )
                })}
                {sepaPaymentMethods.map((sepaPaymentMethod) => {
                    return (
                        <SEPAPaymentButton
                            key={sepaPaymentMethod.id}
                            selectFunction={() => props.selectFunction(sepaPaymentMethod.id)}
                            selected={props.selectedPaymentId === sepaPaymentMethod.id}
                            bankName={sepaPaymentMethod.details.bankInformation.bankName}
                            bankTransferType={
                                sepaPaymentMethod.details.bankInformation.bankTransferType
                            }
                            bankAccountName={
                                sepaPaymentMethod.details.bankInformation.bankTransferAccountName
                            }
                            bankAccountNumberMask={
                                sepaPaymentMethod.details.bankInformation
                                    .bankTransferAccountNumberMask
                            }
                        />
                    )
                })}
            </div>
        </div>
    )
}

function isCreditCard(paymentMethod: PaymentMethodType): paymentMethod is CreditCardPaymentMethod {
    return (
        paymentMethod.type === 'CreditCard' ||
        paymentMethod.type === 'CreditCardReferenceTransaction'
    )
}

function isExpiredCreditCard(creditCard: CreditCardPaymentMethod) {
    return getCreditCardExpirationDate(creditCard).getTime() < Date.now()
}

function isActiveCreditCard(creditCard: CreditCardPaymentMethod) {
    return getCreditCardExpirationDate(creditCard).getTime() > Date.now()
}

function getCreditCardExpirationDate(creditCard: CreditCardPaymentMethod): Date {
    return new Date(creditCard.details.expirationYear, creditCard.details.expirationMonth - 1, 0)
}

function getExpiredCard(expiredCreditCards: CreditCardPaymentMethod[]) {
    return (
        expiredCreditCards.filter((creditCard) => {
            return creditCard.default
        })[0] || expiredCreditCards[0]
    )
}

function isPaypalPaymentMethod(
    paymentMethod: PaymentMethodType
): paymentMethod is PaypalPaymentMethod {
    return paymentMethod.type === 'PayPal'
}

function isACHPaymentMethod(paymentMethod: PaymentMethodType): paymentMethod is ACHPaymentMethod {
    return paymentMethod.type === 'ACH'
}

function isSEPAPaymentMethod(paymentMethod: PaymentMethodType): paymentMethod is SEPAPaymentMethod {
    return (
        paymentMethod.type === 'BankTransfer' &&
        paymentMethod.details.bankInformation.bankTransferType === 'SEPA'
    )
}
