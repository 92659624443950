import {PaymentMethod} from '../../../../definitions/payment-methods'

export function getPaymentGatewayByPaymentMethod(paymentMethod: PaymentMethod) {
    if (paymentMethod.type === 'PayPal') {
        return getPaypalPaymentGateway()
    } else if (
        paymentMethod.type === 'CreditCardReferenceTransaction' &&
        (paymentMethod.details.cardHolderInfo.country.toUpperCase() === 'INDIA' ||
            paymentMethod.details.cardHolderInfo.country.toUpperCase() === 'IND' ||
            paymentMethod.details.cardHolderInfo.country.toUpperCase() === 'IN')
    ) {
        return 'Stripe India'
    } else {
        return 'Stripe'
    }
}

export function getPaymentGateway(paymentMethodType: string, country: string) {
    if (paymentMethodType === 'PayPal') {
        return getPaypalPaymentGateway()
    } else if (
        paymentMethodType === 'CreditCardReferenceTransaction' &&
        (country === 'INDIA' || country === 'IND' || country === 'IN')
    ) {
        return 'Stripe India'
    } else {
        return 'Stripe'
    }
}

function getPaypalPaymentGateway() {
    return 'PayPal Express Checkout'
}
