import React from 'react'
import Button from '@pluralsight/ps-design-system-button'
import styles from './blocked-upgrade.module.scss'
import {Link} from 'react-router-dom'
import ContactSupport from '../../common/Contact-Support/contact-support'

export default function GenericUpgradeError() {
    function BackToPlanButton() {
        return (
            <Link to={'/subscription/plans'}>
                <Button className={styles.mainCTAButton}>Back to Plan</Button>
            </Link>
        )
    }

    return (
        <div className={styles.errorPageLayout}>
            <div className={styles.errorMessage}>Oh no! It looks like something went wrong.</div>
            <div className={styles.subErrorMessage}>
                Please try to{' '}
                <button className={styles.refresh} onClick={() => window.location.reload()}>
                    refresh
                </button>{' '}
                this page. If that doesn’t work, reach out to our support team for help.
            </div>
            <ContactSupport />
            <BackToPlanButton />
        </div>
    )
}
