import React, {useState} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './DateTimePicker.css'
import Dialog from '@pluralsight/ps-design-system-dialog'
import Form from '@pluralsight/ps-design-system-form'
import Dropdown from '@pluralsight/ps-design-system-dropdown'
import TextArea from '@pluralsight/ps-design-system-textarea'
import Button from '@pluralsight/ps-design-system-button'
import {FreeSubscriptionType} from './FreeSubscriptionTypeEnum'
import {useAppContext} from '../useAppState'

export function CreateSubscriptionModal(props: Props) {
    const [notes, setNotes] = useState<string>()
    const [expirationDate, setExpirationDate] = useState<string>()
    const [subscriptionType, setSubscriptionType] = useState<FreeSubscriptionType>()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [subscriptionTypeError, setSubscriptionTypeError] = useState<boolean>(false)
    const [expirationDateError, setExpirationDateError] = useState<boolean>(false)
    const [notesError, setNotesError] = useState<boolean>(false)
    const {setters, state} = useAppContext()

    function formatDate(dateString: string) {
        const date = new Date(dateString)
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        }
        return date.toLocaleDateString('en-US', options)
    }

    function handleSubscriptionType(subscriptionType: FreeSubscriptionType) {
        setSubscriptionType(subscriptionType)
        setSubscriptionTypeError(!subscriptionType)
    }

    function handleSelect(date: string) {
        setExpirationDate(date)
        setExpirationDateError(!date)
    }

    function handleChange(date: string) {
        setExpirationDate(date)
        setExpirationDateError(!date)
    }

    function handleNotesChange(notes: string) {
        setNotes(notes)
        setNotesError(!notes)
    }

    async function handleSubmit() {
        let formattedExpirationDate
        if (expirationDate !== undefined) {
            formattedExpirationDate = formatDate(expirationDate)
        }
        if (!!formattedExpirationDate && !!subscriptionType && !!notes) {
            setIsSubmitting(true)
            try {
                const response = await props.createSubscription(
                    subscriptionType,
                    formattedExpirationDate,
                    notes
                )
                if (!!response.status.success) {
                    setIsSubmitting(false)
                    props.handleToggleModal()
                    setters.setSupportReRendering(!state.reRenderSupportTooling)
                    setters.setSupportToolingError(null)
                }
            } catch (err) {
                setIsSubmitting(false)
                props.handleToggleModal()
                setters.setSupportToolingError(err as string)
            }
        } else {
            setExpirationDateError(!formattedExpirationDate)
            setSubscriptionTypeError(!subscriptionType)
            setNotesError(!notes)
        }
    }

    function setupActionMenu() {
        return (
            <span>
                <Dropdown.Item
                    onClick={() => handleSubscriptionType(FreeSubscriptionType.INDIVIDUAL)}
                >
                    Individual
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => handleSubscriptionType(FreeSubscriptionType.INDIVIDUAL_PREMIUM)}
                >
                    Individual Premium
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => handleSubscriptionType(FreeSubscriptionType.CODE_SCHOOL_LEGACY)}
                >
                    Code School
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => handleSubscriptionType(FreeSubscriptionType.INDIVIDUAL_STANDARD)}
                >
                    Individual Standard
                </Dropdown.Item>
            </span>
        )
    }

    return (
        <Dialog modal onClose={props.handleToggleModal} aria-label={'create subscription form'}>
            <div style={{margin: 20}}>
                <Form.VerticalLayout onSubmit={handleSubmit}>
                    <label>UserName: {props.userName}</label>
                    <Dropdown
                        label='Subscription plan'
                        placeholder='None'
                        error={subscriptionTypeError}
                        subLabel={subscriptionTypeError ? 'Required' : ''}
                        disabled={isSubmitting}
                        menu={setupActionMenu()}
                        uniqueId={() => 'createSubsriptionModal'}
                    />
                    <div className='datepicker-title'>Expiration date</div>
                    <DatePicker
                        selected={expirationDate}
                        onSelect={handleSelect}
                        onChange={handleChange}
                        placeholderText='mm /dd /yyyy'
                        showYearDropdown
                        disabled={isSubmitting}
                        required={true}
                        error={expirationDateError}
                    />
                    <TextArea
                        style={{width: '100%'}}
                        label='Notes'
                        name='notes'
                        error={notesError}
                        subLabel={notesError ? 'Required' : ''}
                        value={notes}
                        disabled={isSubmitting}
                        onChange={(event: any) => handleNotesChange(event.target.value)}
                    />
                    <Button loading={isSubmitting} onClick={() => handleSubmit()}>
                        Create Subscription
                    </Button>
                </Form.VerticalLayout>
            </div>
        </Dialog>
    )
}

interface Props {
    handleToggleModal: () => any
    createSubscription: (
        subscriptionType: FreeSubscriptionType,
        expirationDate: string,
        notes: string
    ) => any
    userName: string
}
