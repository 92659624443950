import React from 'react'
import Button from '@pluralsight/ps-design-system-button'
import {Link} from 'react-router-dom'
import {ReactComponent as PluralSightHeader} from '../../images/pluralsight-logo-horizontal-white-outline.svg'
import styles from './generic-transaction-error.module.scss'
import ContactSupport from '../Contact-Support/contact-support'

export default function GenericTransactionError() {
    function BackToPlanButton() {
        return (
            <Link to={'/subscription/plans'}>
                <Button className={styles.mainCTAButton}>Back to Plan</Button>
            </Link>
        )
    }

    return (
        <div style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
            <div style={{margin: '15px'}}>
                <PluralSightHeader />
            </div>
            <div className={styles.errorPageLayout}>
                <div className={styles.errorMessage}>
                    Oh no! It looks like something went wrong.
                </div>
                <div className={styles.subErrorMessage}>
                    Please try to{' '}
                    <button className={styles.refresh} onClick={() => window.location.reload()}>
                        refresh
                    </button>{' '}
                    this page.If that doesn’t work, reach out to our support team for help.
                </div>
                <ContactSupport />
                <BackToPlanButton />
            </div>
        </div>
    )
}
