// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n  --prismTopbarHeight: 56px;\n  --prismButtonBackgroundColor: rgba(138, 153, 168, 0.15);\n  --prismIconFill: #aaa;\n  --zPrismHeader: 950;\n}\n\n._2RvQQs-8A-vDv8iWKLi9s5 {\n  position: relative;\n  display: inline-block;\n  --popup-z-index: 1000;\n  border-radius: 8px;\n}\n\n._2EuUmDZzI7TnYymTaPIMAW::-webkit-scrollbar {\n  width: 0.5em;\n}\n\n._2EuUmDZzI7TnYymTaPIMAW::-webkit-scrollbar-thumb {\n  background-color: #404376;\n  border-radius: 5px;\n  outline: none;\n}\n\n._2EuUmDZzI7TnYymTaPIMAW::-webkit-scrollbar-track {\n  background-color: #2d2d55;\n  outline: none;\n}\n\n.NPB7d5usuN4bn8ROaOrWu {\n  position: fixed;\n  height: 100vh;\n  width: 100vw;\n  top: 0;\n  left: 0;\n  background: transparent;\n  z-index: calc(var(--popup-z-index) - 1);\n  border-radius: 8px;\n}\n\n._3eHMFWS38XlcrUrAkJBpJ7 {\n  position: absolute;\n  width: -webkit-fill-available;\n  z-index: var(--popup-z-index);\n  padding: 4px 0px 0px 0px;\n  border-radius: 8px;\n  opacity: 0px\n}\n\n@media screen and (min-width: 1200px) {\n\n._3eHMFWS38XlcrUrAkJBpJ7 {\n}\n  }\n\n@media screen and (max-width: 1200px) {\n\n._3eHMFWS38XlcrUrAkJBpJ7 {\n}\n  }\n\n._2EuUmDZzI7TnYymTaPIMAW {\n  width: inherit;\n  max-height: calc(100vh - 150px);\n  overflow-y: auto;\n  overflow-x: hidden;\n  border-radius: 8px;\n  padding: 8px;\n}\n\n._2EuUmDZzI7TnYymTaPIMAW > li > a {\n  padding: 8px 8px 0 8px;\n}\n\n._2EuUmDZzI7TnYymTaPIMAW > li:hover {\n  background: rgb(218, 227, 238) !important;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_2RvQQs-8A-vDv8iWKLi9s5",
	"scrollContainer": "_2EuUmDZzI7TnYymTaPIMAW",
	"overlay": "NPB7d5usuN4bn8ROaOrWu",
	"popup": "_3eHMFWS38XlcrUrAkJBpJ7"
};
module.exports = exports;
