import {RenewSetters} from '../renew-setters'

export const handleRenew = async ({
    actions = null,
    planId = '',
    contractId = '',
    quantity = 0,
    SKU = '',
    subscriptionId = '',
    isB2BPilot = false,
    additionalQuantity = 0,
    shouldUpgrade = false,
    setter = null,
}: {
    actions: any
    planId: string
    contractId: string
    quantity: number
    SKU: string
    subscriptionId: string
    isB2BPilot: boolean
    additionalQuantity: number
    shouldUpgrade: boolean
    setter: any
}) => {
    if (!actions || !planId || !contractId || !SKU || !subscriptionId || !setter) {
        setter({type: RenewSetters.setRenewError, data: 'Invalid parameters provided'})
        return
    }

    try {
        const data = await actions.renew(
            planId,
            contractId,
            quantity,
            SKU,
            subscriptionId,
            isB2BPilot,
            additionalQuantity,
            shouldUpgrade
        )
        const {activityId: renewActivityId} = data
        if (!renewActivityId || !planId) {
            setter({
                type: RenewSetters.setRenewError,
                data: 'Renew activity ID or plan ID is missing',
            })
            return
        }
        await handleActivityId({
            actions,
            renewActivityId,
            planId,
            setter,
            quantity,
            additionalQuantity,
            SKU,
        })
    } catch (e) {
        setter({
            type: RenewSetters.setRenewError,
            data: (e as Error).message || 'An error occurred during renewal',
        })
    }
}

export const handleActivityId = async ({
    actions = null,
    renewActivityId = '',
    planId = '',
    setter = null,
    quantity = 0,
    additionalQuantity = 0,
    SKU = '',
}: {
    actions: any
    renewActivityId: string
    planId: string
    setter: any
    quantity: number
    additionalQuantity: number
    SKU: string
}) => {
    if (!actions || !renewActivityId || !planId || !setter) {
        setter({type: RenewSetters.setRenewError, data: 'Invalid parameters provided'})
        return
    }

    try {
        const data = await actions.activityId(renewActivityId, planId)
        const {cart = {}} = data
        const {account = {}, cartItems = [], currencyCode: currency = '', customerAmount = 0} = cart
        const {address: addressDetails = {}} = account
        const cartItem = cartItems
            ?.filter((cartItem: any) => cartItem.hasOwnProperty('childItems'))
            ?.map((cartItem: any) => {
                const {productCode: sku = '', quantity = 0, childItems = []} = cartItem
                const subTotal = childItems.reduce(
                    (acc: number, childItem: any) => acc + childItem.netTotal,
                    0
                )
                return {sku, quantity, subTotal}
            })
        if (data.status === 'ERROR') {
            setter({type: RenewSetters.setRenewError, data: 'Activity ID returned an error'})
        } else {
            setter({
                type: RenewSetters.initializeRenew,
                data: {
                    ...data,
                    quantity: quantity + additionalQuantity,
                    currentProductSKU: SKU,
                    subtotal: customerAmount,
                },
            })
        }
        await handleCountryCode({
            actions,
            addressDetails,
            currency,
            cartItem,
            planId,
            setter,
        })
    } catch (e) {
        setter({
            type: RenewSetters.setRenewError,
            data: (e as Error).message || 'An error occurred during activity handling',
        })
    }
}

export const handleCountryCode = async ({
    actions = null,
    addressDetails = {},
    currency = '',
    cartItem = [],
    planId = '',
    setter = null,
}: {
    actions: any
    addressDetails: any
    currency: string
    cartItem: any
    planId: string
    setter: any
}) => {
    if (!actions || !addressDetails || !currency || !planId || !setter) {
        setter({type: RenewSetters.setRenewError, data: 'Invalid parameters provided'})
        return
    }

    try {
        const countryCode = await actions.getCountryCode(addressDetails?.country)
        if (countryCode) {
            const {
                streetAddress = '',
                city = '',
                stateProvince = '',
                postalCode = '',
            } = addressDetails
            const body = {
                userCurrency: currency,
                cartItems: cartItem,
                shipTo: {
                    streetAddress,
                    city,
                    stateProvince,
                    country: countryCode,
                    postalCode,
                },
            }
            await calculateTax({
                actions,
                body,
                planId,
                setter,
            })
        }
    } catch (e) {
        setter({
            type: RenewSetters.setRenewError,
            data: (e as Error).message || 'An error occurred during country code handling',
        })
    }
}

export const calculateTax = async ({
    actions = null,
    body = {},
    planId = '',
    setter = null,
}: {
    actions: any
    body: any
    planId: string
    setter: any
}) => {
    if (!actions || !body || !planId || !setter) {
        setter({type: RenewSetters.setRenewError, data: 'Invalid parameters provided'})
        return
    }

    try {
        const data = await actions.calculateTax(body, planId)
        setter({type: RenewSetters.calculateTax, data})
    } catch (e) {
        setter({
            type: RenewSetters.setRenewError,
            data: (e as Error).message || 'An error occurred during tax calculation',
        })
    }
}
