import React, {useEffect, useState} from 'react'
import Link from '@pluralsight/ps-design-system-link'
import {useAppContext} from '../useAppState'
import {formatDate} from '../utils/date-utils/format-date'
import {SubLifeContent} from '../Sub-Life-Content/SubLifeContent'
import {config} from '../environment-config'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import {IndProductState, Product} from '../../../definitions/ind-product-state'
import styles from './Subscription.module.scss'
import {hasCstaLibrary} from './Limited-Libraries/has-csta'
import {CstaCta} from './Limited-Libraries/csta-cta'
import {hasAutoRenew} from './has-auto-renew'
import {getNextChargeDate} from './get-next-charge-date'
import Button from '@pluralsight/ps-design-system-button'
import SmallLoadingDecorator from '../common/Small-Loading-Decorator/small-loading-decorator'

function LabeledDate(prop: {label: string; date?: string}) {
    return (
        <div className={styles.row}>
            <div className={styles.labels}>{prop.label}</div>
            <div className={styles.contentInfo}>{prop.date ? formatDate(prop.date) : 'N/A'}</div>
        </div>
    )
}

function NoSubscriptionAdmin() {
    return (
        <div className={styles.row}>
            <div className={styles.contentInfo}>No current Subscription</div>
        </div>
    )
}

function Freemium() {
    return (
        <div>
            <div className={styles.row}>
                <div className={styles.noSubCopy}>
                    <div className={styles.bottomMargin}>
                        You currently have access to
                        <span className={styles.freemiumProduct}>
                            {' '}
                            Pluralsight's Free limited library
                        </span>
                        .
                    </div>
                    <div>
                        Upgrade today to access our full library of thousands of expert-led courses
                        across hundreds of topics.
                    </div>
                </div>
            </div>
        </div>
    )
}

function GroupMember(props: {planName: string}) {
    const planName = props.planName
    return (
        <div className={styles.row}>
            <div className={styles.labels}>Group plan:</div>
            <div className={styles.contentInfo}>{planName}</div>
        </div>
    )
}

export function Subscription() {
    const {state, actions} = useAppContext()
    let [dataLoading, setDataLoading] = useState(true)
    useEffect(() => {
        actions.loadIndProductState()
        const memberPlanId = state.applicationData.planInfo.member
        const names = state.planDisplayNames
        const indProductStateLoaded = state.indProductState.loaded
        if (indProductStateLoaded) {
            if (!!memberPlanId) {
                const memberPlanNameLoaded = names[memberPlanId] && !names[memberPlanId].pending
                if (memberPlanNameLoaded) {
                    setDataLoading(false)
                } else {
                    actions.loadPlanDisplayName([memberPlanId])
                }
            } else {
                setDataLoading(false)
            }
        }
    }, [
        state.planDisplayNames,
        state.applicationData.planInfo.member,
        state.indProductState.loaded,
        actions,
    ])

    const isAdmin = state.applicationData.planInfo.admin.length > 0
    const memberPlanId = state.applicationData.planInfo.member
    const isMember = memberPlanId !== null
    const planName =
        (memberPlanId !== null &&
            state.planDisplayNames[memberPlanId] &&
            state.planDisplayNames[memberPlanId].displayName) ||
        ''
    const products = (state.indProductState.data && state.indProductState.data.products) || []
    const subscriptions = state.applicationData.subscriptionAccount.subscriptions
    const autoRenew = hasAutoRenew(subscriptions)
    const nextChargeDate = getNextChargeDate(subscriptions)

    return dataLoading ? (
        <CenteredLoadingDecorator />
    ) : (
        <DumbSubscription
            memberInfo={{isMember, planName}}
            products={products}
            isAdmin={isAdmin}
            autoRenew={autoRenew}
            nextChargeDate={nextChargeDate}
        />
    )
}

export function DumbSubscription(props: {
    memberInfo: {isMember: boolean; planName: string}
    isAdmin: boolean
    products: IndProductState[]
    autoRenew: boolean
    nextChargeDate: string
}) {
    const {state} = useAppContext()
    const isMember = props.memberInfo.isMember
    const memberPlanName = props.memberInfo.planName
    const showCta = !isMember && !state.hideCTAs
    const products = props.products
    const freeLimitedLibProducts = products.filter(
        (p) =>
            !p.trial &&
            p.free &&
            p.limitedLibrary &&
            p.inTerm &&
            !p.catalogProduct.preserveProvisionedTerm
    )
    const inTermProducts: IndProductState[] = products.filter((p) => p.inTerm)
    let mainProducts = inTermProducts.filter((p) => p.catalogProduct.preserveProvisionedTerm)
    if (mainProducts.length === 0) {
        mainProducts = inTermProducts.filter((p) => !p.free)
    }
    const noAccess = !props.memberInfo.isMember && inTermProducts.length === 0
    const hasFreeLimitedLibs = freeLimitedLibProducts.length > 0
    const hasMainProduct = mainProducts.length > 0
    const showFreeLimitedLibs = hasFreeLimitedLibs
    const expirationLabel = props.autoRenew ? 'Auto-renews on:' : 'Expiration:'
    const nextChargeDate = props.nextChargeDate
    return (
        <SubLifeContent label='Subscription'>
            {isMember && <GroupMember planName={memberPlanName} />}
            {hasMainProduct && (
                <div>
                    <InTermProducts products={mainProducts} />
                    <LabeledDate label={expirationLabel} date={nextChargeDate} />
                </div>
            )}
            {showFreeLimitedLibs && (
                <FreeLimitedLibraries
                    products={freeLimitedLibProducts}
                    additionalSubscription={isMember || mainProducts.length > 0}
                />
            )}
            {noAccess && <NoAccess isAdmin={props.isAdmin} />}
            {showCta && <IndividualSubscriptionActions products={products} />}
        </SubLifeContent>
    )
}
function NoAccess(props: {isAdmin: boolean}) {
    return props.isAdmin ? <NoSubscriptionAdmin /> : <Freemium />
}
function InTermProducts(props: {products: Product[]}) {
    return <div>{props.products.map(product)}</div>
    function product(p: Product, index: number) {
        const name = p.name === 'Individual Free Weekend' ? 'Individual Free' : p.name
        return (
            <div key={index} className={styles.section}>
                <div className={styles.row}>
                    <div className={styles.labels}>Product:</div>
                    <div className={styles.contentInfo}>{name}</div>
                </div>
            </div>
        )
    }
}

function FreeLimitedLibraries({
    products,
    additionalSubscription,
}: {
    products: Product[]
    additionalSubscription: boolean
}) {
    return (
        <div style={{marginBottom: '35px'}}>
            <div>
                {additionalSubscription ? (
                    <span>
                        <div style={{marginTop: '24px'}}>
                            You also have access to the following:
                        </div>
                    </span>
                ) : (
                    <span>You have access to the following limited subscriptions:</span>
                )}
            </div>
            <ul>{products.map(limitedLibrary)}</ul>
        </div>
    )

    function limitedLibrary(p: Product, index: number) {
        return (
            <li key={index} className={styles.limitedLibraryItem}>
                {p.name} (expires {formatDate(p.expiresAt)})
            </li>
        )
    }
}
function IndividualSubscriptionActions({products}: {products: Product[]}) {
    const hasBillingData = products.filter((p) => !p.free || p.trial).length > 0
    const hasPaymentHistory = products.filter((p) => !p.free).length > 0
    const hasInTermEverythingIndOrPremium =
        products.filter((p) => p.inTerm && (p.sku === 'IND-EVERY' || p.sku === 'INDIVIDUAL-PREM'))
            .length > 0
    const showUpgradeButton = !hasInTermEverythingIndOrPremium
    const hasFullLibrary = products.filter((p) => p.fullLibrary).length > 0
    const hasCsta = hasCstaLibrary(products)
    const showCstaUpgradeButton = hasCsta && !hasFullLibrary
    return (
        <div>
            <div>
                {showUpgradeButton && (
                    <div>
                        {!hasFullLibrary && (
                            <div>Click upgrade to subscribe to our full library.</div>
                        )}
                    </div>
                )}
                <div className={styles.ctaRow}>
                    {showUpgradeButton && <PricingPageButton />}
                    {hasBillingData && <BillingManagePlanButton />}
                </div>
                {showCstaUpgradeButton && <CstaCta />}
            </div>
            {hasPaymentHistory && <PaymentHistoryLink />}
        </div>
    )
}

function BillingManagePlanButton() {
    const billingUrl = config.billing.url as string
    return (
        <Button
            appearance={Button.appearances.stroke}
            className={styles.buttonRow}
            href={billingUrl}
        >
            Manage plan
        </Button>
    )
}

function PaymentHistoryLink() {
    const billingUrl = config.billing.url
    return (
        <div className={styles.paymentHistory}>
            <Link>
                <a href={`${billingUrl}/billing/history`}>View payment history</a>
            </Link>
        </div>
    )
}

function PricingPageButton() {
    const {state, actions} = useAppContext()
    useEffect(() => {
        actions.loadCtaConfig()
    }, [actions])
    const billingUrl = config.billing.url
    const upgradeHref = `${billingUrl}/individual/upgrade-subscription/payment-details`
    const loaded = state.ctaText.loaded
    const buttonText = state.ctaText.data
    return (
        <Button
            appearance={Button.appearances.primary}
            className={`${styles.buttonRow} ${styles.cta}`}
            href={upgradeHref}
        >
            {loaded ? buttonText : <SmallLoadingDecorator />}
        </Button>
    )
}
