import React from 'react'
import styles from './ApplyWalletAmount.module.scss'

interface Props {
    walletAmount: number | null
    totalPrice: number | undefined
    currency: string
}

export default function ApplyWalletAmount({walletAmount, totalPrice, currency}: Props) {
    const creditAmount =
        Number(walletAmount) > Number(totalPrice)
            ? `${totalPrice} ${currency}`
            : `${walletAmount} ${currency}`
    return (
        <div className={styles.row}>
            <div id={'wallet'} className={`${styles.lowOnDarkText} ${styles.upperCase}`}>
                PS WALLET CREDIT APPLIED
            </div>
            <div className={styles.highOnDarkText}>
                <span aria-labelledby={'wallet'}>-{creditAmount}</span>
            </div>
        </div>
    )
}
