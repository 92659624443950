import * as React from 'react'
import {useEffect, useState} from 'react'
import Form from '@pluralsight/ps-design-system-form'
import TextInput from '@pluralsight/ps-design-system-textinput'
import * as Text from '@pluralsight/ps-design-system-text'
import TextArea from '@pluralsight/ps-design-system-textarea'
import Button from '@pluralsight/ps-design-system-button'
import {useParams} from 'react-router-dom'
import {useAppContext} from '../useAppState'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import {ParamTypes} from '../../../definitions/param-types'
import styles from './Cpq-Expand.module.scss'
import Dropdown from '@pluralsight/ps-design-system-dropdown'
import {api} from '../api/api'

export default function CpqExpired() {
    const bannerMessage =
        'Thank you for submitting a renewal request. Your account representative will reach out to you soon.'
    const [charLimit, setCharLimit] = useState(250)
    const [licensesToAdd, setLicencesToAdd] = useState('')
    const [notes, setNotes] = useState('')
    const {planId} = useParams() as ParamTypes
    const {state, actions} = useAppContext()
    const [displayName, setPlanDisplayName] = useState('' as any)
    const contactMethodOptions = ['Email', 'Phone call']
    const [contactMethod, setContactMethod] = useState()
    const [contactInfo, setContactInfo] = useState()
    const [loader, setLoader] = useState(false)

    const [error, setError] = useState(false)
    const {setters} = useAppContext()

    useEffect(() => {
        if (!!planId) {
            actions.loadPlanDisplayName([planId])
        }
    }, [planId, actions])
    useEffect(() => {
        if (!!planId) {
            actions.loadBusinessAccount(planId)
        }
    }, [planId, actions])
    useEffect(() => {
        if (planId in state.planDisplayNames && !state.planDisplayNames[planId].pending) {
            setPlanDisplayName(state.planDisplayNames[planId].displayName)
        }
    }, [state.planDisplayNames, planId])
    const handleChange = (e: any) => {
        if (e.target.name === 'notes') {
            setNotes(e.target.value)
            setCharLimit(250 - e.target.value.length)
        }
        if (e.target.name === 'licenseCount')
            setLicencesToAdd(e.target.value < 0 ? 1 : e.target.value)
    }

    const handleCancel = (e: any) => {
        setters.setSubscribeRoute(false)
    }
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setLoader(true)
        const body = {
            licensesToAdd: parseInt(licensesToAdd),
            contactMethod,
            contactInfo,
            notes,
            planDisplayName: displayName,
            user: state.applicationData.user,
        }
        await api.post(`plans/${planId}/cpq-renew`, body).then((res) => {
            if (res.status.success) {
                setters.setSubscribeBanner({
                    showBanner: true,
                    message: bannerMessage,
                    product: '',
                })
                setters.setSubscribeRoute(false)
            } else {
                throw new Error(res.status.errorMessage)
            }
        })
        setLoader(false)
    }

    return (
        <>
            {!loader && !!displayName ? (
                <div className={styles.formLayout}>
                    <Form.VerticalLayout>
                        <Text.Heading>
                            <h2>
                                Request subscription Renewal for{' '}
                                <span style={{fontWeight: 'bold'}}>{displayName}</span>
                            </h2>
                        </Text.Heading>
                        <TextInput
                            type='number'
                            min='1'
                            name='licenseCount'
                            onChange={handleChange}
                            label='How many subscriptions would you like to renew?*'
                            value={licensesToAdd}
                        />
                        <Dropdown
                            label='What is the best way to contact you?'
                            onChange={(evt: any, value?: any, label?: any) =>
                                setContactMethod(value)
                            }
                            placeholder={'Select'}
                            menu={contactMethodOptions.map((opt) => (
                                <Dropdown.Item key={opt} value={opt}>
                                    {opt}
                                </Dropdown.Item>
                            ))}
                            value={licensesToAdd}
                        />
                        {!!contactMethod && (
                            <TextInput
                                onChange={(evt: any) => {
                                    setError(false)
                                    setContactInfo(evt.target.value)
                                }}
                                value={contactInfo}
                                error={error ? true : false}
                                label={contactMethod === 'Email' ? 'Email address' : 'Phone number'}
                                name='contact information'
                                subLabel={error && 'Field is required'}
                            />
                        )}

                        <TextArea
                            label='Notes (optional)'
                            name='notes'
                            maxLength={250}
                            subLabel={`${charLimit} characters left`}
                            onChange={handleChange}
                        />
                        <Form.ButtonRow>
                            {!licensesToAdd ? (
                                <Button disabled>Submit request</Button>
                            ) : (
                                <Button onClick={handleSubmit}>Submit request</Button>
                            )}

                            <Button
                                appearance={Button.appearances.secondary}
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Form.ButtonRow>
                    </Form.VerticalLayout>
                </div>
            ) : (
                <CenteredLoadingDecorator />
            )}
        </>
    )
}
