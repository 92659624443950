import React from 'react'
import {bool, func, string} from 'prop-types'
import css from './account-button.css'
import {
  AccountIcon,
  BuildingIcon,
  CaretDownIcon
} from '@pluralsight/ps-design-system-icon'
export function AccountButton({displayName, isEnabled, onClick, isActive}) {
  return (
    <div className={css.container}>
      <BuildingIcon className={css.buildingIcon} />
      {/* <label htmlFor='account-name' className={css.label}>
        Account:
      </label> */}
      {isEnabled ? (
        <button
          name='account-name'
          className={css.button}
          onClick={onClick}
          aria-expanded={isActive}
        >
          <span className={css.accountIcon}>
            <AccountIcon />
          </span>
          <span className={css.title}>{displayName}</span>
          <CaretDownIcon className={css.caret} />
        </button>
      ) : (
        <div name='account-name' className={css.title}>
          {displayName}
        </div>
      )}
    </div>
  )
}

AccountButton.propTypes = {
  displayName: string.isRequired,
  isEnabled: bool,
  onClick: func,
  isActive: bool
}
