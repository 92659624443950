export enum PaymentMethodType {
    BankTransfer = 'BankTransfer',
    CreditCard = 'CreditCard',
    CreditCardReferenceTransaction = 'CreditCardReferenceTransaction',
    PayPal = 'PayPal',
    ACH = 'ACH',
}
export enum BannerMessageKeys {
    DEFAULT = 'DEFAULT',
    LEARNING = 'LEARNING',
    TRANSACTION_LINKS = 'TRANSACTION_LINKS',
}
export const BUSINESS = 'Business'
export const NEGATIVE_AMOUNT_ERROR = 'Authorization amount cannot be a negative amount'
