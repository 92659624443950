import {number, shape, string} from 'prop-types'

export const currentPlan = shape({
  planId: string.isRequired,
  displayName: string.isRequired
})

export const plan = shape({
  planId: string.isRequired,
  displayName: string.isRequired,
  totalLicenses: number
})
