export const config = {
    www: {
        url: process.env.REACT_APP_WWW_URL,
        goCode: process.env.REACT_APP_WWW_GO_CODE,
    },
    billing: {
        url: process.env.REACT_APP_BILLING_URL,
    },
    plans: {
        url: process.env.REACT_APP_PLANS_URL,
    },
    plansApiHost: {
        url: process.env.REACT_APP_PLANS_API_HOST,
    },
    people: {
        url: process.env.REACT_APP_PEOPLE_HOST,
    },
    upsellProductOptionId: {
        productOptionId: process.env.REACT_APP_UPSELL_PRODUCT_OPTION_ID,
    },
    subscriptionLifecycle: {
        browserPath: process.env.REACT_APP_BROWSER_BASE_PATH,
        apiPathBase: process.env.REACT_APP_SUB_LIFE_BASE_PATH,
    },
    renewalsBefore90days: process.env.REACT_APP_ALLOW_RENEWALS_BEFORE_NINETYDAYS,
    hideFutureAddOns15days: process.env.REACT_APP_HIDE_FUTURE_ADD_ONS_15DAYS,
    hideFutureAddOnsBeyond: process.env.REACT_APP_HIDE_FUTURE_ADD_ONS_BEYOND,
}
