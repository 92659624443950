import * as React from 'react'
import RenewAccountSwitcher from '../Account-Switcher/renew-account-switcher'
import LogoHeader from '../Logo-Header/logo-header'
import RenewMain from '../Main-Content/renew-main'
import {useEffect} from 'react'
import styles from '../styles/renew.module.scss'
import {useRenewState} from '../Renew-Context/renew-context'
import ProcessRenew from '../Thank-You-Page/process-renew'
import {BackToPlanLink} from '../../Plan-Link/BackToPlanLink'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../../definitions/param-types'

export default function RenewUnBlocked() {
    const {planId} = useParams() as ParamTypes
    const {state} = useRenewState()
    useEffect(() => {}, [state.processingRenew])
    const processingRenew = state.processingRenew
    return (
        <>
            {!processingRenew ? (
                <div className={styles.mainPage}>
                    <LogoHeader />
                    <div className={styles.mainContent}>
                        <div className={styles.backLink}>
                            <BackToPlanLink planId={planId} />
                        </div>
                        <RenewAccountSwitcher />
                        <RenewMain />
                    </div>
                </div>
            ) : (
                <ProcessRenew />
            )}
        </>
    )
}
