import Dropdown from '@pluralsight/ps-design-system-dropdown'
import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {DisplayNameMap} from '../../useAppState'
import styles from './Sub-Life-Account-Switcher.module.scss'

export function SubLifeAccountSwitcher(props: {
    planIds: string[]
    currentPlanId: string
    displayName: DisplayNameMap
    clearData: () => void
    disabled?: boolean
}) {
    let navigate = useNavigate()
    let location = useLocation()

    function getRedirectUrl(newPlanId: string) {
        let redirectUrl = location.pathname.replace(props.currentPlanId, newPlanId)
        return redirectUrl
    }

    return (
        <div className={styles.accountSwitcher}>
            <span className={styles.accountHeader}>Account:</span>
            <div className={styles.dropdown}>
                {props.disabled ? (
                    <div className={styles.accountName}>
                        {props.displayName[props.currentPlanId]?.displayName || ''}
                    </div>
                ) : (
                    <Dropdown
                        disabled={props.disabled}
                        onChange={(event: any, value: any) => {
                            if (value !== props.currentPlanId) {
                                props.clearData()
                                navigate(getRedirectUrl(value))
                            }
                        }}
                        placeholder={
                            !!props.displayName[props.currentPlanId]
                                ? props.displayName[props.currentPlanId].displayName
                                : ''
                        }
                        menu={
                            <>
                                {props.planIds.map((planId: string) => {
                                    return (
                                        <Dropdown.Item value={planId} key={planId}>
                                            {!!props.displayName[planId]
                                                ? props.displayName[planId].displayName
                                                : ''}
                                        </Dropdown.Item>
                                    )
                                })}
                            </>
                        }
                    />
                )}
            </div>
        </div>
    )
}
