import React from 'react'
import styles from './Expand-Order-Summary.module.scss'
import {useExpandContext} from '../Expand-Context'
import Drawer from '../../common/Custom-Drawer/drawer'
import {formatPrice} from '../../common/utils/formatPrice'
import SmallLoadingDecorator from '../../common/Small-Loading-Decorator/small-loading-decorator'
import {formattedDate} from '../../utils/date-utils/format-date'
import {useAppContext} from '../../useAppState'
import {
    QUARTER,
    TOTAL_DUE_TODAY,
    TOTAL_DUE_TODAY_ANNUAL,
    TOTAL_DUE_TODAY_QUARTERLY,
} from '../../common/constants'
import {isQuarterlyPayment, useQuarterlyPayment} from '../../utils/quarterly-payment'
import {calculateFinalPrice} from '../../common/utils/calculateFinalPrice'
import ApplyWalletAmount from '../../common/utils/ApplyWalletAmount'
import {autoApplyWallet} from '../../common/utils/autoApplyWallet'

export function ExpandOrderSummary() {
    const {state} = useExpandContext()

    return (
        <div
            className={
                state.discountAmount === 0
                    ? styles.orderSummaryBase
                    : styles.orderSummaryBaseWithDiscount
            }
        >
            <div>
                <h4 className={styles.orderSummaryHeader}>Order summary</h4>
            </div>
            <div>
                <ExpandOrderSummaryBody />
            </div>
        </div>
    )
}

export function ExpandSummaryFooter() {
    const {setters, state} = useExpandContext()

    function toggleDrawer() {
        setters.setIsMobileOrderSummaryOpen(!state.isMobileOrderSummaryOpen)
    }

    function TopRow(props: {price: number; currency: string}) {
        return (
            <div className={styles.topRow} onClick={() => toggleDrawer()}>
                <h3>
                    Order summary{' '}
                    <span
                        className={`${styles.chevron} ${
                            state.isMobileOrderSummaryOpen ? styles.openCaret : styles.closedCaret
                        }`}
                    />
                </h3>
                <div>{props.price === 0 ? '--' : `${props.price + ' ' + props.currency}`}</div>
            </div>
        )
    }

    return (
        <div className={styles.expandFooter}>
            <Drawer
                isOpen={state.isMobileOrderSummaryOpen}
                base={<TopRow price={state.amount} currency={state.currency} />}
                children={
                    <div className={styles.footerTable}>
                        <hr
                            className={`${styles.expandFooterHrMargin} ${styles.horizontalRuleColor}`}
                        />
                        <ExpandOrderSummaryBody />
                    </div>
                }
            />
        </div>
    )
}

function getPricePerLicence(state: any) {
    const {discountAmount, pricePerLicense, subTotal, quantityToAdd} = state
    if (discountAmount && discountAmount > 0) {
        return (subTotal + discountAmount) / quantityToAdd
    }
    return subTotal ? subTotal / quantityToAdd : pricePerLicense
}

function ExpandOrderSummaryBody() {
    const {state} = useExpandContext()
    const appContext = useAppContext() // Rename this variable
    return (
        <OrderSummaryBody
            numberOfSubscriptions={state.quantityToAdd}
            pricePerLicence={getPricePerLicence(state)}
            subtotal={state.subTotal}
            taxAmount={state.taxAmount}
            totalPrice={state.amount}
            currency={state.currentSubscription.currency}
            productName={state.currentSubscription.productName}
            discountAmount={state.discountAmount}
            proratedPriceLoaded={state.proratedPriceLoaded}
            startDate={state.subscription.subscriptionStartDate}
            endDate={state.subscription.subscriptionEndDate}
            walletAmount={appContext.state.walletAmount}
        />
    )
}

function OrderSummaryBody(props: {
    numberOfSubscriptions: number
    pricePerLicence?: number
    totalPrice?: number
    taxAmount?: number
    subtotal?: number
    currency: string
    productName: string
    discountAmount: number
    proratedPriceLoaded: boolean
    startDate?: any
    endDate?: any
    walletAmount: number | null
}) {
    const {state: appState} = useAppContext()
    const {productBillingPeriod = ''} = appState
    const walletFeature = appState.applicationData.featureConfig.enableWalletFeature
    const {
        calculatedSubtotal = 0,
        calculatedTaxAmount = 0,
        calculatedTotalPrice = 0,
    } = useQuarterlyPayment(
        appState,
        props.subtotal ?? 0,
        props.taxAmount ?? 0,
        props.totalPrice ?? 0,
        productBillingPeriod
    )
    const isValidDiscountAmount = props.discountAmount && props.discountAmount > 0

    return (
        <>
            {props.startDate ? (
                <div className={`${styles.lowOnDarkText} ${styles.amountPickerRow}`}>
                    <div>
                        <div id={'product'} className={styles.upperCase}>
                            Product
                        </div>
                        <div
                            className={styles.productName}
                            style={{justifyContent: 'flex-start'}}
                            aria-labelledby={'product'}
                        >
                            {props.productName}
                        </div>
                    </div>
                    <div>
                        <div className={`${styles.upperCase}`}>Quantity</div>
                        <div className={`${styles.quantity}`}>{props.numberOfSubscriptions}</div>
                    </div>
                    <div>
                        <div className={`${styles.upperCase}`}>Service Period</div>
                        <div className={styles.quantity}>
                            {props.startDate &&
                                `${formattedDate(props.startDate)}  - ${formattedDate(
                                    props.endDate
                                )}`}
                        </div>
                    </div>
                    <div className={styles.textAlignRight}>
                        <div id={'price'} className={styles.upperCase}>
                            Price<span className={styles.daggers}>✝</span>
                        </div>

                        <div className={styles.productName}>
                            <span aria-labelledby={'price'}>
                                {props.pricePerLicence && formatPrice(props.pricePerLicence)}{' '}
                                {props.currency}
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.loader}>
                    <SmallLoadingDecorator margin={true} />
                </div>
            )}
            <hr className={`${styles.firstHrMargin} ${styles.horizontalRuleColor}`} />
            {isValidDiscountAmount ? (
                <div className={styles.row}>
                    <div id={'discounts'} className={`${styles.lowOnDarkText} ${styles.upperCase}`}>
                        Discounts
                    </div>
                    <div className={styles.highOnDarkText}>
                        <span aria-labelledby={'discounts'}>
                            {props.discountAmount === 0
                                ? '--'
                                : `${props.discountAmount} ${props.currency}`}
                        </span>
                    </div>
                </div>
            ) : (
                <></>
            )}

            <div className={styles.row}>
                <div id={'subtotal'} className={`${styles.lowOnDarkText} ${styles.upperCase}`}>
                    {isValidDiscountAmount ? '(Discounted) ' : ''}Subtotal
                </div>
                <div className={styles.highOnDarkText}>
                    {props.proratedPriceLoaded ? (
                        <SmallLoadingDecorator />
                    ) : (
                        <span aria-labelledby={'subtotal'}>
                            {calculatedSubtotal === 0
                                ? '--'
                                : `${calculatedSubtotal} ${props.currency}`}
                        </span>
                    )}
                </div>
            </div>
            <div className={styles.row}>
                <div id={'tax'} className={`${styles.lowOnDarkText} ${styles.upperCase}`}>
                    Estimated tax<span className={styles.daggers}>✝✝</span>
                </div>
                <div className={styles.highOnDarkText}>
                    <span aria-labelledby={'tax'}>
                        {calculatedTaxAmount === 0
                            ? '--'
                            : `${calculatedTaxAmount} ${props.currency}`}
                    </span>
                </div>
            </div>
            {autoApplyWallet(props.walletAmount, props.totalPrice, walletFeature) ? (
                <ApplyWalletAmount
                    walletAmount={props.walletAmount}
                    totalPrice={props.totalPrice}
                    currency={props.currency}
                />
            ) : (
                <></>
            )}
            <hr className={`${styles.secondHrMargin} ${styles.horizontalRuleColor}`} />
            <div className={styles.totalRow}>
                <div id={'total'}>
                    {isQuarterlyPayment(appState, productBillingPeriod)
                        ? productBillingPeriod === QUARTER
                            ? TOTAL_DUE_TODAY_QUARTERLY
                            : TOTAL_DUE_TODAY_ANNUAL
                        : TOTAL_DUE_TODAY}
                </div>
                <div className={styles.textAlignRight}>
                    <span aria-labelledby={'total'}>
                        {autoApplyWallet(props.walletAmount, props.totalPrice, walletFeature)
                            ? `${calculateFinalPrice(
                                  calculatedTotalPrice,
                                  Number(props.walletAmount)
                              ).toFixed(2)} ${props.currency}`
                            : calculatedTotalPrice === 0
                            ? '--'
                            : `${calculatedTotalPrice} ${props.currency}`}
                    </span>
                </div>
            </div>
        </>
    )
}
