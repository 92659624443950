import React, {createContext, useContext, useMemo, useState} from 'react'
import {PaymentMethod} from '../../../definitions/payment-methods'
import {api} from '../api/api'
import {
    UpgradeErrorData,
    UpgradeErrorMessage,
    UpgradeGuardResponse,
    UpgradeRequestV2,
} from '../../../definitions/upgrade'
import {useAsyncError} from '../useAsyncError'
import {EVERYTHING_NEW_BUNDLE_SKU, EVERYTHING_NEW_SKU, getBundleSku} from '../common/constants'
import {
    canDisplayPsWallet,
    isB2BLegacySKU,
    isNewPricingUpgrade,
} from '../utils/wallet-eligible-product'

const UpgradeContextV2 = (props: any) => {
    const activateErrorBoundary = useAsyncError()
    const initialState: Partial<UpgradeStateV2> = {
        planId: '',
        handle: '',
        errorMessage: '',
        currency: '',
        guardData: undefined,
        hasError: false,
        existingPaymentMethods: [],
        paymentMethodId: '',
        paymentGateway: '',
        paymentMethodsInitialized: false,
        isPaymentOpen: true,
        isPaymentComplete: false,
        isMobileOrderSummaryOpen: false,
        isReviewConfirmOpen: false,
        guardResponse: null,
        getActivityError: false,
        currentSubscription: {
            isLoaded: false,
        } as UpgradeBusinessSubscriptionDataV2,
        upgradePreview: {
            amount: 0,
            currency: '',
            lineItems: [
                {
                    amount: 0,
                    unitPrice: 0,
                    quantity: 0,
                },
            ],
            loaded: false,
            isLoading: true,
            newPlan: {
                netPrice: 0,
            },
            currentPlan: {
                netPrice: 0,
            },
            displayPsWallet: false,
            isB2BLegacyUpgrade: false,
        },
        upgrade: {
            amount: 0,
            subtotal: 0,
            taxAmount: 0,
            currency: '',
            termEndDate: '',
            lineItems: [
                {
                    amount: 0,
                    unitPrice: 0,
                    quantity: 0,
                },
            ],
            paymentMethod: '',
            loaded: false,
            pending: false,
        },
        productBillingPeriod: '',
        targetCustomerDiscountAmount: 0,
    }

    const useUpgradeState = () => {
        const [state, setState] = useState<UpgradePreview>(
            !!props.initialState ? props.initialState : initialState
        )
        const setters = useMemo<UpgradeSetters>(() => getUpgradeSetters(setState), [setState])
        const [actions] = useState(getActions(setState))
        return {state, actions, setters}
    }

    const getUpgradeSetters: (setState: any) => UpgradeSetters = (setState: any) => ({
        setPlanId: (planId: string | undefined) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                planId,
            }))
        },
        setHandle: (handle: string) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                handle,
            }))
        },
        setPaymentMethodId: (paymentMethodId: string) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                paymentMethodId,
            }))
        },
        setPaymentGateway: (paymentGateway: string) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                paymentGateway,
            }))
        },
        setExistingPaymentMethods: (existingPaymentMethods: any) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                existingPaymentMethods,
            }))
        },
        setPaymentMethodsInitialized: (paymentMethodsInitialized: boolean) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                paymentMethodsInitialized,
            }))
        },
        setIsPaymentOpen: (isPaymentOpen: boolean) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                isPaymentOpen,
            }))
        },
        setIsPaymentComplete: (isPaymentComplete: boolean) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                isPaymentComplete,
            }))
        },
        setIsReviewConfirmOpen: (isReviewConfirmOpen: boolean) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                isReviewConfirmOpen,
            }))
        },
        setHasAgreedToTerms: (hasAgreedToTerms: boolean) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                hasAgreedToTerms,
            }))
        },
        setPreviewLoading: () => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                upgradePreview: {
                    ...state.upgradePreview,
                    isLoading: true,
                },
            }))
        },
        setCurrentSubscription: (currentSubscription: UpgradeBusinessSubscriptionDataV2) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                currentSubscription,
            }))
        },
        setIsMobileOrderSummaryOpen: (isMobileOrderSummaryOpen: boolean) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                isMobileOrderSummaryOpen,
            }))
        },
        setHasError(errorMessage: UpgradeErrorMessage) {
            setState((state: UpgradeStateV2) => ({
                ...state,
                hasError: true,
                errorMessage: errorMessage,
            }))
        },
    })

    const getActions: (setState: any) => UpgradeActions = (setState: any) => ({
        loadPaymentMethods(planId: string) {
            api.get(`plans/${planId}/payment-methods`).then((res) => {
                if (res.status.success) {
                    setState((state: any) => ({
                        ...state,
                        existingPaymentMethods: res.data.paymentMethods.savedPayments,
                        paymentMethodsInitialized: true,
                    }))
                }
            })
        },
        postAmend(upgradeRequest: UpgradeRequestV2) {
            api.post(`plans/${upgradeRequest.planId}/amend`, upgradeRequest)
                .then((res) => {
                    if (res.status.success) {
                        setState((state: UpgradeStateV2) => ({
                            ...state,
                            amendActivity: {amendActivityId: res.data.activityId},
                        }))
                        if (res?.data?.activityId && upgradeRequest?.planId) {
                            this.getAmendActivity(res.data.activityId, upgradeRequest.planId)
                        }
                    } else {
                        setState((state: UpgradeStateV2) => ({
                            ...state,
                            hasError: true,
                            errorMessage: res.status.errorMessage,
                        }))
                    }
                })
                .catch((e) => {
                    activateErrorBoundary(e)
                })
        },
        getAmendActivity(activityId: string, planId: string) {
            api.getPolling(`plans/${planId}/getActivity/${activityId}`, (result) => {
                if (
                    result.data.status === 'SUCCESS' &&
                    result.data.data.cartPricingCalculated === true
                ) {
                    return true
                } else if (result.data.status === 'ERROR') {
                    return true
                }
                return false
            })
                .then((res) => {
                    if (res.status.success) {
                        if (res.data.status === 'ERROR') {
                            setState((state: any) => {
                                return {
                                    ...state,
                                    getActivityError: true,
                                }
                            })
                        } else {
                            setState((state: any) => {
                                const cart = res.data.data.cart
                                const targetCustomerDiscountAmount =
                                    cart.targetCustomerDiscountAmount
                                const cartItems = cart?.cartItems
                                const cartItemsLength = cartItems.length
                                let newPlan = {...state.upgradePreview.newPlan}
                                let currentPlan = {...state.upgradePreview.currentPlan}
                                const isNewPPUpgrade: boolean = isNewPricingUpgrade(
                                    state.currentSubscription.productSKU
                                )
                                const isB2BLegacyUpgrade: boolean = isB2BLegacySKU(
                                    state.currentSubscription.productSKU
                                )

                                if ((isNewPPUpgrade && cartItemsLength > 0) || isB2BLegacyUpgrade) {
                                    cartItems.forEach((cartItem: any) => {
                                        const {childItems} = cartItem

                                        if (cartItem.productCode === EVERYTHING_NEW_BUNDLE_SKU) {
                                            if (childItems.length > 0) {
                                                const {netPrice: newPlanNetPrice} = childItems.find(
                                                    (childItem: any) =>
                                                        childItem.productCode === EVERYTHING_NEW_SKU
                                                )

                                                newPlan = {
                                                    netPrice: newPlanNetPrice,
                                                }
                                            }
                                        }

                                        if (
                                            cartItem.productCode ===
                                            getBundleSku(state.currentSubscription.productSKU)
                                        ) {
                                            const {netPrice: currentPlanNetPrice} = childItems.find(
                                                (childItem: any) =>
                                                    childItem.productCode ===
                                                    state.currentSubscription.productSKU
                                            )

                                            currentPlan = {
                                                netPrice: currentPlanNetPrice,
                                            }
                                        }
                                    })
                                }

                                const unitPrice = cart.listAmount / state.planSubscriptionQuantity

                                const displayPsWalletValue = canDisplayPsWallet(
                                    cart.netAmount,
                                    isNewPPUpgrade
                                )

                                return {
                                    ...state,
                                    cartId: cart.cartId,
                                    targetCustomerDiscountAmount: targetCustomerDiscountAmount,
                                    upgradePreview: {
                                        amount: cart.customerAmount,
                                        currency: cart.currencyCode,
                                        lineItems: [
                                            {
                                                amount: cart.listAmount,
                                                unitPrice,
                                                quantity: state.planSubscriptionQuantity,
                                            },
                                        ],
                                        isLoading: false,
                                        loaded: true,
                                        newPlan,
                                        currentPlan,
                                        displayPsWallet: displayPsWalletValue,
                                        isB2BLegacyUpgrade: isB2BLegacyUpgrade,
                                    },
                                }
                            })
                        }
                    }
                })
                .catch((e) => {
                    setState((state: any) => {
                        return {
                            ...state,
                            getActivityError: true,
                        }
                    })
                })
        },
        getSubscription(subscriptionId: string, planId: string) {
            api.get(`plans/${planId}/subscription/${subscriptionId}`)
                .then((res) => {
                    if (res.status.success) {
                        let currentSubscription: UpgradeBusinessSubscriptionDataV2 =
                            res.data?.subscriptions?.[0]
                        setState(
                            (state: UpgradeStateV2) =>
                                ({
                                    ...state,
                                    currentSubscription: {
                                        ...currentSubscription,
                                        isLoaded: true,
                                    },
                                } as UpgradeStateV2)
                        )
                    }
                })
                .catch((error) => activateErrorBoundary(error))
        },
        loadBusinessAccount(planId: string) {
            api.get(`plans/${planId}/account`).then((res) => {
                if (res.status.success) {
                    const {
                        productState: {
                            products: [
                                {
                                    productCatalogInfo: {
                                        productOption: {billingPeriod = ''} = {},
                                    } = {},
                                } = {},
                            ] = [],
                        } = {},
                    } = res.data

                    setState((state: UpgradeStateV2) => {
                        const updatedState = {
                            ...state,
                            planSubscriptionQuantity:
                                res.data.productState.products[0].productInfo.quantity,
                            currency: res.data.billingInfo.currency,
                            productBillingPeriod: billingPeriod,
                        }
                        return updatedState
                    })
                } else {
                    throw new Error(res.status.errorMessage)
                }
            })
        },
        getTax(taxRequest: any, planId: string) {
            api.post(`plans/${planId}/calculateTax`, taxRequest).then((res) => {
                if (res.status.success) {
                    setState((state: UpgradeStateV2) => ({
                        ...state,
                        tax: {
                            subTotal: res.data.subTotal,
                            tax: res.data.tax,
                            totalWithTax: res.data.totalWithTax,
                            cartItems: res.data.cartItems,
                        },
                    }))
                }
            })
        },
        postCheckout(body: CheckoutPayLoad, planId: string) {
            setState((state: UpgradeStateV2) => ({
                ...state,
                upgrade: {
                    pending: true,
                },
            }))
            api.post(`plans/${planId}/checkout`, body).then((res) => {
                if (res.status.success) {
                    setState((state: UpgradeStateV2) => ({
                        ...state,
                        upgrade: {
                            amount: state.tax.totalWithTax,
                            subtotal: state.tax.subTotal,
                            taxAmount: state.tax.tax | 0,
                            currency: state.upgradePreview.currency,
                            termEndDate: res.data.orderDetails.orderItems[0].endDate,
                            lineItems: res.data.orderDetails.orderItems.map((orderItem: any) => ({
                                amount: orderItem.totalPrice,
                                unitPrice: orderItem.unitPrice,
                                quantity: orderItem.quantity,
                            })),
                            paymentMethod: state.paymentMethodId,
                            loaded: true,
                            pending: false,
                        },
                    }))
                }
            })
        },
        upgradeGuardCheck(planId: string) {
            api.get(`plans/${planId}/upgrade-guard`).then((res) => {
                if (res.status.success) {
                    const guardResponse: UpgradeGuardResponse = res.data
                    setState((state: UpgradeStateV2) => {
                        return {
                            ...state,
                            hasError: !guardResponse.canUpgrade,
                            errorMessage: guardResponse.canUpgrade ? '' : guardResponse.message,
                            guardResponse: guardResponse,
                            guardData: guardResponse.canUpgrade ? undefined : guardResponse.data,
                        }
                    })
                } else {
                    throw Error(res.status.errorMessage)
                }
            })
        },
        resetState() {
            setState((state: UpgradeStateV2) => ({
                ...initialState,
            }))
        },
        resetToPaymentSelection() {
            setState((state: UpgradeStateV2) => ({
                ...state,
                paymentMethodId: state.paymentMethodId,
                isPaymentOpen: true,
                isPaymentComplete: false,
                isReviewConfirmOpen: false,
            }))
        },
    })

    return (
        <UpgradeContext.Provider value={useUpgradeState()}>
            {props.children}
        </UpgradeContext.Provider>
    )
}

const UpgradeContext = createContext({} as any)

const useUpgradeContext = () => {
    return useContext<{state: UpgradeStateV2; actions: UpgradeActions; setters: UpgradeSetters}>(
        UpgradeContext
    )
}

interface UpgradeSetters {
    setPlanId: (planId: string | undefined) => void
    setHandle: (handle: string) => void
    setIsReviewConfirmOpen: (isReviewConfirmOpen: boolean) => void
    setIsPaymentOpen: (isPaymentOpen: boolean) => void
    setIsPaymentComplete: (isPaymentComplete: boolean) => void
    setPaymentGateway: (paymentGateway: string) => void
    setPaymentMethodId: (paymentMethodId: string) => void
    setExistingPaymentMethods: (paymentMethods: any) => void
    setPaymentMethodsInitialized: (isInitialized: boolean) => void
    setPreviewLoading: () => void
    setIsMobileOrderSummaryOpen: (isMobileOrderSummaryOpen: boolean) => void
    setCurrentSubscription: (currentSubscription: UpgradeBusinessSubscriptionDataV2) => void
    setHasError: (errorMessage: UpgradeErrorMessage) => void
}

interface UpgradeActions {
    loadPaymentMethods: (planId: string) => void
    upgradeGuardCheck: (planId: string) => void
    postAmend: (upgradePreviewPayload: UpgradeRequestV2) => void
    getAmendActivity: (amendActivityId: string, planId: string) => void
    postCheckout: (body: CheckoutPayLoad, planId: string) => void
    getSubscription: (subscriptionId: string, planId: string) => void
    loadBusinessAccount: (planId: string) => void
    getTax: (taxRequest: any, planId: string) => void
    resetToPaymentSelection: () => void
    resetState: () => void
}

// eslint-disable-next-line
export interface UpgradeStateV2 {
    cartId: string
    planId: string
    handle: string
    currency: string
    currentSubscription: UpgradeBusinessSubscriptionDataV2
    upgradePreview: UpgradePreview
    upgrade: UpgradePost
    hasError: boolean
    errorMessage: UpgradeErrorMessage
    guardData: UpgradeErrorData
    guardResponse: UpgradeGuardResponse | null
    isOrderSummaryOpen: boolean
    existingPaymentMethods: PaymentMethod[]
    isPaymentOpen: boolean
    isPaymentComplete: boolean
    paymentMethodId: string
    paymentGateway: string
    hasAgreedToTerms: boolean
    isReviewConfirmOpen: boolean
    isMobileOrderSummaryOpen: boolean
    paymentMethodsInitialized: boolean
    amendActivity: {amendActivityId: string}
    paymentAddress: PaymentAddress
    tax: UpgradeTaxV2
    getActivityError: boolean
    planSubscriptionQuantity: number
    productBillingPeriod?: string
    targetCustomerDiscountAmount: number
}

interface UpgradeTaxV2 {
    userCurrency: string
    shipFrom: {
        country: string
        postalCode: string
        state: string
        streetAddress: string
        city: string
    }
    shipTo: {
        country: string
        postalCode: string
        state: string
        streetAddress: string
        city: string
    }
    cartItems: [
        {
            sku: string
            quantity: number
            subTotal: number
            tax: number
            totalWithTax: number
        }
    ]
    subTotal: number
    tax: number
    totalWithTax: number
}

interface PaymentAddress {
    addressLine1: string
    addressLine2: string
    city: string
    stateProvince: string
    country: string
    postalCode: string
}

export interface CheckoutPayLoad {
    paymentMethodId: string | null
    cartId: string
}

export interface UpgradeBusinessSubscriptionDataV2 {
    formattedAutoRenewDate: string
    isLoaded: boolean
    unitOfMeasure: string
    subscriptionUrl: string
    subscriptionType: string
    subscriptionTerm: number
    subscriptionStartDate: string
    subscriptionPricing: string
    subscriptionNumber: string
    subscriptionId: string
    subscriptionEndDate: string
    salesOrderURL: string
    salesOrderNumber: string
    salesOrderItemURL: string
    quantity: number
    productSKU: string
    productShortDescription: string
    productName: string
    contractURL: string
    contractNumber: string
    contractId: string
    contact: {
        userHandle: string
        phone: string
        lastName: string
        firstName: string
        email: string
        contactUrl: string
        contactId: string
    }
    billing: {
        unitPrice: number
        listPrice: number
        billingFrequency: string
    }
    account: {
        soldTo: {
            streetAddress: string
            stateProvince: string
            postalCode: string
            name: string
            isPrimary: boolean
            country: string
            city: string
        }
        billTo: {
            streetAddress: string
            stateProvince: string
            postalCode: string
            name: string
            isPrimary: boolean
            country: string
            city: string
        }
        accountURL: string
        accountName: string
        accountId: string
    }
    zuoraId: string
}

interface UpgradePreview {
    amount: number
    currency: string
    lineItems: [
        {
            amount: number
            unitPrice: number
            quantity: number
        }
    ]
    isLoading: boolean
    loaded: boolean
    displayPsWallet: boolean
    newPlan: {
        netPrice: number
    }
    currentPlan: {
        netPrice: number
    }
    isB2BLegacyUpgrade: boolean
}

export interface UpgradePost {
    amount: number
    subtotal: number
    taxAmount: number
    currency: string
    termEndDate: string
    lineItems: [
        {
            amount: number
            unitPrice: number
            quantity: number
        }
    ]
    paymentMethod: string
    loaded: boolean
    pending: boolean
}

export {UpgradeContextV2, useUpgradeContext}
