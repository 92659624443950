import React from 'react'
import styles from './CenteredPlanSelection.module.scss'
import LogoHeader from '../Renew-V2/Logo-Header/logo-header'
import {PlanSelection} from './PlanSelection'

export function CenteredPlanSelection(props: {
    destinationFn: (planId: string, productOptionId: string) => string
}) {
    return (
        <>
            <div className={styles.mainPage}>
                <LogoHeader />
            </div>
            <div className={styles.center}>
                <PlanSelection destinationFn={props.destinationFn} />
            </div>
        </>
    )
}
