import React, {ReactNode, useEffect} from 'react'
import {useAppContext} from '../useAppState'
import {useUpgradeContext} from './Upgrade-Context'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../definitions/param-types'
import {UpgradeRequestV2} from '../../../definitions/upgrade'
import {getUpgradeSku} from '../common/constants'
import {isWalletEligible} from '../utils/wallet-eligible-product'

export default function UpgradeDataLoader(props: {children: ReactNode}) {
    const {state: appState, actions: appActions} = useAppContext()
    const {state, actions, setters} = useUpgradeContext()
    const showNewProducts = appState.applicationData.featureConfig.acgiIntegrationActive
    const walletFeature = appState.applicationData.featureConfig.enableWalletFeature

    let {planId, subscriptionId} = useParams() as ParamTypes

    useEffect(() => {
        const hasPlanId = !!planId
        const hasGuardResponse = !!state.guardResponse
        const planIdMismatch = planId !== state.guardResponse?.planId
        if ((hasPlanId && !hasGuardResponse) || planIdMismatch) {
            actions.upgradeGuardCheck(planId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions, planId])

    useEffect(() => {
        const zuoraId: string | null = state.currentSubscription.zuoraId || null
        if (zuoraId && walletFeature) {
            appActions.loadWalletAmount(zuoraId)
        }
    }, [walletFeature, state.currentSubscription.zuoraId])

    useEffect(() => {
        if (
            !!planId &&
            state.currentSubscription.isLoaded &&
            !!state.planSubscriptionQuantity &&
            !state.upgrade.loaded &&
            state.guardResponse?.canUpgrade
        ) {
            setters.setPreviewLoading()

            const amendBody = {
                quantity: state.planSubscriptionQuantity,
                planId: state.planId,
                sku: state.currentSubscription.productSKU,
                amendedSubscriptionId: state.currentSubscription.subscriptionId,
                isUpgrade: true,
            } as UpgradeRequestV2

            actions.postAmend(amendBody)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        planId,
        actions,
        state.upgrade.loaded,
        state.currentSubscription.isLoaded,
        state.planSubscriptionQuantity,
        state.currentSubscription.productSKU,
        state.guardResponse?.canUpgrade,
        setters,
    ])

    useEffect(() => {
        if (!!subscriptionId) {
            actions.getSubscription(subscriptionId, planId)
            actions.loadBusinessAccount(planId)
        }
    }, [planId, subscriptionId, actions])

    useEffect(() => {
        if (!!planId && !state.paymentMethodsInitialized) {
            actions.loadPaymentMethods(planId)
        }
    }, [planId, subscriptionId, state.paymentMethodsInitialized, actions])

    useEffect(() => {
        if (
            !!state.currentSubscription?.account?.billTo?.streetAddress &&
            state.upgradePreview.loaded
        ) {
            const upgradeSKU = getUpgradeSku(state.currentSubscription.productSKU, showNewProducts)
            const country =
                state.currentSubscription.account.billTo.country === 'India'
                    ? 'IN'
                    : state.currentSubscription.account.billTo.country
            const taxRequest = {
                userCurrency: state.currency || 'USD',
                cartItems: [
                    {
                        sku: upgradeSKU,
                        quantity: state.planSubscriptionQuantity,
                        subTotal: state.upgradePreview.amount,
                    },
                ],
                shipTo: {
                    streetAddress: state.currentSubscription.account.billTo.streetAddress,
                    city: state.currentSubscription.account.billTo.city,
                    stateProvince: state.currentSubscription.account.billTo.stateProvince,
                    country: country,
                    postalCode: state.currentSubscription.account.billTo.postalCode,
                },
            }
            actions.getTax(taxRequest, planId)
        }
    }, [
        actions,
        state.currentSubscription,
        state.upgradePreview.amount,
        state.upgradePreview.loaded,
        state.planSubscriptionQuantity,
        showNewProducts,
        planId,
    ])

    useEffect(() => {
        setters.setPlanId(planId)
        setters.setHandle(appState.applicationData.user.handle)
    }, [planId, setters, appState.applicationData.user.handle])

    useEffect(
        function negativeAmountPreviewGuard() {
            const previewLoaded = state.upgradePreview.loaded
            const noError = !state.hasError
            if (previewLoaded && noError) {
                if (state.upgradePreview.amount < 0) {
                    if (isWalletEligible(state)) {
                        return
                    }
                    setters.setHasError('Negative amount preview')
                }
            }
        },
        // eslint-disable-next-line
        [setters, state.hasError, state.upgradePreview.amount, state.upgradePreview.loaded]
    )

    return <div>{props.children}</div>
}
