import React from 'react'
import Link from '@pluralsight/ps-design-system-link'
import './styles/terms-layout.scss'
import {shortFormatDate} from '../../utils/date-utils/format-date'
import UnpaidInvoiceModal from '../../common/Unpaid-Invoice-Modal/Unpaid-Invoice-Modal'
import BusinessCancelModal from '../../common/Business-Cancel-Modal/business-cancel-modal'
import {useAppContext} from '../../useAppState'
import Dialog from '@pluralsight/ps-design-system-dialog'
import RenewQuantityModal from './RenewQuantityModal'
import {config} from '../../environment-config'
import RenewWithUpgradeModal from './RenewWithUpgradeModal'

export default function AccountTermsV2(props: {
    subscriptionQuantity: number
    hasSubscription: boolean
    subscription: any
    displayName: string
    planId: string
    productStartDate: string
    productExpirationDate: string
    customerSource: string
    billingUrl: string | undefined
    unpaidInvoiceIds: string[]
    // subscribeLink: any
    subscriptionId: string
    originalSubscriptionId: string
    disableTransactionLinks: boolean
    isPsOneProduct: boolean
    currentProductName: string
    isMultiYear: boolean
    hasAddOns: boolean
    isAiPlusDataOrSecPlusCloud: boolean
}) {
    const billingUrl = !!props.billingUrl ? props.billingUrl : ''
    const displayName = props.displayName
    const autoConvert = props.hasSubscription && props.subscription.autoConvert
    const expiration = props.hasSubscription ? props.subscription.termEndDate : ''
    const billingHref = `${props.billingUrl}/?planId=${props.planId}`
    const isResellerOrThirdParty = props.customerSource && props.customerSource !== 'Pluralsight'
    const thirdPartyHref = thirdPartySubscriptionHref(
        props.customerSource,
        props.planId,
        billingUrl
    )
    const [isOpen, setOpen] = React.useState(false)
    const currentDate: Date = new Date()
    const planEndDate: Date = new Date(props.productExpirationDate)
    const diffTime = Math.abs(currentDate.getTime() - planEndDate.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    const isRenewAllowed = config.renewalsBefore90days === 'true' ? true : diffDays <= 90
    const managePlanHref = isResellerOrThirdParty ? thirdPartyHref : billingHref
    const renewLink = `${config.subscriptionLifecycle.browserPath}/transaction/renew/${props.planId}/${props.originalSubscriptionId}?acta=b2b_uue_click_renew`
    const hasUnpaidInvoice = !!props.unpaidInvoiceIds && props.unpaidInvoiceIds.length > 0
    const unpaidInvoiceIds = hasUnpaidInvoice ? props.unpaidInvoiceIds : []
    const subscribeText = `There is not currently an active plan for this account.`
    const autoRenewText =
        props.hasSubscription && props.subscription.autoRenew ? 'AUTO-RENEWS ON' : 'EXPIRES ON'
    const thirdPartyBillingInfo = null
    const hasFutureStartDate = new Date(props.productStartDate).getTime() > Date.now()
    const outOfTermExpirationText = hasFutureStartDate ? 'EXPIRES ON' : 'EXPIRED ON'
    const {state, setters} = useAppContext()
    const canChangeRenewQuantity = state.applicationData.featureConfig.canChangeRenewQuantity
    const enableUpgradeWithRenewalsFlag =
        state.applicationData.featureConfig.enableUpgradeWithRenewalsFlag
    const [shouldUpgradeWithRenew, setShouldUpgradeWithRenew] = React.useState(false)
    const [showUpgradeWithRenewModal, setShowUpgradeWithRenewModal] = React.useState(true)
    const currency = state.businessAccountMap[props.planId]?.data?.billingInfo?.currency

    return (
        <>
            <div className='accountDetails'>
                {termDetails(
                    props.disableTransactionLinks,
                    'ACCOUNT NAME',
                    managePlanHref,
                    isResellerOrThirdParty
                        ? props.customerSource === 'Reseller'
                            ? ''
                            : `Manage plan via ${props.customerSource}`
                        : 'Manage plan',
                    displayName,
                    props.customerSource !== 'Pluralsight',
                    {
                        thirdPartyBillingInfo,
                    },
                    setters.setSubscribeRoute,
                    isResellerOrThirdParty
                )}
                {props.hasSubscription ? (
                    <div className='termsLayout'>
                        {props.productStartDate &&
                            termDetails(
                                props.disableTransactionLinks,
                                'START DATE',
                                '',
                                'Cancel',
                                shortFormatDate(props.productStartDate),
                                props.customerSource !== 'Pluralsight'
                            )}
                        {autoConvert
                            ? termDetails(
                                  props.disableTransactionLinks,
                                  'AUTO CONVERTS ON',
                                  '',
                                  '',
                                  shortFormatDate(expiration),
                                  props.customerSource !== 'Pluralsight'
                              )
                            : termDetails(
                                  props.disableTransactionLinks,
                                  autoRenewText,
                                  isResellerOrThirdParty || props.isPsOneProduct || !isRenewAllowed
                                      ? ''
                                      : renewLink,
                                  isResellerOrThirdParty || props.isPsOneProduct || !isRenewAllowed
                                      ? ''
                                      : 'Renew now',
                                  shortFormatDate(expiration),
                                  props.customerSource !== 'Pluralsight',
                                  {
                                      hasUnpaidInvoice,
                                      unpaidInvoiceIds,
                                      planId: props.planId,
                                      subscriptionId: props.subscriptionId,
                                      originalSubscriptionId: props.originalSubscriptionId,
                                      currentProductName: props.currentProductName,
                                      subscriptionQuantity: props.subscriptionQuantity,
                                      setOpen: setOpen,
                                      isOpen: isOpen,
                                      canChangeRenewQuantity: canChangeRenewQuantity,
                                      isMultiYear: props.isMultiYear,
                                      hasAddOns: props.hasAddOns,
                                      isAiPlusDataOrSecPlusCloud: props.isAiPlusDataOrSecPlusCloud,
                                      shouldUpgradeWithRenew: shouldUpgradeWithRenew,
                                      setShouldUpgradeWithRenew: setShouldUpgradeWithRenew,
                                      enableUpgradeWithRenewalsFlag: enableUpgradeWithRenewalsFlag,
                                      showUpgradeWithRenewModal: showUpgradeWithRenewModal,
                                      setShowUpgradeWithRenewModal: setShowUpgradeWithRenewModal,
                                      currency: currency,
                                  }
                              )}
                    </div>
                ) : (
                    <>
                        <div className='termsLayout'>
                            {props.productStartDate &&
                                termDetails(
                                    props.disableTransactionLinks,
                                    'START DATE',
                                    '',
                                    '',
                                    shortFormatDate(props.productStartDate),
                                    props.customerSource !== 'Pluralsight'
                                )}
                            {props.productExpirationDate &&
                                termDetails(
                                    props.disableTransactionLinks,
                                    outOfTermExpirationText,
                                    '',
                                    '',
                                    shortFormatDate(props.productExpirationDate),
                                    props.customerSource !== 'Pluralsight'
                                )}
                        </div>
                        {!hasFutureStartDate &&
                            termDetails(
                                props.disableTransactionLinks,
                                'PLAN',
                                '',
                                'Subscribe',
                                subscribeText,
                                props.customerSource !== 'Pluralsight',
                                {},
                                setters.setSubscribeRoute
                            )}
                    </>
                )}
            </div>
        </>
    )
}

function thirdPartySubscriptionHref(customerSource: string, planId: string, billingUrl: string) {
    switch (customerSource) {
        case 'Amazon':
            return 'https://www.amazon.com/yourmembershipsandsubscriptions'
        case 'AWS Marketplace':
            return 'https://console.aws.amazon.com/marketplace/home?region=us-east-1#/subscriptions'
        case 'Apple':
            return 'https://support.apple.com/en-us/HT202039'
        default:
            return `${billingUrl}/?planId=${planId}`
    }
}

function termDetails(
    disableTransactionLinks: boolean,
    header: string,
    link: string,
    linkText: string,
    data: string,
    isThirdParty: boolean,
    {
        thirdPartyBillingInfo,
        hasUnpaidInvoice,
        unpaidInvoiceIds,
        planId,
        subscriptionId,
        originalSubscriptionId,
        currentProductName,
        subscriptionQuantity,
        setOpen,
        isOpen,
        canChangeRenewQuantity,
        isMultiYear,
        hasAddOns,
        isAiPlusDataOrSecPlusCloud,
        shouldUpgradeWithRenew,
        setShouldUpgradeWithRenew,
        enableUpgradeWithRenewalsFlag,
        showUpgradeWithRenewModal,
        setShowUpgradeWithRenewModal,
        currency,
    }: OptionalTerms = {
        thirdPartyBillingInfo: null,
        hasUnpaidInvoice: false,
    },
    setSubscribeRoute?: any,
    isResellerOrThirdParty?: boolean | string
) {
    const subscribeText = linkText === 'Subscribe'
    const productLinkEnabled =
        !disableTransactionLinks && (linkText !== 'Renew now' || (planId && subscriptionId))
    const handleSubscribeClick = () => {
        setSubscribeRoute(true)
    }
    return (
        <div className='termDetails'>
            <div className='text'>{header}</div>
            <div className='link'>
                {hasUnpaidInvoice ? (
                    <UnpaidInvoiceModal
                        linkText={'Renew now'}
                        planId={!!planId ? planId : ''}
                        invoiceIds={!!unpaidInvoiceIds ? unpaidInvoiceIds : []}
                        hideLink={isThirdParty}
                    />
                ) : linkText === 'Cancel' ? (
                    <BusinessCancelModal heading='Cancel your plan' hideLink={isThirdParty} />
                ) : linkText === 'Subscribe' ? (
                    <button
                        className={productLinkEnabled ? 'subscribeBtn' : 'subscribeBtnDisabled'}
                        disabled={!productLinkEnabled}
                        onClick={handleSubscribeClick}
                    >
                        {linkText}
                    </button>
                ) : canChangeRenewQuantity && linkText === 'Renew now' ? (
                    <div>
                        <Link className={productLinkEnabled ? '' : 'link-disabled'}>
                            <p
                                onClick={() => {
                                    setShowUpgradeWithRenewModal(true)
                                    setOpen(true)
                                }}
                                style={{cursor: 'pointer'}}
                            >
                                {linkText}
                            </p>
                        </Link>
                        {isOpen && (
                            <Dialog
                                modal
                                onClose={() => {
                                    setOpen(false)
                                    setShowUpgradeWithRenewModal(true)
                                }}
                                aria-label='example dialog'
                                disableCloseButton
                                style={{
                                    zIndex: 1,
                                    maxWidth: '460px',
                                    width: '476px',
                                    minHeight: '375px',
                                    border: '1px solid #1E2428',
                                    borderRadius: '4px',
                                    background: '#1E2428',
                                }}
                            >
                                {isAiPlusDataOrSecPlusCloud &&
                                enableUpgradeWithRenewalsFlag &&
                                showUpgradeWithRenewModal ? (
                                    <RenewWithUpgradeModal
                                        setShowUpgradeWithRenewModal={setShowUpgradeWithRenewModal}
                                        planId={planId}
                                        isMultiYear={isMultiYear}
                                        hasAddOns={hasAddOns}
                                        setShouldUpgradeWithRenew={setShouldUpgradeWithRenew}
                                        setOpen={setOpen}
                                        currency={currency}
                                    />
                                ) : (
                                    <RenewQuantityModal
                                        setOpen={setOpen}
                                        planId={planId}
                                        subscriptionId={originalSubscriptionId}
                                        subscriptionQuantity={subscriptionQuantity}
                                        currentProductName={currentProductName}
                                        isMultiYear={isMultiYear}
                                        hasAddOns={hasAddOns}
                                        shouldUpgrade={shouldUpgradeWithRenew}
                                        setShouldUpgradeWithRenew={setShouldUpgradeWithRenew}
                                        setShowUpgradeWithRenewModal={setShowUpgradeWithRenewModal}
                                    />
                                )}
                            </Dialog>
                        )}
                    </div>
                ) : (
                    <Link className={productLinkEnabled ? '' : 'link-disabled'}>
                        <a
                            href={link}
                            target={isResellerOrThirdParty ? '_blank' : ''}
                            rel='noreferrer'
                        >
                            {linkText}
                        </a>
                    </Link>
                )}
            </div>
            <div className={subscribeText ? 'subscribeText' : 'termData'}>{data}</div>
            {!!thirdPartyBillingInfo && <div className='resellerInfo'>{thirdPartyBillingInfo}</div>}
        </div>
    )
}

interface OptionalTerms {
    thirdPartyBillingInfo?: string | null
    hasUnpaidInvoice?: boolean
    unpaidInvoiceIds?: string[]
    planId?: string
    subscriptionId?: string
    originalSubscriptionId?: string
    currentProductName?: string
    subscriptionQuantity?: number
    isResellerOrThirdParty?: boolean | string
    setOpen?: any
    isOpen?: boolean
    canChangeRenewQuantity?: boolean
    isMultiYear?: boolean
    hasAddOns?: boolean
    isAiPlusDataOrSecPlusCloud?: boolean
    shouldUpgradeWithRenew?: boolean
    setShouldUpgradeWithRenew?: any
    enableUpgradeWithRenewalsFlag?: boolean
    showUpgradeWithRenewModal?: boolean
    setShowUpgradeWithRenewModal?: any
    currency?: string
}
