import React, {useEffect, useState} from 'react'
import {useUpgradeContext} from './Upgrade-Context'
import CircularProgress from '@pluralsight/ps-design-system-circularprogress'
import Drawer from '../common/Custom-Drawer/drawer'
import styles from './Upgrade-Order-Summary.module.scss'
import {upgradeLineItemUnitPrice} from './upgrade-line-items'
import getUpgradedProductName from '../utils/upgrade-product-name'
import {useAppContext} from '../useAppState'
import {isQuarterlyPayment, useQuarterlyPayment} from '../utils/quarterly-payment'
import {
    QUARTER,
    TOTAL_DUE_TODAY,
    TOTAL_DUE_TODAY_ANNUAL,
    TOTAL_DUE_TODAY_QUARTERLY,
} from '../common/constants'
import {calculateFinalPrice} from '../common/utils/calculateFinalPrice'
import ApplyWalletAmount from '../common/utils/ApplyWalletAmount'
import {autoApplyWallet} from '../common/utils/autoApplyWallet'
import UpgradeWalletMessage from './Upgrade-Wallet-Message'
import {isWalletEligible} from '../utils/wallet-eligible-product'
import Shimmer from '../Shimmer/Shimmer'
import {calculateDiscountSlab, shouldShowBulkDiscount} from '../utils/bulk-discount'
import {
    BulkDiscountInfoIcon,
    DiscountProrationInfoIcon,
} from '../common/Bulk-Discount/bulk-discount'

export function UpgradeOrderSummary() {
    return (
        <div className={styles.orderSummaryContainer}>
            <h4 className={styles.header}>Order Summary</h4>
            <div>
                <UpgradeOrderSummaryBody />
            </div>
        </div>
    )
}

export function UpgradeSummaryFooter() {
    const {state, setters} = useUpgradeContext()

    function toggleOrderSummaryDrawer() {
        setters.setIsMobileOrderSummaryOpen(!state.isMobileOrderSummaryOpen)
    }

    function TopRow(props: {price: number; currency: string; isLoading: boolean}) {
        return (
            <div className={styles.topRow} onClick={() => toggleOrderSummaryDrawer()}>
                <h3>
                    Order Summary
                    <span
                        className={`${styles.chevron} ${
                            state.isMobileOrderSummaryOpen ? styles.openCaret : ''
                        }`}
                    />
                </h3>
                <div>
                    {props.isLoading ? (
                        <Shimmer height='20px' width='100px' />
                    ) : (
                        `${props.price} ${props.currency}`
                    )}
                </div>
            </div>
        )
    }

    return (
        <div>
            <Drawer
                isOpen={state.isMobileOrderSummaryOpen}
                base={
                    <TopRow
                        price={state.upgradePreview.amount}
                        isLoading={state.upgradePreview.isLoading}
                        currency={state.upgradePreview.currency}
                    />
                }
                children={
                    <div>
                        <UpgradeOrderSummaryBody />
                    </div>
                }
            />
        </div>
    )
}

function UpgradeOrderSummaryBody() {
    const {state, actions} = useUpgradeContext()
    const {
        currency,
        lineItems,
        isLoading,
        loaded,
        displayPsWallet,
        newPlan,
        currentPlan,
        isB2BLegacyUpgrade,
    } = state.upgradePreview
    const [newPlanUnitPrice, setNewPlanUnitPrice] = useState(0)
    const [currentPlanUnitPrice, setCurrentPlanUnitPrice] = useState(0)
    const [loading, setLoading] = useState(true)
    const {productName, billing} = state.currentSubscription
    const newQuantity = state.planSubscriptionQuantity
    const quantity = state.planSubscriptionQuantity
    const {state: appState} = useAppContext()
    const showNewProducts = appState.applicationData.featureConfig.acgiIntegrationActive
    const walletFeature = appState.applicationData.featureConfig.enableWalletFeature
    const walletAmount = appState.walletAmount
    const upgradeProductName = getUpgradedProductName(productName, showNewProducts)
    const {tax = {subTotal: 0, tax: 0, totalWithTax: 0}, productBillingPeriod = ''} = state
    const [, setWalletSection] = useState(false)
    const {applicationData: {featureConfig: {enableWalletFeature = false} = {}} = {}} = appState
    const {
        calculatedSubtotal = 0,
        calculatedTaxAmount = 0,
        calculatedTotalPrice = 0,
    } = useQuarterlyPayment(
        appState,
        tax?.subTotal,
        tax?.tax,
        tax?.totalWithTax,
        productBillingPeriod
    )
    const targetCustomerDiscountAmount = state.targetCustomerDiscountAmount
    const [isHoveringDiscount, setIsHoveringDiscount] = useState<boolean>(false)
    const [isHoveringSubtotal, setIsHoveringSubtotal] = useState<boolean>(false)
    const showBulkDiscount = shouldShowBulkDiscount(
        appState.applicationData.featureConfig.smoothDiscountingFlag,
        targetCustomerDiscountAmount,
        state.currentSubscription.productSKU
    )

    useEffect(() => {
        if (!isLoading && loaded) {
            let newPlanUnitPrice = upgradeLineItemUnitPrice(lineItems)
            let currentPlanUnitPrice = 0
            if ((walletFeature && displayPsWallet) || isB2BLegacyUpgrade) {
                // Considering upgrade to new p&p flow, display list price
                newPlanUnitPrice = newPlan?.netPrice
                currentPlanUnitPrice = currentPlan?.netPrice
            }
            setNewPlanUnitPrice(newPlanUnitPrice)
            setCurrentPlanUnitPrice(currentPlanUnitPrice)
            setLoading(false)
        }
        setWalletSection(isWalletEligible(state))
        // eslint-disable-next-line
    }, [loaded, isLoading, lineItems, actions])

    function renderPlan() {
        if (isLoading) {
            return <Shimmer height='20px' width='100px' />
        }
        return (
            <>
                <div aria-labelledby={'plan'} className={styles.planDetail}>
                    <span>{walletFeature && displayPsWallet ? 'New Plan: ' : ''}</span>
                    {upgradeProductName}
                </div>

                {walletFeature && displayPsWallet && (
                    <div aria-labelledby={'plan'} className={styles.planDetail}>
                        <span>Current Plan:</span> {productName}
                    </div>
                )}
            </>
        )
    }

    function renderQty() {
        if (isLoading) {
            return <Shimmer height='20px' width='100px' />
        }
        return (
            <>
                <div aria-labelledby={'subscriptions'} className={styles.planDetail}>
                    {newQuantity}
                </div>
                {walletFeature && displayPsWallet && (
                    <div aria-labelledby={'subscriptions'} className={styles.planDetail}>
                        {quantity}
                    </div>
                )}
            </>
        )
    }

    function renderPrice() {
        if (isLoading) {
            return <Shimmer height='20px' width='100px' />
        }
        return (
            <>
                <div>
                    <span aria-labelledby={'price'} className={styles.planDetail}>
                        {formatMoney(newPlanUnitPrice ?? 0)} {currency}
                    </span>
                </div>
                {walletFeature && displayPsWallet && (
                    <div>
                        <span aria-labelledby={'price'} className={styles.planDetail}>
                            {formatMoney(currentPlanUnitPrice ?? 0)} {currency}
                        </span>
                    </div>
                )}
            </>
        )
    }

    function renderSubTotal() {
        if (isLoading) {
            return <Shimmer height='15px' width='100px' />
        }
        const subTotal = showBulkDiscount ? state.upgradePreview.amount : calculatedSubtotal
        return (
            <div>
                <span className={styles.planDetail}>
                    {formatMoney(subTotal)} {currency}
                </span>
            </div>
        )
    }

    function renderEstimatedTax() {
        if (isLoading) {
            return <Shimmer height='15px' width='100px' />
        }
        return (
            <div>
                <span className={styles.planDetail}>
                    {formatMoney(calculatedTaxAmount)} {currency}
                </span>
            </div>
        )
    }

    function renderDiscountAmount() {
        return (
            <div>
                <span className={styles.bulkDiscountRow}>
                    {BulkDiscountInfoIcon(isHoveringDiscount, setIsHoveringDiscount, false)}
                    <span className={styles.bulkDiscountSpan}>
                        Save {calculateDiscountSlab(newQuantity)}
                    </span>
                    {formatMoney(targetCustomerDiscountAmount)} {currency}
                </span>
            </div>
        )
    }

    return (
        <>
            <div className={styles.orderSummaryBody}>
                <div>
                    <div id={'plan'} className={`${styles.upperCase} ${styles.subHeader}`}>
                        Plan
                    </div>
                    {renderPlan()}
                </div>
                <div>
                    <div id={'subscriptions'} className={`${styles.upperCase} ${styles.subHeader}`}>
                        Licenses
                    </div>
                    {renderQty()}
                </div>
                <div>
                    <div id={'price'} className={`${styles.upperCase} ${styles.subHeader}`}>
                        Price <span className={styles.daggers}>✝</span>
                    </div>
                    {renderPrice()}
                </div>
            </div>

            <hr className={styles.horizontalRule} />

            <div className={styles.subtotalContainer}>
                {showBulkDiscount && (
                    <div className={styles.bulkDiscountBody}>
                        <div className={`${styles.upperCase} ${styles.subtotalHeader}`}>
                            Bulk Discount
                        </div>
                        {renderDiscountAmount()}
                    </div>
                )}
                <div className={styles.subtotalBody}>
                    <div className={`${styles.upperCase} ${styles.subtotalHeader}`}>
                        Subtotal
                        {showBulkDiscount &&
                            DiscountProrationInfoIcon(
                                isHoveringSubtotal,
                                setIsHoveringSubtotal,
                                true,
                                false
                            )}
                    </div>
                    {renderSubTotal()}
                </div>
                <div className={styles.subtotalBody}>
                    <div className={`${styles.upperCase} ${styles.subtotalHeader}`}>
                        Estimated tax<span className={styles.daggers}>✝✝</span>
                    </div>
                    {renderEstimatedTax()}
                </div>
                {autoApplyWallet(walletAmount, state.tax?.totalWithTax, walletFeature) && (
                    <ApplyWalletAmount
                        walletAmount={walletAmount}
                        totalPrice={state.tax?.totalWithTax}
                        currency={currency}
                    />
                )}
            </div>

            <hr className={styles.horizontalRule} />

            <div className={styles.totalContainer}>
                <div>
                    {isQuarterlyPayment(appState, productBillingPeriod)
                        ? productBillingPeriod === QUARTER
                            ? TOTAL_DUE_TODAY_QUARTERLY
                            : TOTAL_DUE_TODAY_ANNUAL
                        : TOTAL_DUE_TODAY}
                </div>
                <div>
                    {isLoading ? (
                        <Shimmer height='25px' width='100px' />
                    ) : (
                        <span>
                            {autoApplyWallet(walletAmount, state.tax?.totalWithTax, walletFeature)
                                ? calculateFinalPrice(
                                      calculatedTotalPrice ?? 0,
                                      Number(walletAmount)
                                  ).toFixed(2)
                                : calculatedTotalPrice ?? 0}{' '}
                            {currency}
                        </span>
                    )}
                </div>
            </div>
            {walletFeature && displayPsWallet && (
                <div className={styles.walletMessageDiv}>
                    <hr className={styles.horizontalRule} />
                    <UpgradeWalletMessage amount={calculatedTotalPrice} currency={currency} />
                </div>
            )}
        </>
    )
}

export function SmallLoadingDecorator() {
    return <CircularProgress size={CircularProgress.sizes.small} aria-label={'loading decorator'} />
}

function formatMoney(amount: number) {
    return amount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
