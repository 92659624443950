import React from 'react'
import './Shimmer.scss'

interface Props {
    height: string
    width: string
}

export default function Shimmer(props: Props) {
    return (
        <div
            className='shimmer'
            style={{height: props.height, width: props.width}}
            data-testid='shimmer-div'
        ></div>
    )
}
