import * as React from 'react'
import {useEffect} from 'react'

import Conditions from '../Conditions/conditions'
import Payment from '../Payment/payment'
import RenewOrderSummary from '../Renew-Order-Summary/renew-order-summary'
import ReviewAndConfirm from '../Review-And-confirm/review-and-confirm'
import styles from '../styles/renew.module.scss'
import {logComponentLoad} from '../../utils/log-component-load'

export default function RenewMain() {
    useEffect(() => {
        // Record a page view with New Relic for the "RenewMain" component load
        if (window.newrelic) {
            window.newrelic.setPageViewName('Renew')
            window.newrelic.addPageAction('ComponentLoad', {component: 'RenewMain'})
            window.newrelic.setCustomAttribute('userFlow', 'B2B_Renewal')
        }
        logComponentLoad('Payment')
        logComponentLoad('ReviewAndConfirm')
        logComponentLoad('RenewOrderSummary')
        logComponentLoad('Conditions')
    }, [])

    return (
        <div className={styles.rowLayout}>
            <div className={styles.cell1}>
                <Payment />
                <br />
                <ReviewAndConfirm />
            </div>
            <div className={styles.cell2}>
                <RenewOrderSummary />
                <Conditions />
            </div>
        </div>
    )
}
