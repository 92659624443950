(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"), require("@pluralsight/ps-design-system-icon"), require("@pluralsight/ps-design-system-actionmenu"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "prop-types", "@pluralsight/ps-design-system-icon", "@pluralsight/ps-design-system-actionmenu"], factory);
	else if(typeof exports === 'object')
		exports["plans-account-switcher"] = factory(require("react"), require("prop-types"), require("@pluralsight/ps-design-system-icon"), require("@pluralsight/ps-design-system-actionmenu"));
	else
		root["plans-account-switcher"] = factory(root["React"], root[undefined], root[undefined], root[undefined]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__8__) {
return 