import * as React from 'react'
import {useEffect} from 'react'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../definitions/param-types'
import Renew from './renew'
import {useRenewState} from './Renew-Context/renew-context'
import {RenewSetters} from './renew-setters'
import BlockedRenewRoutes from './Blocked-Renew/blocked-renew'
import {LinearLoadingDecorator} from '../Loading-Decorator/Linear-Loading-Decorator'

export default function RenewGuardV2() {
    const {state, actions, setter} = useRenewState()
    const {planId} = useParams() as ParamTypes
    useEffect(() => {
        if (state.renewGuard.loadingGuard) {
            actions.canRenew(planId).then((data) => {
                setter({type: RenewSetters.setRenewGuard, data})
            })
        }
    }, [state.renewGuard.loadingGuard, actions, setter, planId])
    return (
        <>
            {state.renewGuard.canRenew ? (
                <Renew />
            ) : state.renewGuard.loadingGuard ? (
                <LinearLoadingDecorator centerVertically={true} setToZero={true} />
            ) : (
                <BlockedRenewRoutes
                    errorMessage={state.renewGuard.reason}
                    data={state.renewGuard.data}
                    planId={planId}
                />
            )}
        </>
    )
}
