import {ReviewConfirm, ReviewConfirmMobile} from '../common/Review-Confirm/Review-Confirm'
import {useExpandContext} from './Expand-Context'
import React from 'react'
import {ExpandTermsAndConditions} from './Expand-Terms-And-Conditions'
import {getPaymentGatewayByPaymentMethod} from '../common/utils/getPaymentGateway'

export function ExpandReviewConfirm() {
    let {state, actions} = useExpandContext()
    return (
        <ReviewConfirm
            isDisabled={state.cartId === ''}
            isOpen={state.isReviewConfirmOpen}
            isActive={state.isReviewConfirmOpen}
            isComplete={false}
            stepNumber={3}
            purchase={() => {
                actions.postCheckout(state.planId, {
                    paymentMethodId: state.paymentData.paymentMethodId,
                    paymentGateway: getPaymentGatewayByPaymentMethod(state.currentPaymentMethod),
                    cartId: state.cartId,
                })
            }}
            terms={ExpandTermsAndConditions}
        />
    )
}

export function ExpandReviewConfirmMobile() {
    let {state, actions} = useExpandContext()

    return (
        <ReviewConfirmMobile
            isDisabled={state.cartId === ''}
            isOpen={state.isReviewConfirmOpen}
            isActive={state.isReviewConfirmOpen}
            isComplete={false}
            stepNumber={3}
            purchase={() =>
                actions.postCheckout(state.planId, {
                    paymentMethodId: state.currentPaymentMethod.id,
                    paymentGateway: getPaymentGatewayByPaymentMethod(state.currentPaymentMethod),
                    cartId: state.cartId,
                })
            }
            autoRenewDate={state.currentSubscription.formattedAutoRenewDate}
            productName={state.currentSubscription.productName}
            terms={ExpandTermsAndConditions}
        />
    )
}
