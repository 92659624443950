// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Sq-ktMyAR5MkCyjHVafLr {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border: 0;\n  background: transparent;\n  text-align: left;\n  cursor: pointer;\n}\n._1nz78UTxfBwRONVZwQ3L7g {\n  font-weight: 200;\n  width: 136px;\n  grid-gap: 0px;\n  gap: 0px;\n  opacity: 0px;\n  word-break: break-word;\n}\n._1hFnrl_B3dnO28MTrCqCiT {\n  color: #181818;\n  font-weight: 500;\n  text-overflow: ellipsis;\n  line-height: 1.6;\n  font-family: 'PS Commons', 'PS TT Commons Roman', Arial, sans-serif;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 21px;\n  text-align: left;\n}\n.KAWgyO7Od8uZliI8NjnIj {\n  font-family: 'PS Commons', 'PS TT Commons Roman', Arial, sans-serif;\n  font-size: 12px;\n  font-weight: 500;\n  line-height: 18px;\n  text-align: left;\n  color: var(--neutral-text-weak, #8b90c1);\n}\n._3IjFoF7nTFIWFRH-HFumoM {\n  width: Hug (24px) px;\n  height: Hug (24px) px;\n  padding: 2px 0px 0px 0px;\n  grid-gap: 8px;\n  gap: 8px;\n  opacity: 0px;\n  color: var(--neutral-text-inverse, #130f25);\n}\n", ""]);
// Exports
exports.locals = {
	"planContainer": "_3Sq-ktMyAR5MkCyjHVafLr",
	"planDetails": "_1nz78UTxfBwRONVZwQ3L7g",
	"planName": "_1hFnrl_B3dnO28MTrCqCiT",
	"licenses": "KAWgyO7Od8uZliI8NjnIj",
	"check": "_3IjFoF7nTFIWFRH-HFumoM"
};
module.exports = exports;
