// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n  --prismTopbarHeight: 56px;\n  --prismButtonBackgroundColor: rgba(138, 153, 168, 0.15);\n  --prismIconFill: #aaa;\n  --zPrismHeader: 950;\n}\n\n._1i3MGdrRlnXv80vmt9xV4I {\n  position: fixed;\n  top: 0px;\n  z-index: calc(var(--zPrismHeader) + 1);\n  right: 0px;\n  display: flex;\n  align-items: center;\n  height: var(--prismTopbarHeight)\n}\n\n@media screen and (min-width: 1200px) {\n\n._1i3MGdrRlnXv80vmt9xV4I {\n    right: auto;\n    left: inherit\n}\n  }\n", ""]);
// Exports
exports.locals = {
	"accountSwitcherContainer": "_1i3MGdrRlnXv80vmt9xV4I"
};
module.exports = exports;
