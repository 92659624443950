import {ExpandOrderSummary, ExpandSummaryFooter} from './Expand-Order-Summary/Expand-Order-Summary'
import React, {ReactNode, useEffect, useState} from 'react'
import {ExpandAccountSwitcher} from './Expand-Account-Switcher'
import {ReactComponent as PluralSightHeader} from '../images/pluralsight-logo-horizontal-white-outline.svg'
import {ExpandQuantitySelector} from './Expand-Quantity-Selector/Expand-Quantity-Selector'
import {ExpandPayment} from './Expand-Payment/Expand-Payment'
import {ExpandReviewConfirm, ExpandReviewConfirmMobile} from './Expand-Review-Confirm'
import {ExpandTermsAndConditions} from './Expand-Terms-And-Conditions'
import {ExpandStateV2, useExpandContext} from './Expand-Context'
import ExpandDataLoader from './Expand-Data-Loader'
import ThankYouPage from './Thank-You-Page'
import BlockedExpandRoutes from './Blocked-Expand/Blocked-Expand-Routes'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import styles from './Expand.module.scss'
import {BackToPlanLink} from '../Plan-Link/BackToPlanLink'
import {ProcessTransactionDecorator} from '../common/Transaction/process-transaction-decorator'
import GenericErrorPage from '../Renew-V2/generic-error-page/generic-error-page'
import Banner from '@pluralsight/ps-design-system-banner'
import ExpandModal from './ExpandModal'

const mobileWidth = 769

export function ExpandV2() {
    useEffect(() => {
        // Record a page view with New Relic for the "Add New/Expand" page load
        if (window.newrelic) {
            window.newrelic.setPageViewName('Expand')
            window.newrelic.addPageAction('PageLoad', {userFlow: 'Expand'})
            window.newrelic.setCustomAttribute('userFlow', 'B2B_Expand')
        }
    }, [])

    return (
        <ExpandStateV2>
            <ExpandProcessing />
        </ExpandStateV2>
    )
}

function StandardOrMobile() {
    const [mobileLayout, setMobileLayout] = useState(false)
    const {state} = useExpandContext()
    const guardChecked = !!state.guardResponse
    useEffect(() => {
        if (window.innerWidth < mobileWidth) {
            setMobileLayout(true)
        }
        if (window.newrelic) {
            window.newrelic.setPageViewName(mobileLayout ? 'MobileLayout' : 'StandardLayout')
            window.newrelic.addPageAction('LayoutDetermined', {
                layout: mobileLayout ? 'mobile' : 'standard',
            })
        }
    }, [mobileLayout])

    return (
        <ExpandDataLoader>
            {!guardChecked ? (
                <CenteredLoadingDecorator centerVertically={true} />
            ) : (
                <div className={styles.page}>
                    <PluralSightHeader />
                    {mobileLayout ? <MobileLayout /> : <StandardLayout />}
                </div>
            )}
        </ExpandDataLoader>
    )
}

export function BannerNotification() {
    const {state} = useExpandContext()
    const [showBanner, setShowBanner] = useState(false)
    const [bannerMessage, setBannerMessage] = useState('')
    const [bannerColor, setBannerColor] = useState<'blue' | 'green' | 'yellow' | 'red'>(
        Banner.colors.blue
    )

    useEffect(() => {
        if (state.hasBannerMessage) {
            setBannerMessage(state.hasError ? state.bannerErrorMessage : state.bannerMessage)
            setBannerColor(state.hasError ? Banner.colors.red : Banner.colors.green)
            setShowBanner(true)
            setTimeout(() => setShowBanner(false), 5000)
        }
    }, [state.hasBannerMessage, state.hasError, state.bannerMessage, state.bannerErrorMessage])

    return showBanner ? <Banner color={bannerColor}>{bannerMessage}</Banner> : null
}

function StandardLayout() {
    const {state} = useExpandContext()
    const [isModalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        setModalOpen(state.isLoading)
        if (window.newrelic) {
            window.newrelic.addPageAction('ModalState', {isOpen: state.isLoading})
        }
    }, [state.isLoading])

    return (
        <>
            <div
                className={
                    isModalOpen
                        ? `${styles.expandContainer} ${styles.pageBlur}`
                        : styles.expandContainer
                }
            >
                <BannerNotification />
                <div className={styles.backToPlan}>
                    <BackToPlanLink planId={state.planId} />
                </div>
                <div className={styles.expand}>
                    <main className={styles.mainDisplay}>
                        <ExpandMain />
                    </main>
                    <aside className={styles.aside}>
                        <div className={styles.accountSwitcher}>
                            <ExpandAccountSwitcher />
                        </div>
                        <ExpandOrderSummary />
                        <ExpandTermsAndConditions lowOnDark={true} />
                    </aside>
                </div>
            </div>
            {isModalOpen && <div className={styles.modalOverlay}></div>}
        </>
    )
}

function MobileLayout() {
    const {state} = useExpandContext()
    const [isModalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        setModalOpen(state.isLoading)
        if (window.newrelic) {
            window.newrelic.addPageAction('ModalState', {isOpen: state.isLoading})
        }
    }, [state.isLoading])

    return (
        <>
            <div
                className={
                    isModalOpen
                        ? `${styles.expandContainer} ${styles.pageBlur}`
                        : styles.expandContainer
                }
            >
                <BannerNotification />
                <div className={styles.backToPlan}>
                    <BackToPlanLink planId={state.planId} />
                </div>
                <div>
                    <main className={styles.expand}>
                        <div className={styles.accountSwitcher}>
                            <ExpandAccountSwitcher />
                        </div>
                        <div className={styles.mainDisplay}>
                            <ExpandMobileMain />
                        </div>
                    </main>
                    <div className={styles.spacer}></div>
                    <ExpandSummaryFooter />
                </div>
            </div>
            {isModalOpen && <div className={styles.modalOverlay}></div>}
        </>
    )
}

function ExpandProcessing() {
    const {state} = useExpandContext()

    if (state.hasError) {
        return (
            <BlockedExpandRoutes
                errorMessage={state.errorMessage}
                planId={state.planId}
                guardData={state.guardData}
            />
        )
    } else if (state.expandActivityIdError) {
        return <GenericErrorPage />
    } else if (state.expand?.pending) {
        return <ProcessTransactionDecorator />
    } else if (!state.expand?.loaded) {
        return <StandardOrMobile />
    } else {
        return <ThankYouPage />
    }
}

function ExpandMainLayout(props: {reviewConfirm: ReactNode}) {
    const {state, setters} = useExpandContext()
    const [isOpen, setOpen] = useState(false)

    useEffect(() => {
        setters.setIsPaymentOpen(false)
    }, [setters])

    useEffect(() => {
        if (state.isLoading) {
            setOpen(true)
            setters.setIsPaymentOpen(false)
        } else {
            setOpen(false)
            if (!state.isLoading && state.isQuantityComplete && !state.isPaymentComplete) {
                setters.setIsPaymentOpen(true)
            }
        }
        if (window.newrelic) {
            window.newrelic.addPageAction('PaymentState', {
                isLoading: state.isLoading,
                isQuantityComplete: state.isQuantityComplete,
                isPaymentComplete: state.isPaymentComplete,
            })
        }
    }, [state.isLoading, state.isQuantityComplete, state.isPaymentComplete, setters])

    return (
        <div className={isOpen ? `${styles.page} ${styles.pageBlur}` : styles.page}>
            <h2 className={styles.header}>Add Licenses</h2>
            <div className={styles.rows}>
                <ExpandQuantitySelector />
                <ExpandPayment />
                {props.reviewConfirm}
            </div>
            <ExpandModal isOpen={isOpen} onClose={() => setOpen(false)} />
        </div>
    )
}

function ExpandMain() {
    return <ExpandMainLayout reviewConfirm={<ExpandReviewConfirm />} />
}

function ExpandMobileMain() {
    return <ExpandMainLayout reviewConfirm={<ExpandReviewConfirmMobile />} />
}

export default ExpandV2
