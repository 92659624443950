import React, {useState, useEffect} from 'react'
import Tooltip from '@pluralsight/ps-design-system-tooltip'
import {Below} from '@pluralsight/ps-design-system-position'
import {InfoIcon} from '@pluralsight/ps-design-system-icon'
import {WALLET_TOOLTIP_TEXT} from '../common/constants'
import Shimmer from '../Shimmer/Shimmer'
import './Wallet.scss'
import {useAppContext} from '../useAppState'
import {currencySymbols} from '../common/constants'

interface Props {
    amount: number | null
    error: string
    isB2B: boolean
    currency: string
    zuoraId: string | null
}

export default function Wallet({isB2B, error, amount, zuoraId, currency}: Props) {
    const {actions} = useAppContext()
    const [isHovering, setIsHovering] = useState<boolean>(false)
    const currencySign = !!currencySymbols[currency] ? currencySymbols[currency] : ''
    useEffect(() => {
        if (isB2B && zuoraId) {
            actions.loadWalletAmount(zuoraId)
        }
    }, [isB2B, zuoraId])
    const tooltip = (
        <div className='walletTooltipTextContainer'>
            <Tooltip tailPosition={Tooltip.tailPositions.topCenter}>
                <p>{WALLET_TOOLTIP_TEXT}</p>
            </Tooltip>
        </div>
    )
    if (amount === null) {
        return <></>
    }
    return (
        <div className='psWallet'>
            <div className='headingContainer'>
                <div className='walletHeading'>PS Wallet</div>
                <div className='toolTip'>
                    <Below show={tooltip} when={isHovering}>
                        <InfoIcon
                            className='walletTooltipIcon'
                            color={InfoIcon.colors.textIconLowOnDark}
                            onMouseEnter={() => setIsHovering(true)}
                            onMouseLeave={() => setIsHovering(false)}
                        />
                    </Below>
                </div>
            </div>
            <div className='walletSubHeading'>
                Credit you can apply for upcoming renewals or adding licenses.
            </div>
            <div className='walletContainer'>
                <div className='balanceLabel'>WALLET BALANCE</div>
                {!error ? (
                    amount !== null ? (
                        <div className='balanceAmount'>{`${currencySign}${amount}`}</div>
                    ) : (
                        <Shimmer height='38px' width='200px' />
                    )
                ) : (
                    <div className='walletError'>{error}</div>
                )}
            </div>
        </div>
    )
}
