import {
    ENTERPRISE_SKU,
    EVERYTHING_SKU,
    PROFESSIONAL_SKU,
    PROFESSIONAL_BUNDLE_SKU,
    EVERYTHING_BUNDLE_SKU,
    ENTERPRISE_BUNDLE_SKU,
    STARTER_SKU,
} from '../common/constants'
import {UpgradeStateV2} from '../Upgrade-V2/Upgrade-Context'

export function isWalletEligible(state: UpgradeStateV2) {
    const sfdcProductId = getSdfcIdFromState(state)
    if (!sfdcProductId) {
        return false
    }
    if (
        sfdcProductId === PROFESSIONAL_SKU ||
        sfdcProductId === ENTERPRISE_SKU ||
        sfdcProductId === EVERYTHING_SKU
    ) {
        return true
    }
    if (
        sfdcProductId === PROFESSIONAL_BUNDLE_SKU ||
        sfdcProductId === ENTERPRISE_BUNDLE_SKU ||
        sfdcProductId === EVERYTHING_BUNDLE_SKU
    ) {
        return true
    }
    return false
}

function getSdfcIdFromState(state: UpgradeStateV2) {
    if (state?.guardResponse && 'product' in state?.guardResponse) {
        const productOption =
            state?.guardResponse?.product?.productCatalogInfo.productOption || null
        const sfdcProductId = (productOption && productOption.sfdcProductId) || ''
        return sfdcProductId
    }
}

export function isNewPricingUpgrade(currentProductSKU: string) {
    return (
        currentProductSKU === PROFESSIONAL_SKU ||
        currentProductSKU === ENTERPRISE_SKU ||
        currentProductSKU === EVERYTHING_SKU
    )
}

export function isB2BLegacySKU(currentProductSKU: string) {
    return (
        currentProductSKU === STARTER_SKU ||
        currentProductSKU === PROFESSIONAL_SKU ||
        currentProductSKU === ENTERPRISE_SKU ||
        currentProductSKU === EVERYTHING_SKU
    )
}

export function canDisplayPsWallet(amount: number, isNewPricingUpgrade: boolean) {
    return isNewPricingUpgrade && amount < 0
}
