import * as React from 'react'
import {useRenewState} from '../Renew-Context/renew-context'
import '../styles/conditions.scss'
import {useAppContext} from '../../useAppState'
import {ANNUAL, QUARTER} from '../../common/constants'
export default function Conditions() {
    const {state} = useRenewState()
    const {
        state: {productBillingPeriod = ''},
    } = useAppContext()
    const renewDate = state?.renew?.data?.cart.cartItems?.[0].childItems?.[0].effectiveEndDate

    const taxText =
        '✝✝ Pluralsight is required by law to collect sales tax on purchases in certain states. The actual tax amount will be calculated based on the applicable state and local sales tax when your order is processed.'

    const renewTerms = `✝ Price reflects the total for your renewed Pluralsight licenses plan. Your plan will renew on ${renewDate}, unless you request cancellation.`
    const billingCycleText = `✝✝✝ You agree to be billed per ${
        productBillingPeriod === QUARTER ? QUARTER.toLowerCase() : ANNUAL
    }. Your subscription will automatically renew unless you choose to cancel it.`
    return (
        <div className='conditions'>
            <div>{renewTerms}</div>
            <div>{taxText}</div>
            <div style={{marginBottom: '100px'}}>{billingCycleText}</div>
        </div>
    )
}
