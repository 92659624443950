import React from 'react'
import {NavLink as RouterLink, useParams} from 'react-router-dom'
import styles from './B2b-Nav-Bar.module.scss'
import {ParamTypes} from '../../../definitions/param-types'
import {useAppContext} from '../useAppState'
import Banner from '@pluralsight/ps-design-system-banner'
import {ReactComponent as GreenTick} from '../images/green-tick.svg'
import {config} from '../environment-config'

export default function B2bNavBar() {
    const {planId} = useParams() as ParamTypes
    const {state, setters} = useAppContext()
    const dismissCPQBanner = () => {
        setters.setGreenProductBanner({
            showBanner: false,
            product: '',
            message: '',
        })
    }
    return (
        <div>
            {state.greenProductBanner.showBanner && (
                <div className={styles.bannerMessage}>
                    <GreenTick />
                    <Banner style={{backgroundColor: '#377A41'}} onClick={dismissCPQBanner}>
                        {state.greenProductBanner.message}
                    </Banner>
                </div>
            )}

            <div className={styles.TabLayout}>
                <RouterLink
                    to={`${config.subscriptionLifecycle.browserPath}/plans/${planId}`}
                    className={({isActive}: {isActive: boolean}) =>
                        `${styles.navTabs}` + (isActive ? ` ${styles.activeTab}` : '')
                    }
                >
                    Plan
                </RouterLink>
                <RouterLink
                    to={`${config.subscriptionLifecycle.browserPath}/plans/${planId}/integrations`}
                    className={({isActive}: {isActive: boolean}) =>
                        `${styles.navTabs}` + (isActive ? ` ${styles.activeTab}` : '')
                    }
                >
                    Integrations
                </RouterLink>
            </div>
        </div>
    )
}
