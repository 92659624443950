import CircularProgress from '@pluralsight/ps-design-system-circularprogress'
import React from 'react'

export default function SmallLoadingDecorator(props: {margin?: boolean}) {
    return (
        <CircularProgress
            size={CircularProgress.sizes.small}
            style={props.margin ? {marginLeft: 'unset'} : {marginLeft: 'auto'}}
            aria-label={'loading decorator'}
        />
    )
}
