var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { css } from 'glamor';
import helper from './helper';
import './PaymentMethodConsumer.scss';
import CircularProgress from '@pluralsight/ps-design-system-circularprogress';
import { MessageType } from './enums/message-type.enum';
var ALLOWED_EUR_LIMIT_FOR_SEPA = 10000;
var PaymentMethodConsumer = /** @class */ (function (_super) {
    __extends(PaymentMethodConsumer, _super);
    function PaymentMethodConsumer(props) {
        var _this = _super.call(this, props) || this;
        _this.initialState = {
            completed: false,
            preflightSuccess: false,
            iframeSrc: '',
            childIframeHeight: 133,
            childIframeLoaded: false,
        };
        _this.shouldResetIframeOnCurrencyChange = function (changedProps, prevProps, props) {
            if (changedProps.includes('authAmount') && _this.props.currency === 'EUR') {
                var isAmountDecreasedFromAboveLimit = prevProps.authAmount > ALLOWED_EUR_LIMIT_FOR_SEPA &&
                    props.authAmount <= ALLOWED_EUR_LIMIT_FOR_SEPA;
                var isAmountIncreasedFromBelowLimit = prevProps.authAmount <= ALLOWED_EUR_LIMIT_FOR_SEPA &&
                    props.authAmount > ALLOWED_EUR_LIMIT_FOR_SEPA;
                return isAmountDecreasedFromAboveLimit || isAmountIncreasedFromBelowLimit;
            }
            return false;
        };
        _this.checkForUpdatedProps = _this.checkForUpdatedProps.bind(_this);
        _this.resetIframe = _this.resetIframe.bind(_this);
        _this.loadIframe = _this.loadIframe.bind(_this);
        _this.attemptIframeRender = _this.attemptIframeRender.bind(_this);
        _this.onError = _this.onError.bind(_this);
        _this.incomingMessageListener = _this.incomingMessageListener.bind(_this);
        _this.publishMessageToConsumer = _this.publishMessageToConsumer.bind(_this);
        _this.internalMessageHandler = _this.internalMessageHandler.bind(_this);
        _this.shouldResetIframeOnCurrencyChange =
            _this.shouldResetIframeOnCurrencyChange.bind(_this);
        _this.state = __assign({}, _this.initialState);
        return _this;
    }
    PaymentMethodConsumer.prototype.componentDidMount = function () {
        window.addEventListener('message', this.incomingMessageListener);
        this.loadIframe();
    };
    PaymentMethodConsumer.prototype.componentWillUnmount = function () {
        window.removeEventListener('message', this.incomingMessageListener);
    };
    PaymentMethodConsumer.prototype.componentDidUpdate = function (prevProps, prevState) {
        var changedProps = this.checkForUpdatedProps(prevProps, this.props);
        var resetOnCurrencyChange = this.shouldResetIframeOnCurrencyChange(changedProps, prevProps, this.props);
        if (changedProps.includes('token') || resetOnCurrencyChange) {
            this.resetIframe();
        }
        else if (changedProps.length > 0) {
            this.publishMessageToConsumer(MessageType.REQUEST_NEW_TOKEN, {
                description: 'Warning: New token required. Attempted to pass new props without passing new token.',
                rejectedChangedProps: changedProps,
            });
        }
    };
    PaymentMethodConsumer.prototype.checkForUpdatedProps = function (oldProps, newProps) {
        var changedProps = [];
        for (var key in oldProps) {
            if (oldProps[key] !== newProps[key]) {
                changedProps.push(key);
            }
        }
        return changedProps;
    };
    PaymentMethodConsumer.prototype.resetIframe = function () {
        this.setState(__assign({}, this.initialState), this.loadIframe);
    };
    PaymentMethodConsumer.prototype.loadIframe = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, token, authAmount, currency, iframeUrl, handle, planId, cta, netNewEmail, theme, preselectedPaymentOption;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, token = _a.token, authAmount = _a.authAmount, currency = _a.currency, iframeUrl = _a.iframeUrl, handle = _a.handle, planId = _a.planId, cta = _a.cta, netNewEmail = _a.netNewEmail, theme = _a.theme, preselectedPaymentOption = _a.preselectedPaymentOption;
                        try {
                            helper.validateProps(token, authAmount, iframeUrl, theme, handle, planId, cta, netNewEmail, preselectedPaymentOption);
                        }
                        catch (err) {
                            this.onError(MessageType.VALIDATION_ERROR, true, {
                                errorMessage: err.message,
                            });
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, helper.validateCurrency(currency, iframeUrl, token, this.onError)];
                    case 1:
                        if (_b.sent()) {
                            this.attemptIframeRender();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentMethodConsumer.prototype.attemptIframeRender = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, token, handle, planId, authAmount, cta, currency, iframeUrl, netNewEmail, theme, preselectedPaymentOption, version, iframeSrc, err_1, messageType;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, token = _a.token, handle = _a.handle, planId = _a.planId, authAmount = _a.authAmount, cta = _a.cta, currency = _a.currency, iframeUrl = _a.iframeUrl, netNewEmail = _a.netNewEmail, theme = _a.theme, preselectedPaymentOption = _a.preselectedPaymentOption;
                        version = process.env.UPMC_VERSION;
                        iframeSrc = helper.formatIframeSrc(token, authAmount, currency, iframeUrl, version, theme, netNewEmail, handle, planId, cta, preselectedPaymentOption);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, helper.attemptPreflight(iframeSrc)];
                    case 2:
                        _b.sent();
                        this.setState({
                            iframeSrc: iframeSrc,
                            preflightSuccess: true,
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _b.sent();
                        messageType = helper.determineErrorType(err_1);
                        this.onError(messageType, true, { errorMessage: err_1.message });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PaymentMethodConsumer.prototype.onError = function (messageType, hideIframe, payload) {
        this.publishMessageToConsumer(messageType, payload);
        if (hideIframe) {
            this.setState({
                completed: true,
            });
        }
    };
    PaymentMethodConsumer.prototype.incomingMessageListener = function (e) {
        var origin = e.origin;
        var data = e.data;
        var iframeUrl = this.props.iframeUrl;
        if (!helper.validateMessage(origin, iframeUrl, data)) {
            return;
        }
        var messageType = data.messageType, publish = data.publish, hideIframe = data.hideIframe, payload = data.payload;
        if (publish) {
            this.publishMessageToConsumer(messageType, payload);
        }
        else {
            this.internalMessageHandler(messageType, payload);
        }
        if (hideIframe) {
            this.setState({
                completed: true,
            });
        }
    };
    PaymentMethodConsumer.prototype.publishMessageToConsumer = function (messageType, payload) {
        var _a = this.props, messageHandler = _a.messageHandler, token = _a.token, iframeUrl = _a.iframeUrl;
        messageHandler(messageType, payload);
        try {
            helper.logConsumerMessage(token, messageType, payload, iframeUrl);
        }
        catch (err) {
            //Do nothing with the error
        }
    };
    PaymentMethodConsumer.prototype.internalMessageHandler = function (messageType, payload) {
        switch (messageType) {
            case MessageType.INITIAL_LOAD:
                this.setState({
                    childIframeLoaded: true,
                });
                break;
            case MessageType.RESIZE:
                this.setState({
                    childIframeHeight: payload.childIframeHeight,
                });
                break;
        }
    };
    PaymentMethodConsumer.prototype.render = function () {
        var _a = this.state, completed = _a.completed, iframeSrc = _a.iframeSrc, preflightSuccess = _a.preflightSuccess, childIframeHeight = _a.childIframeHeight, childIframeLoaded = _a.childIframeLoaded;
        var upmcContainer = css({
            height: "".concat(childIframeHeight, "px"),
        });
        return (_jsx("div", __assign({ "data-testid": "payment-method-consumer" }, { children: !completed && (_jsxs("div", __assign({}, upmcContainer, { className: "upmc-container" }, { children: [_jsx("div", __assign({ className: "upmc-loading-container", hidden: childIframeLoaded }, { children: _jsx(CircularProgress, { "aria-label": "Loading..." }) })), preflightSuccess && iframeSrc && (_jsx("iframe", { id: "upmc-iframe", src: iframeSrc, allow: "payment" }))] }))) })));
    };
    return PaymentMethodConsumer;
}(React.Component));
export default PaymentMethodConsumer;
