import React, {useEffect} from 'react'
import {useAppContext} from '../useAppState'
import {useUpgradeContext} from './Upgrade-Context'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../definitions/param-types'
import {SubLifeAccountSwitcher} from '../common/Sub-Life-Account-Switcher/Sub-Life-Account-Switcher'
import styles from './Upgrade-Account-Switcher.module.scss'

export function UpgradeAccountSwitcher() {
    const {planId} = useParams() as ParamTypes
    const {state: appState, actions} = useAppContext()
    const {actions: upgradeActions} = useUpgradeContext()

    let adminList = appState.applicationData.planInfo.admin

    useEffect(() => {
        actions.loadPlanDisplayName(adminList)
    }, [adminList, actions])

    function reset() {
        upgradeActions.resetState()
    }

    function SingleAccountDisplay(props: {planId: string}) {
        return (
            <div className={styles.singleAccount}>
                Account: <span className={styles.accountName}>{props.planId}</span>
            </div>
        )
    }

    return (
        <div>
            {adminList.length > 1 ? (
                <SubLifeAccountSwitcher
                    planIds={adminList}
                    currentPlanId={planId}
                    displayName={appState.planDisplayNames}
                    clearData={() => reset()}
                />
            ) : (
                <SingleAccountDisplay planId={planId} />
            )}
        </div>
    )
}
