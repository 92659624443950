import {AI_PLUS_DATA_SKU, EVERYTHING_NEW_SKU, SEC_PLUS_CLOUD_SKU} from '../common/constants'

export const calculateDiscountSlab = (quantity: number): string => {
    let discountSlab: number
    if (quantity >= 2 && quantity <= 20) {
        discountSlab = 0
    } else if (quantity >= 21 && quantity <= 50) {
        discountSlab = 5
    } else if (quantity >= 51 && quantity <= 100) {
        discountSlab = 5
    } else if (quantity >= 101 && quantity <= 250) {
        discountSlab = 13
    } else if (quantity >= 251 && quantity <= 1000) {
        discountSlab = 21
    } else {
        discountSlab = 0
    }
    return discountSlab.toFixed(2) + '%'
}

export const isNewB2BSKU = (sku: string): boolean => {
    return sku === EVERYTHING_NEW_SKU || sku === AI_PLUS_DATA_SKU || sku === SEC_PLUS_CLOUD_SKU
}

export const shouldShowBulkDiscount = (
    featureFlag: boolean,
    discountAmount: number,
    currentSKU: string
): boolean => {
    return featureFlag && discountAmount > 0 && isNewB2BSKU(currentSKU)
}
