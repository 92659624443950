import React from 'react'
import {arrayOf, bool, func} from 'prop-types'
import ActionMenu from '@pluralsight/ps-design-system-actionmenu'

import {Plan} from './plan'
import {AccountButton} from './account-button'
import * as accountSwitcherPropTypes from './prop-types'
import css from './dropdown.css'

export function AccountSwitcherDropdown({
  currentPlan,
  plans,
  isOpen,
  displayLicenses,
  onRequestToggleVisibility,
  onSelect
}) {
  const currentPlanDisplayName = getCurrentPlanDisplayName(currentPlan)
  const isEnabled = hasAbilityToNavigatePlans(plans, currentPlan)

  return (
    <div className={css.container}>
      <AccountButton
        onClick={onRequestToggleVisibility}
        isEnabled={isEnabled}
        displayName={currentPlanDisplayName}
        isActive={isOpen}
      />
      {isOpen && (
        <>
          <div onClick={onRequestToggleVisibility} className={css.overlay} />
          <div className={css.popup}>
            <ActionMenu className={css.scrollContainer}>
              {plans.sort(byDisplayName).map(plan => (
                <ActionMenu.Item
                  onClick={() => onSelect(plan.planId)}
                  key={plan.planId}
                  className={css.actionMenuItem}
                >
                  <Plan
                    plan={plan}
                    isSelected={plan.planId === currentPlan.planId}
                    displayLicenses={displayLicenses}
                    key={plan.planId}
                  />
                </ActionMenu.Item>
              ))}
            </ActionMenu>
          </div>
        </>
      )}
    </div>
  )
}

AccountSwitcherDropdown.propTypes = {
  currentPlan: accountSwitcherPropTypes.currentPlan.isRequired,
  plans: arrayOf(accountSwitcherPropTypes.plan).isRequired,
  isOpen: bool,
  displayLicenses: bool,
  onRequestToggleVisibility: func,
  onSelect: func
}

const getCurrentPlanDisplayName = currentPlan =>
  currentPlan ? currentPlan.displayName : 'Unknown'

const hasOnePlanButUserIsViewingADifferentPlan = (plans, currentPlan) =>
  plans.length === 1 && currentPlan && currentPlan.planId !== plans[0].planId

const hasAbilityToNavigatePlans = (plans, currentPlan) =>
  plans.length > 1 ||
  hasOnePlanButUserIsViewingADifferentPlan(plans, currentPlan)

const byDisplayName = (a, b) => a.displayName.localeCompare(b.displayName)
