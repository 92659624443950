import {BannerMessageKeys} from './enums'
export const STARTER_SKU = 'Starter-SKU'
export const PROFESSIONAL_SKU = 'BUS-PROF'
export const ENTERPRISE_SKU = 'BUS-ENT'
export const EVERYTHING_SKU = 'PS-EVERYTHING'
export const EVERYTHING_NEW_SKU = 'PS-EVERYTHING-NEW'
export const AI_PLUS_DATA_SKU = 'AI-PLUS-DATA'
export const SEC_PLUS_CLOUD_SKU = 'SEC-PLUS-CLOUD'

export const STARTER_BUNDLE_SKU = 'BUS-STARTER-BUNDLE'
export const PROFESSIONAL_BUNDLE_SKU = 'BUS-PROF-BUNDLE'
export const ENTERPRISE_BUNDLE_SKU = 'BUS-ENT-BUNDLE'
export const EVERYTHING_BUNDLE_SKU = 'PS-EVERY-LA-BUND'
export const EVERYTHING_NEW_BUNDLE_SKU = 'PS-EVERY-NEW-BUND'
export const AI_PLUS_DATA_BUNDLE_SKU = 'AI-DATA-BUND'
export const SEC_PLUS_CLOUD_BUNDLE_SKU = 'SEC-CLOUD-BUND'
export const B2B = 'b2b'
export const QUARTER = 'Quarter'
export const ACTA = 'acta'
export const ANNUAL = 'annual'
export const TOTAL_DUE_TODAY_QUARTERLY = 'Total due today (Quarterly)'
export const TOTAL_DUE_TODAY_ANNUAL = 'Total due today (Annual)'
export const TOTAL_DUE_TODAY = 'Total due today'
export const TOTAL_QUARTERLY = 'Total (Quarterly)'
export const TOTAL_ANNUAL = 'Total (Annual)'
export const TOTAL = 'Total'

export function getUpgradeSku(sku: string, showNewProducts: boolean) {
    if (showNewProducts) {
        switch (sku) {
            case STARTER_SKU:
                return EVERYTHING_NEW_SKU
            case PROFESSIONAL_SKU:
                return EVERYTHING_NEW_SKU
            case AI_PLUS_DATA_SKU:
                return EVERYTHING_NEW_SKU
            case SEC_PLUS_CLOUD_SKU:
                return EVERYTHING_NEW_SKU
            case ENTERPRISE_SKU:
                return EVERYTHING_NEW_SKU
            case EVERYTHING_SKU:
                return EVERYTHING_NEW_SKU
            default:
                throw new Error(`Sku ${sku} is invalid for upgrade`)
        }
    } else {
        switch (sku) {
            case STARTER_SKU:
                return EVERYTHING_NEW_SKU
            case AI_PLUS_DATA_SKU:
                return EVERYTHING_NEW_SKU
            case SEC_PLUS_CLOUD_SKU:
                return EVERYTHING_NEW_SKU
            case PROFESSIONAL_SKU:
                return EVERYTHING_NEW_SKU
            case ENTERPRISE_SKU:
                return EVERYTHING_NEW_SKU
            case EVERYTHING_SKU:
                return EVERYTHING_NEW_SKU
            default:
                throw new Error(`Sku ${sku} is invalid for upgrade`)
        }
    }
}

export function getBundleSku(sku: string) {
    switch (sku) {
        case STARTER_SKU:
            return STARTER_BUNDLE_SKU
        case PROFESSIONAL_SKU:
            return PROFESSIONAL_BUNDLE_SKU
        case ENTERPRISE_SKU:
            return ENTERPRISE_BUNDLE_SKU
        case EVERYTHING_SKU:
            return EVERYTHING_BUNDLE_SKU
        case EVERYTHING_NEW_SKU:
            return EVERYTHING_NEW_BUNDLE_SKU
        case AI_PLUS_DATA_SKU:
            return AI_PLUS_DATA_BUNDLE_SKU
        case SEC_PLUS_CLOUD_SKU:
            return SEC_PLUS_CLOUD_BUNDLE_SKU
        default:
            return `Sku ${sku} is invalid for bundle`
    }
}

type BannerContent = {
    message: string
    href?: string | null
    hrefText?: string | null
}

export const BANNER_MESSAGE: Record<BannerMessageKeys, BannerContent> = {
    DEFAULT: {
        message: '',
    },
    LEARNING: {
        message:
            "Explore our new learning plans! We've updated our options to bring you more value at a lower price.",
        href: 'cmsdkcsd',
        hrefText: `Click here to see what's new!`,
    },
    TRANSACTION_LINKS: {
        message:
            "Explore our new learning plans! We've updated our options to bring you more value at a lower price.",
    },
}

export const WALLET_TOOLTIP_TEXT =
    'PS Wallet credit can be used for upcoming renewals, upgrading your Pluralsight plan, or adding additional users to your current plan.'

export const currencySymbols: any = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    INR: '₹',
    ZAR: 'R',
}

export const monthlyPriceForEverythingPlan: any = {
    USD: '47',
    EUR: '42',
    GBP: '35.75',
    INR: '3950',
    ZAR: '837',
}

export const BULK_DISCOUNT_TOOLTIP_TEXT = '5% discount applied for purchasing 50+ licenses.'

export const PRORATION_DISCOUNT_TOOLTIP_TEXT = (isUpgrade: boolean) =>
    `Discount${isUpgrade ? ' & proration' : ''} applied.`

export const UPGRADE_PRICE_LOCK_MESSAGES = {
    heading: "You're upgrading to Everything at no extra cost.",
    subheading:
        'You will be charged nothing today and can review your plan renewal options at your next renewal',
}
