import React, {useEffect} from 'react'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../definitions/param-types'
import {LinearLoadingDecorator} from '../Loading-Decorator/Linear-Loading-Decorator'
import {useAsyncError} from '../useAsyncError'
import ModifyCheckout from './modify-checkout'
import {useModifyState, ModifyRequestV2} from './Modify-Context/modify-context'
import {ModifySetters} from './modify-setters'
import ProcessModify from './Process-Modify/process-modify'
export default function ModifyLandingPage() {
    const {labsQuantity} = useParams() as ParamTypes
    const {state, actions, setter} = useModifyState()
    let {planId, subscriptionId} = useParams() as ParamTypes
    const throwError = useAsyncError()

    const quantityLabs: number = parseInt(labsQuantity)
    useEffect(() => {
        actions
            .getSubscription(subscriptionId)
            .then((res: any) => {
                setter({type: ModifySetters.currentSubscription, data: res})
                modifyAmend(
                    res.productSKU,
                    quantityLabs > state.loadBusinessAccountQuantity
                        ? state.loadBusinessAccountQuantity
                        : quantityLabs
                )
            })
            .catch((e) => throwError(e.message))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions, planId, setter, throwError, state.subscriptionsToAdd, subscriptionId])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const modifyAmend = (sku: string, labsQuantityValue: number) => {
        const payload: ModifyRequestV2 = {
            sku: sku,
            quantity: state.loadBusinessAccountQuantity,
            amendedSubscriptionId: subscriptionId,
            childComponents: [{sku: 'LABS', quantity: labsQuantityValue}],
            shouldCheckout: false,
            isModify: true,
        }

        actions
            .postAmend(planId, payload)
            .then((res: any) => {
                let index = res?.cart?.cartItems.length
                if (res.status === 'ERROR') {
                    setter({type: ModifySetters.setModifyError, data: null})
                }
                if (res?.cart && res?.cart?.cartItems[index - 1].childItems[0]) {
                    let {
                        listTotal,
                        listPrice,
                        productId,
                        quantity,
                        productCode,
                        productOption,
                        endDate,
                        cartId,
                        additionalDiscount,
                    } = res?.cart?.cartItems[index - 1].childItems[0]
                    let data = {
                        amount: parseInt(listTotal),

                        taxAmount: 0,
                        discountAmount: additionalDiscount,
                        percentDiscount: 0,
                        currency: res?.cart?.currencyCode,
                        cartId: cartId,
                        termEndDate: endDate,
                        lineItems: [
                            {
                                productId: productId,
                                productOptionId: productOption,
                                amount: parseInt(listTotal),
                                unitPrice: parseInt(listPrice),
                                quantity: quantity,
                                uom: '',
                                productName: productCode,
                            },
                        ],
                    }
                    setter({type: ModifySetters.initializeModify, data})
                } else {
                    setter({type: ModifySetters.initializeModify, data: null})
                    setter({type: ModifySetters.setProcessing, data: null})
                }
            })
            .catch((e) => {
                throwError(e.message)
            })
    }

    return (
        <>
            {!state.preview.isInitialized ? (
                <LinearLoadingDecorator centerVertically={true} />
            ) : (
                <>{state.processingModify ? <ProcessModify /> : <ModifyCheckout />}</>
            )}
        </>
    )
}
