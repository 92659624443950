import Button from '@pluralsight/ps-design-system-button'
import * as React from 'react'
import {config} from '../../environment-config'
import {useAppContext} from '../../useAppState'
import {useRenewState} from '../Renew-Context/renew-context'
import {getLineItemIndexOfRenewingToSku} from '../../common/utils/renew-utils'
import '../styles/thank-you.scss'
import OrderReceiptSummary from './order-receipt-summary'

export default function ThankYouRenew() {
    const viewInvoiceHref = `${config.billing.url}/billing/history`
    const {state: appState} = useAppContext()
    const {state} = useRenewState()

    let lineItemData
    const lineItem = state.result.data.cart.cartItems.filter((cartItem) =>
        cartItem.hasOwnProperty('childItems')
    )
    const {currentProductSKU} = state.renew.data
    const lineItemIndex = getLineItemIndexOfRenewingToSku(currentProductSKU)
    lineItemData = lineItem[lineItemIndex].childItems

    return (
        <div className='center'>
            <div className='heading'>
                Thank You, <span>{appState.applicationData.user.firstName}!</span>
            </div>
            <div className='subHeading'>
                Your <b>{lineItemData[0].productName}</b> plan has been renewed for another year.
            </div>
            <div className='buttonRow'>
                <div>
                    <Button
                        appearance={Button.appearances.stroke}
                        href={viewInvoiceHref}
                        target='_blank'
                    >
                        View invoice
                    </Button>
                </div>
                <div>
                    <Button href={`${config.subscriptionLifecycle.browserPath}/plans`}>
                        Back to subscription
                    </Button>
                </div>
            </div>
            <hr></hr>
            <div className='notificationText'>
                Your full receipt has been sent to {appState.applicationData.user.email}
            </div>
            <OrderReceiptSummary />
        </div>
    )
}
