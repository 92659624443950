import Button from '@pluralsight/ps-design-system-button'
import {CodeIcon, LockIcon} from '@pluralsight/ps-design-system-icon'
import * as React from 'react'
import {RowHeaderRightIcon} from '../../common/Row-Header/Row-Header'
import {useRenewState} from '../Renew-Context/renew-context'
import Checkbox from '@pluralsight/ps-design-system-checkbox'
import {useState} from 'react'
import '../styles/review-and-confirm.scss'
import {RenewPayloadV2} from '../../../../definitions/renew'
import {RenewSetters} from '../renew-setters'
import {PaymentMethod} from '../../../../definitions/payment-methods'
import {useParams} from 'react-router-dom'
import {ParamTypes} from '../../../../definitions/param-types'
export default function ReviewAndConfirm() {
    const {state, setter, actions} = useRenewState()
    const [reviewed, setReviewed] = useState(false)
    const [checkboxError, setCheckboxError] = useState(false)
    let {planId} = useParams() as ParamTypes

    React.useEffect(() => {}, [state.payment.paymentMethodId, state.invoiced])

    function handleCheck() {
        setReviewed(!reviewed)
        if (checkboxError) {
            setCheckboxError(false)
        }
    }
    function purchase() {
        const isStage =
            window.location.hostname === 'app-stage.pluralsight.com' ||
            window.location.hostname === 'app-local.pluralsight.com'
        if (!reviewed) {
            setCheckboxError(true)
        } else {
            const paymentMethod = (state.payment.savedPayments as PaymentMethod[]).find(
                (pm) => pm.id === state.payment.paymentMethodId
            )

            let payload: RenewPayloadV2
            if (paymentMethod) {
                payload = {
                    paymentMethodId: state.payment.paymentMethodId,
                    paymentGateway:
                        paymentMethod?.type === 'CreditCard'
                            ? 'Adyen 2.0'
                            : paymentMethod.type === 'ACH' || paymentMethod.type === 'BankTransfer'
                            ? isStage
                                ? 'Stripe'
                                : 'PS LLC'
                            : 'PayPal Express Checkout',
                    cartId: state.renew.data.cart.cartId,
                    sku: state.renew?.data?.cart?.cartItems?.[0]?.sku,
                }
            } else {
                payload = {
                    paymentMethodId: state.payment.paymentMethodId,
                    paymentGateway:
                        state.newPayment.paymentMethodType === 'CreditCard'
                            ? 'Adyen 2.0'
                            : state.newPayment.paymentMethodType === 'ACH' ||
                              state.newPayment.paymentMethodType === 'BankTransfer'
                            ? isStage
                                ? 'Stripe'
                                : 'PS LLC'
                            : 'PayPal Express Checkout',
                    cartId: state.renew.data.cart.cartId,
                    sku: state.renew?.data?.cart?.cartItems?.[0]?.sku,
                }
            }
            setter({type: RenewSetters.setProcessing, data: null})
            actions
                .renewPlan(payload, planId)
                .then((data1: any) => {
                    let data = {
                        cart: {
                            amount: data1?.orderDetails?.totalPrice,
                            taxAmount: 0, // not defined in renew response example
                            discountAmount: 0,
                            percentDiscount: 0, // not defined in renew response example
                            currency: data1?.orderDetails?.currencyISOCode,
                            termEndDate: data1?.orderDetails?.orderItems[0].endDate, // not defined in renew response example
                            cartItems: state?.renew?.data?.cart.cartItems,
                        },
                    }
                    setter({type: RenewSetters.setResult, data})
                })
                .catch((e) => {
                    setter({type: RenewSetters.setRenewError, data: null})
                })
        }
    }
    const isOpen = !!state.payment.paymentMethodId || state.invoiced
    const checkBoxText =
        'By checking here and continuing, I agree to the Pluralsight Terms of Use unless my company and Pluralsight have executed a written agreement for the services purchased hereunder, in which case such executed agreement shall govern the additional subscriptions purchased hereunder.'
    const renewText =
        'Your selected plan will automatically renew unless you cancel 30 days prior to your renewal date. For further details regarding automatic renewal, cancellations, and our refund policy, please review our Terms of Use.'
    return (
        <>
            <div className='review'>
                <RowHeaderRightIcon
                    headerText={'Review and confirm'}
                    active={isOpen}
                    complete={isOpen && reviewed}
                    stepNumber={2}
                    alternateText={'Secure checkout'}
                    icon={<LockIcon size={CodeIcon.sizes.medium} />}
                />
                {isOpen && (
                    <div className='content'>
                        <div className='margin'>{renewText}</div>
                        <div style={{display: 'flex'}} className='margin'>
                            <Checkbox
                                onCheck={handleCheck}
                                error={checkboxError}
                                checked={reviewed}
                                data-testid='review-check'
                            />
                            <span style={{float: 'right'}}>{checkBoxText}</span>
                        </div>
                        <div className='margin'>
                            <Button
                                appearance={Button.appearances.primary}
                                onClick={purchase}
                                data-testid='purchase-button'
                            >
                                Purchase
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
