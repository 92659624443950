import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {NotFoundErrorPage} from '@pluralsight/ps-design-system-errors'
import {AnalyticsPageView} from './Main-Content/Analytics-page-view'
import {TransactionAuth} from './Router/Transaction-Auth/TransactionAuth'
import {useAppContext} from './useAppState'
import UpsellStateProviderV2 from './Modify_v2/Modify-Context/modify-context'
import ModifyGuardV2 from './Modify_v2/modify-guard'
import {CenteredPlanSelection} from './Plan-Selection/CenteredPlanSelection'
import {MainContent} from './Main-Content/Main-Content'
import RenewStateProviderV2 from './Renew-V2/Renew-Context/renew-context'
import RenewGuardV2 from './Renew-V2/renew-guard'
import {ExpandV2} from './expand-v2/Expand'
import UpgradeV2 from './Upgrade-V2/Upgrade'

export function TransactionRoutes() {
    const {state} = useAppContext()
    return (
        <AnalyticsPageView>
            <Routes>
                <Route
                    path='expand'
                    render={() => <Navigate to='expand/:planId' replace />}
                    element={
                        <CenteredPlanSelection destinationFn={(planId) => `expand/${planId}`} />
                    }
                />
                <Route
                    path='expand/:planId'
                    element={
                        <TransactionAuth>
                            <ExpandV2 />
                        </TransactionAuth>
                    }
                />
                <Route
                    path='expand/:planId/:subscriptionId/'
                    element={
                        <TransactionAuth>
                            <ExpandV2 />
                        </TransactionAuth>
                    }
                />
                <Route
                    path='account'
                    render={() => <Navigate to=':planId' replace />}
                    element={
                        <CenteredPlanSelection destinationFn={(planId) => `account/${planId}`} />
                    }
                />
                <Route
                    path=':planId'
                    element={
                        <TransactionAuth>
                            <MainContent />
                        </TransactionAuth>
                    }
                />
                <Route
                    path='renew'
                    render={() => <Navigate to='renew/:planId' replace />}
                    element={
                        <CenteredPlanSelection destinationFn={(planId) => `renew/${planId}`} />
                    }
                />
                <Route
                    path={
                        state.applicationData.featureConfig.canChangeRenewQuantity
                            ? 'renew/:planId/:subscriptionId/:renewQuantity'
                            : 'renew/:planId/:subscriptionId'
                    }
                    element={
                        <TransactionAuth>
                            <RenewStateProviderV2>
                                <RenewGuardV2 />
                            </RenewStateProviderV2>
                        </TransactionAuth>
                    }
                />
                <Route
                    path='upsell'
                    render={() => (
                        <Navigate
                            to='plans/:planId/productOption/:productOptionId/modify'
                            replace
                        />
                    )}
                    element={
                        <CenteredPlanSelection
                            destinationFn={(planId, productOptionId) =>
                                `plans/${planId}/productOption/${productOptionId}/modify`
                            }
                        />
                    }
                />
                {state.applicationData.featureConfig.modifyFeatureFlag && (
                    <Route
                        path='plans/:planId/productOption/:productOptionId/modify/:subscriptionId/:labsQuantity'
                        element={
                            <TransactionAuth>
                                <UpsellStateProviderV2>
                                    <ModifyGuardV2 />
                                </UpsellStateProviderV2>
                            </TransactionAuth>
                        }
                    />
                )}
                <Route
                    path='upgrade'
                    render={() => <Navigate to='upgrade/:planId' replace />}
                    element={
                        <CenteredPlanSelection destinationFn={(planId) => `upgrade/${planId}`} />
                    }
                />
                {state.applicationData.featureConfig.upgradeFeatureFlag && (
                    <Route
                        path={`upgrade/:planId/:subscriptionId`}
                        element={
                            <TransactionAuth>
                                <UpgradeV2 />
                            </TransactionAuth>
                        }
                    />
                )}
                <Route path='*' element={NotFoundErrorPage} />
            </Routes>
        </AnalyticsPageView>
    )
}
