import * as React from 'react'
import {useRenewState} from '../Renew-Context/renew-context'
import '../styles/renew-order-summary.scss'
import {useState, useEffect} from 'react'
import {formatPrice} from '../../common/utils/formatPrice'
import {useAppContext} from '../../useAppState'
import {isQuarterlyPayment, useQuarterlyPayment} from '../../utils/quarterly-payment'
import {
    QUARTER,
    TOTAL_DUE_TODAY,
    TOTAL_DUE_TODAY_ANNUAL,
    TOTAL_DUE_TODAY_QUARTERLY,
} from '../../common/constants'
import {calculateFinalPrice} from '../../common/utils/calculateFinalPrice'
import ApplyWalletAmount from '../../common/utils/ApplyWalletAmount'
import {autoApplyWallet} from '../../common/utils/autoApplyWallet'
import {calculateDiscountSlab, shouldShowBulkDiscount} from '../../utils/bulk-discount'
import {getLineItemIndexOfRenewingToSku} from '../../common/utils/renew-utils'
import {
    BulkDiscountInfoIcon,
    DiscountProrationInfoIcon,
} from '../../common/Bulk-Discount/bulk-discount'

export default function RenewOrderSummary() {
    const {state} = useRenewState()
    const [maxWidth] = useState(760)
    const appContext = useAppContext()
    const [collapsed, setCollapsed] = useState(window.innerWidth < maxWidth ? true : false)
    const [mobileView, setMobileView] = useState(window.innerWidth < maxWidth ? true : false)
    const currency = state.renew.data.cart.currencyCode
    const walletFeature = appContext.state.applicationData.featureConfig.enableWalletFeature
    const walletAmount = appContext.state.walletAmount
    const targetCustomerDiscountAmount = state.renew.data.cart.targetCustomerDiscountAmount
    const quantity = state.renew.data.quantity
    const subtotal = state.renew.data.subtotal
    const [isHoveringDiscount, setIsHoveringDiscount] = useState<boolean>(false)
    const [isHoveringSubtotal, setIsHoveringSubtotal] = useState<boolean>(false)
    const showBulkDiscount = shouldShowBulkDiscount(
        appContext.state.applicationData.featureConfig.smoothDiscountingFlag,
        targetCustomerDiscountAmount,
        state.renew.data.currentProductSKU
    )

    let lineItemData
    const lineItem = state.renew.data.cart.cartItems.filter((cartItem) =>
        cartItem.hasOwnProperty('childItems')
    )
    const {currentProductSKU} = state.renew.data
    const lineItemIndex = getLineItemIndexOfRenewingToSku(currentProductSKU)
    lineItemData = lineItem[lineItemIndex].childItems

    const toggleColllapse = (e: any) => {
        setCollapsed(!collapsed)
    }

    const {state: appState} = useAppContext()
    const {tax = {data: {subTotal: 0, tax: 0, totalWithTax: 0}}} = state
    const {productBillingPeriod = ''} = appState

    const {calculatedSubtotal, calculatedTaxAmount, calculatedTotalPrice} = useQuarterlyPayment(
        appState,
        subtotal,
        tax?.data?.tax,
        tax?.data?.totalWithTax,
        productBillingPeriod
    )

    useEffect(() => {
        function handleResize() {
            setMobileView(window.innerWidth < maxWidth ? true : false)
            setCollapsed(window.innerWidth < maxWidth ? true : false)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [maxWidth])

    useEffect(() => {
        const zuoraId: string | null = state.renew.data.cart.account.billTo.zuoraId || null
        if (zuoraId && walletFeature) {
            appContext.actions.loadWalletAmount(zuoraId)
        }
    }, [walletFeature, state.renew.data])

    return (
        <div className='orderSummary'>
            {mobileView ? (
                <h4 className='mobileHeader' onClick={toggleColllapse}>
                    Order Summary <span className={`arrow-${collapsed ? 'up' : 'down'}`}></span>
                    <span className='amount'>
                        {formatPrice(calculatedTotalPrice ?? 0)} {currency}
                    </span>
                    <hr className={`${collapsed ? 'hide-line' : ''}`}></hr>
                </h4>
            ) : (
                <h4 className='header'>Order summary</h4>
            )}
            {!collapsed || !mobileView ? (
                <>
                    <div className='summary'>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Plan</th>
                                    <th>Licenses</th>
                                    <th style={{textAlign: 'right'}}>
                                        Price<sup> ✝</sup>
                                    </th>
                                </tr>
                                {lineItemData.map((lineItem: any) => {
                                    return formatLineItemProduct(
                                        lineItem,
                                        currency,
                                        showBulkDiscount
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    <hr />

                    <div className='subTotal'>
                        {calculatedSubtotal && showBulkDiscount && (
                            <div className='row spacer'>
                                <div className='label'>
                                    Bulk Discount
                                    {BulkDiscountInfoIcon(
                                        isHoveringDiscount,
                                        setIsHoveringDiscount,
                                        false
                                    )}
                                    <span className={'bulkDiscount'}>
                                        Save {calculateDiscountSlab(quantity)}
                                    </span>
                                </div>
                                <div className='values'>
                                    {formatPrice(targetCustomerDiscountAmount)} {currency}
                                </div>
                            </div>
                        )}
                        <div className='row spacer'>
                            <div className='label'>
                                Subtotal
                                {showBulkDiscount &&
                                    DiscountProrationInfoIcon(
                                        isHoveringSubtotal,
                                        setIsHoveringSubtotal,
                                        false,
                                        false
                                    )}
                            </div>
                            <div className='values'>
                                {formatPrice(calculatedSubtotal ?? 0)} {currency}
                            </div>
                        </div>
                        <div className='row spacer'>
                            <div className='label'>
                                Estimated Tax<sup> ✝✝</sup>
                            </div>
                            <div className='values'>
                                {formatPrice(calculatedTaxAmount || 0)} {currency}
                            </div>
                        </div>
                        {autoApplyWallet(
                            walletAmount,
                            state?.tax?.data?.totalWithTax,
                            walletFeature
                        ) && (
                            <ApplyWalletAmount
                                walletAmount={walletAmount}
                                totalPrice={state.tax.data.totalWithTax}
                                currency={currency}
                            />
                        )}
                    </div>

                    <hr />

                    <div className='total'>
                        <div className='amountLabel'>
                            {isQuarterlyPayment(appState, productBillingPeriod)
                                ? productBillingPeriod === QUARTER
                                    ? TOTAL_DUE_TODAY_QUARTERLY
                                    : TOTAL_DUE_TODAY_ANNUAL
                                : TOTAL_DUE_TODAY}
                        </div>
                        <div className='amount'>
                            {autoApplyWallet(walletAmount, calculatedTotalPrice, walletFeature)
                                ? calculateFinalPrice(
                                      calculatedTotalPrice ?? 0,
                                      Number(walletAmount)
                                  ).toFixed(2)
                                : formatPrice(calculatedTotalPrice ?? 0)}{' '}
                            {currency}
                        </div>
                    </div>
                </>
            ) : (
                <div></div>
            )}
        </div>
    )
}

function formatLineItemProduct(lineItem: any, currency: string, showBulkDiscount: boolean) {
    return (
        <tr key={lineItem.productId}>
            <td>{lineItem.productName}</td>
            <td>{lineItem.quantity}</td>
            <td style={{textAlign: 'right'}}>
                {formatPrice(showBulkDiscount ? lineItem.listPrice : lineItem.netPrice)} {currency}
            </td>
        </tr>
    )
}
