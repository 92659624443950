import React, {ReactNode, useEffect, useState} from 'react'
import {UpgradeContextV2, useUpgradeContext} from './Upgrade-Context'
import {UpgradeAccountSwitcher} from './Upgrade-Account-Switcher'
import {UpgradeOrderSummary, UpgradeSummaryFooter} from './Upgrade-Order-Summary'
import {UpgradeTermsAndConditions} from './Upgrade-Terms-And-Conditions'
import BlockedUpgradeRoutes from './Blocked-Upgrade/Blocked-Upgrade-Routes'
import {UpgradeReviewConfirm, UpgradeReviewConfirmMobile} from './Upgrade-Review-Confirm'
import {ReactComponent as PluralSightHeader} from '../images/pluralsight-logo-horizontal-white-outline.svg'
import UpgradeDataLoader from './Upgrade-Data-Loader'
import {UpgradePayment} from './Upgrade-Payment'
import UpgradeThankYouPage from './Upgrade-Thank-You-Page'
import {ProcessTransactionDecorator} from '../common/Transaction/process-transaction-decorator'
import styles from './Upgrade.module.scss'
import {BackToPlanLink} from '../Plan-Link/BackToPlanLink'
import GenericErrorPage from '../Renew-V2/generic-error-page/generic-error-page'
import {logComponentLoad} from '../utils/log-component-load'
import Banner from '@pluralsight/ps-design-system-banner'
import DisplayPriceLockMessage from './Upgrade-PriceLock-Message'

const mobileWidth = 769

export default function UpgradeV2() {
    useEffect(() => {
        // Record a page view with New Relic for the "UpgradeV2" component load
        if (window.newrelic) {
            window.newrelic.setPageViewName('Upgrade')
            window.newrelic.addPageAction('PageLoad', {userFlow: 'UpgradeV2'})
            window.newrelic.setCustomAttribute('userFlow', 'B2B_Upgrade')
        }
    }, [])

    return (
        <UpgradeContextV2>
            <UpgradeProcessing />
        </UpgradeContextV2>
    )
}

function UpgradeStandardLayout() {
    let {state} = useUpgradeContext()

    useEffect(() => {
        logComponentLoad('UpgradeStandardLayout')
        logComponentLoad('UpgradeAccountSwitcher')
        logComponentLoad('UpgradeOrderSummary')
        logComponentLoad('UpgradeTermsAndConditions')
    }, [])

    return (
        <div className={styles.upgradeContainer}>
            <div className={styles.backToPlanMessage}>
                <BackToPlanLink planId={state.planId} />
            </div>
            <div className={styles.upgrade}>
                <main className={styles.mainLayout}>
                    <UpgradeMain />
                </main>
                <aside className={styles.aside}>
                    <div className={styles.accountSwitcher}>
                        <UpgradeAccountSwitcher />
                    </div>
                    <UpgradeOrderSummary />
                    {state.upgradePreview.isB2BLegacyUpgrade && state.upgradePreview.amount === 0 && (
                        <div style={{marginTop: '10px'}}>
                            <Banner>
                                {DisplayPriceLockMessage(
                                    state?.currentSubscription?.subscriptionEndDate
                                )}
                            </Banner>
                        </div>
                    )}
                    <UpgradeTermsAndConditions lowOnDark={true} />
                </aside>
            </div>
        </div>
    )
}

function UpgradeMobileLayout() {
    let {state} = useUpgradeContext()

    useEffect(() => {
        logComponentLoad('UpgradeMobileLayout')
        logComponentLoad('UpgradeAccountSwitcher')
        logComponentLoad('UpgradeOrderSummary')
        logComponentLoad('UpgradeTermsAndConditions')
    }, [])

    return (
        <div>
            <div>
                <BackToPlanLink planId={state.planId} />
            </div>
            <main>
                <div>
                    <UpgradeAccountSwitcher />
                </div>
                <div>
                    <UpgradeMainMobile />
                </div>
            </main>
            <UpgradeSummaryFooter />
        </div>
    )
}

function UpgradeMainLayout(props: {reviewConfirm: ReactNode}) {
    useEffect(() => {
        logComponentLoad('UpgradeMainLayout')
        logComponentLoad('UpgradePayment')
    }, [])

    return (
        <div>
            <h2 className={styles.header}>Upgrade</h2>
            <div className={styles.rows}>
                <UpgradePayment />
                {props.reviewConfirm}
            </div>
        </div>
    )
}

function UpgradeMain() {
    useEffect(() => {
        logComponentLoad('UpgradeMain')
    }, [])

    return <UpgradeMainLayout reviewConfirm={<UpgradeReviewConfirm />} />
}

function UpgradeMainMobile() {
    useEffect(() => {
        logComponentLoad('UpgradeMainMobile')
    }, [])

    return <UpgradeMainLayout reviewConfirm={<UpgradeReviewConfirmMobile />} />
}

function UpgradeStandardorMobile() {
    const [mobileLayout, setMobileLayout] = useState(false)
    useEffect(() => {
        if (window.innerWidth < mobileWidth) {
            setMobileLayout(true)
        }

        if (window.newrelic) {
            window.newrelic.addPageAction('LayoutDetermined', {
                layout: mobileLayout ? 'mobile' : 'standard',
            })
        }
    }, [mobileLayout])

    return (
        <UpgradeDataLoader>
            <div className={styles.pageContainer}>
                <PluralSightHeader />
                {mobileLayout ? <UpgradeMobileLayout /> : <UpgradeStandardLayout />}
            </div>
        </UpgradeDataLoader>
    )
}

function UpgradeProcessing() {
    let {state} = useUpgradeContext()
    if (state.hasError) {
        return (
            <BlockedUpgradeRoutes
                errorMessage={state.errorMessage}
                planId={state.planId}
                guardData={state.guardData}
            />
        )
    } else if (state.getActivityError) {
        return <GenericErrorPage />
    } else if (state.upgrade.pending) {
        return <ProcessTransactionDecorator />
    } else if (!state.upgrade.loaded) {
        return <UpgradeStandardorMobile />
    } else {
        return <UpgradeThankYouPage />
    }
}
