import React, {useState} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../Create-Subscription-Modal/DateTimePicker.css'
import Form from '@pluralsight/ps-design-system-form'
import TextArea from '@pluralsight/ps-design-system-textarea'
import Button from '@pluralsight/ps-design-system-button'
import {useAppContext} from '../useAppState'
import {Product} from '../../../definitions/ind-product-state'
import Dialog from '@pluralsight/ps-design-system-dialog'

export function ExtendSubscriptionModal(props: Props) {
    const [notes, setNotes] = useState('')
    const [expirationDate, setExpirationDate] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [expirationDateError, setExpirationDateError] = useState(false)
    const [notesError, setNotesError] = useState(false)
    const {setters, state} = useAppContext()
    const currentExpirationDate = props.products.map((p) => p.expiresAt).toString()
    const formattedCurrentExpirationDate = new Date(currentExpirationDate)
    const today = new Date()
    const maxDate = new Date(today.setMonth(today.getMonth() + 30))
    const minDate =
        formattedCurrentExpirationDate > today ? formattedCurrentExpirationDate : new Date()

    function formatDate(dateString: string) {
        const date = new Date(dateString)
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        }
        return date.toLocaleDateString('en-US', options)
    }

    function handleSelect(date: string) {
        setExpirationDate(date)
        setExpirationDateError(!date)
    }

    function handleChange(date: string) {
        setExpirationDate(date)
        setExpirationDateError(!date)
    }

    function handleNotesChange(notes: string) {
        setNotes(notes)
        setNotesError(!notes)
    }

    async function handleSubmit() {
        const formattedExpirationDate = formatDate(expirationDate)
        const expirationDateExists = !!formattedExpirationDate
        const notesExists = !!notes
        if (expirationDateExists && notesExists) {
            setIsSubmitting(true)
            try {
                const response = await props.extendSubscription(formattedExpirationDate, notes)
                if (!!response.status.success) {
                    setIsSubmitting(false)
                    props.handleToggleModal()
                    setters.setSupportReRendering(!state.reRenderSupportTooling)
                    setters.setSupportToolingError(null)
                }
            } catch (err) {
                setIsSubmitting(false)
                props.handleToggleModal()
                setters.setSupportToolingError(err as string)
            }
        } else {
            setExpirationDateError(!formattedExpirationDate)
            setNotesError(!notesExists)
        }
    }

    return (
        <Dialog modal onClose={props.handleToggleModal} aria-label={'extend subscription form'}>
            <div style={{margin: 20}}>
                <Form.VerticalLayout onSubmit={handleSubmit}>
                    <label>UserName: {props.userName}</label>
                    <div className='datepicker-title'>New expiration date</div>
                    <DatePicker
                        selected={expirationDate}
                        onSelect={handleSelect}
                        onChange={handleChange}
                        placeholderText='mm /dd /yyyy'
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        disabled={isSubmitting}
                        required={true}
                        error={expirationDateError}
                        minDate={minDate}
                        maxDate={maxDate}
                        openToDate={minDate}
                    />
                    <TextArea
                        style={{width: '100%'}}
                        label='Notes'
                        name='notes'
                        error={notesError}
                        subLabel={notesError ? 'Required' : ''}
                        value={notes}
                        disabled={isSubmitting}
                        onChange={(event: any) => handleNotesChange(event.target.value)}
                    />
                    <Button
                        loading={isSubmitting}
                        onClick={() => handleSubmit()}
                        disabled={!!expirationDate && !!notes ? false : true}
                    >
                        Extend Subscription
                    </Button>
                </Form.VerticalLayout>
            </div>
        </Dialog>
    )
}

interface Props {
    handleToggleModal: () => any
    extendSubscription: (expirationDate: string, notes: string) => any
    userName: string
    products: Product[]
}
