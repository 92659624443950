import React, {ReactNode, useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {ForbiddenErrorPage} from '@pluralsight/ps-design-system-errors'
import {api} from '../../api/api'
import {ApiContext} from '../../../../definitions/api'
import {ParamTypes} from '../../../../definitions/param-types'
import {CenteredLoadingDecorator} from '../../Loading-Decorator/Centered-Loading-Decorator'

export function TransactionAuth(props: {children: ReactNode}) {
    const {planId} = useParams() as ParamTypes
    const [showLoadingDecorator, setShowLoadingDecorator] = useState(true)
    const [hasViewAuth, setHasViewAuth] = useState(false)

    useEffect(checkTransactionAuth, [planId])

    function mainContent(): ReactNode {
        return hasViewAuth ? (
            <>{props.children}</>
        ) : (
            <ForbiddenErrorPage size={ForbiddenErrorPage.sizes.large} />
        )
    }

    function checkTransactionAuth() {
        api.get(`plans/${planId}/transaction-auth`).then((res: ApiContext<boolean>) => {
            if (res.status.success) {
                const isAuthorized = res.data
                setHasViewAuth(isAuthorized)
            } else {
                throw new Error(res.status.errorMessage)
            }
            setShowLoadingDecorator(false)
        })
    }

    return (
        <>
            {showLoadingDecorator ? (
                <CenteredLoadingDecorator centerVertically={true} />
            ) : (
                mainContent()
            )}
        </>
    )
}
