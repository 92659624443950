import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {ReactComponent as SkillsLogoDark} from '../images/skills-logo.svg'
import {ReactComponent as AcgLogoDark} from '../images/acg-logo.svg'
import {ReactComponent as FlowLogoDark} from '../images/flow-logo.svg'
import {ReactComponent as ProfessionalServicesLogoDark} from '../images/proserv-logo.svg'
import Banner from '@pluralsight/ps-design-system-banner'
import {useAppContext} from '../useAppState'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import {config} from '../environment-config'
import Accounts from '../Account-Plans/accounts'
import AccSwitcher from '../Account-Switcher/acc-switcher'
// import DisplayFutureState from './Display-Future-State/display-future-state'
import {ProductV2} from '../../../definitions/product-state'
import {ParamTypes} from '../../../definitions/param-types'
import {isAddon} from '../utils/is-addon'
import {isFutureDate} from '../utils/date-utils/is-future-date'
import {isPastDate} from '../utils/date-utils/is-past-date'
import styles from './admin-plan.module.scss'
import ProductDetailsV2 from './admin-sections/Product-DetailsV2'
import AccountTermsV2 from './admin-sections/Account-TermsV2'
import {
    AI_PLUS_DATA_SKU,
    ENTERPRISE_SKU,
    EVERYTHING_NEW_SKU,
    EVERYTHING_SKU,
    PROFESSIONAL_SKU,
    SEC_PLUS_CLOUD_SKU,
    STARTER_SKU,
} from '../common/constants'
import Wallet from '../Wallet/Wallet'
import {BUSINESS} from '../common/enums'
import {SalesforceSubscription} from '../../../definitions/v2/salesforce-subscription-data'

export function AdminPlanV2() {
    const {state, actions, setters} = useAppContext()
    let {planId} = useParams() as ParamTypes
    const [productStartDate, setProductStartDate] = useState('')
    const [productExpirationDate, setProductExpirationDate] = useState('')
    const userHandle = state.applicationData.user.handle
    const showModifyExp = state.applicationData.featureConfig.modifyFeatureFlag
    const disableTransactionLinks = state.applicationData.featureConfig.disableTransactionLinks
    const showNewProducts = state.applicationData.featureConfig.acgiIntegrationActive
    const showUpgradeLink = state.applicationData.featureConfig.upgradeFeatureFlag
    const enableWalletFeature = state.applicationData.featureConfig.enableWalletFeature

    useEffect(() => {
        if (!!planId) {
            actions.loadPlanDisplayName([planId])
        }
    }, [planId, actions])

    useEffect(() => {
        if (!!planId) {
            actions.loadBusinessAccount(planId)
        }
    }, [planId, actions])

    useEffect(() => {
        if (!!planId) {
            actions.loadSubscription(planId)
        }
    }, [planId, actions])

    useEffect(() => {
        if (!!planId) {
            actions.loadInvoicesV2(planId)
        }
    }, [planId, actions])

    useEffect(() => {
        const accountWrapper = state.businessAccountMap[planId]
        const accountLoaded = accountWrapper && accountWrapper.loaded
        if (
            accountLoaded &&
            planId &&
            !!state.planDisplayNames[planId] &&
            !state.planDisplayNames[planId].pending
        ) {
            const account = accountWrapper.data
            if (account.productState.products && account.productState.products.length > 0) {
                const earliestStartDate = account.productState.products[0].productInfo.startsAt
                const mostRecentProduct = account.productState.products.reduce(
                    (prev: any, cur: any) => {
                        if (!prev.productInfo || !prev.productInfo.expiresAt) {
                            prev = cur
                        } else if (!!prev.productInfo && !!prev.productInfo.expiresAt) {
                            if (
                                new Date(cur.productInfo.expiresAt) >
                                new Date(prev.productInfo.expiresAt)
                            ) {
                                prev = cur
                            }
                        }
                        return prev
                    },
                    {}
                )
                const lastExpirationDate = mostRecentProduct.productInfo.expiresAt
                setProductStartDate(earliestStartDate)
                setProductExpirationDate(lastExpirationDate)
            }
            setShowLoadingDecorator(false)
        }
    }, [planId, state.businessAccountMap, state.planDisplayNames])

    let [showLoadingDecorator, setShowLoadingDecorator] = useState(true)

    function getRenewedAndUpgradedSubId(subscriptions: SalesforceSubscription[]): any {
        if (!subscriptions || subscriptions?.length === 0) {
            return null
        }
        // Find the maximum expiration date
        const maxExpirationDate = subscriptions?.reduce((max: any, sub: any) => {
            return new Date(max.subscriptionEndDate) > new Date(sub.subscriptionEndDate) ? max : sub
        })?.subscriptionEndDate
        // Iterate through subscriptions to find the matching subscription ID
        const renewedAndUpgradedSubId = subscriptions?.find(
            (s: any) =>
                s.productSKU === EVERYTHING_NEW_SKU &&
                new Date(maxExpirationDate) <= new Date(s.subscriptionEndDate)
        )?.subscriptionId

        return renewedAndUpgradedSubId
    }

    function mainContent() {
        const accountWrapper = state.businessAccountMap[planId]
        const sortedSubscriptions = accountWrapper.data?.subscriptionState.subscriptions.sort(
            (a, b) => {
                const timeA = new Date(a.termEndDate).getTime()
                const timeB = new Date(b.termEndDate).getTime()
                return timeB - timeA
            }
        )
        const currency = accountWrapper.data?.billingInfo?.currency

        const isMultiYear = sortedSubscriptions[0]?.term > 12
        const hasAddOns =
            accountWrapper.data.productState.products.findIndex((p) =>
                isAddon(p.productCatalogInfo.product.category)
            ) >= 0
        const isCPQ = sortedSubscriptions[0]?.isCPQ
        const products =
            accountWrapper && accountWrapper.loaded ? accountWrapper.data.productState.products : []
        const hasSubscription = products.length > 0 && products.some((p) => p.productInfo.inTerm)
        const currentTime = Date.now()
        const currentOrFutureProducts = products.filter(isCurrentOrFutureProduct)
        // Name required in B2B Renewal Modal
        const currentProductName = currentOrFutureProducts[0]?.productCatalogInfo.product.name
        const hasProducts = currentOrFutureProducts.length > 0
        const displayName = !!planId ? state.planDisplayNames[planId].displayName : ''
        const inTermProducts = products.filter((p) => p.productInfo.inTerm)
        const customerSource = inTermProducts[0]?.productCatalogInfo?.productOption?.customerSource
        const subData = !!planId ? state.subscriptionData[planId]?.data?.subscriptions : null
        const [firstSubData] = subData || []
        const zuoraId: string | null = firstSubData?.zuoraId || null
        let subscriptionId: string = ''
        // the original subscription without any ammendments which needs to be amended/renewed
        let originalSubscriptionId: string = ''
        let subscription = inTermProducts.filter(
            (data) =>
                data.productCatalogInfo.product.category === 'Base Products' &&
                data.productCatalogInfo.product.type === 'Skills'
        )

        const sfdcId = subscription[0]?.productCatalogInfo?.productOption?.sfdcProductId
        let renewedToUpgradedSubscriptionId = null
        if (
            state.subscriptionData[planId] &&
            state.subscriptionData[planId].loaded &&
            state.subscriptionData[planId].data.subscriptions.length > 0
        ) {
            const sub = state.subscriptionData[planId].data.subscriptions.filter(
                (subscription) => subscription.productSKU === sfdcId
            )
            // the current interm original subscription which needs to be renewed
            const originalSubscription = sub.filter(
                (s) => new Date() < new Date(s.subscriptionEndDate)
            )[0]

            originalSubscriptionId = originalSubscription?.subscriptionId || sub[0]?.subscriptionId

            // Use latest subscription based on subscriptionStartDate
            sub.sort((a, b) => (a.subscriptionStartDate > b.subscriptionStartDate ? -1 : 1))

            if ('LABS' === sub[0]?.productSKU) {
                let sfdcId = subscription[0]?.productCatalogInfo?.productOption?.sfdcProductId
                const sub = state.subscriptionData[planId].data.subscriptions.filter(
                    (subscription) => subscription.productSKU === sfdcId
                )
                subscriptionId = sub[0]?.subscriptionId
            } else if (sub.length === 0) {
                subscriptionId = ''
            } else {
                subscriptionId = sub[0]?.subscriptionId
            }
            renewedToUpgradedSubscriptionId = getRenewedAndUpgradedSubId(
                state.subscriptionData[planId].data.subscriptions
            )
        }
        const isPsOneProduct =
            subscription[0] && subscription[0].productCatalogInfo
                ? subscription[0].productCatalogInfo.product.sku.includes('PSONE')
                : false
        let subscriptionQuantity = subscription[0]?.productInfo.quantity
        const unpaidInvoiceIds = state.invoiceData[planId]?.data?.unpaidInvoices.map(
            (invoice) => invoice.id
        )
        const hasUnpaidInvoices = !!unpaidInvoiceIds && unpaidInvoiceIds.length > 0
        const dismissCPQBanner = () => {
            setters.setSubscribeBanner({
                showBanner: false,
                product: '',
                message: '',
            })
        }
        const isB2B =
            subscription[0] &&
            subscription[0]?.productCatalogInfo?.product?.customerType === BUSINESS
                ? true
                : false
        const isAiPlusDataOrSecPlusCloud =
            sfdcId === AI_PLUS_DATA_SKU || sfdcId === SEC_PLUS_CLOUD_SKU

        return (
            <div className={styles.adminContainer}>
                {/* <NotifyBanner
                    disableTransactionLinks={disableTransactionLinks}
                    learningBanner={true}
                /> */}
                <AccountTermsV2
                    subscriptionQuantity={subscriptionQuantity}
                    hasSubscription={hasSubscription}
                    displayName={displayName}
                    originalSubscriptionId={originalSubscriptionId}
                    subscription={sortedSubscriptions[0]}
                    planId={planId}
                    productStartDate={productStartDate}
                    productExpirationDate={productExpirationDate}
                    customerSource={customerSource}
                    billingUrl={config.billing.url}
                    unpaidInvoiceIds={unpaidInvoiceIds}
                    subscriptionId={subscriptionId}
                    disableTransactionLinks={disableTransactionLinks}
                    isPsOneProduct={isPsOneProduct}
                    currentProductName={currentProductName}
                    isMultiYear={isMultiYear}
                    hasAddOns={hasAddOns}
                    isAiPlusDataOrSecPlusCloud={isAiPlusDataOrSecPlusCloud}
                />
                {state.subscribeBanner.showBanner && (
                    <Banner color={Banner.colors.green} onClick={dismissCPQBanner}>
                        {state.subscribeBanner.message}
                    </Banner>
                )}
                {hasProducts && (
                    <Products
                        planId={planId}
                        products={currentOrFutureProducts}
                        currentTime={currentTime}
                        showUpgradeLink={showUpgradeLink}
                        showNewProducts={showNewProducts}
                        showModifyExp={showModifyExp}
                        hasUnpaidInvoices={hasUnpaidInvoices}
                        isMultiYear={isMultiYear}
                        isCPQ={isCPQ}
                        subscriptionId={subscriptionId}
                        disableTransactionLinks={disableTransactionLinks}
                        enableWalletFeature={enableWalletFeature}
                        isB2B={isB2B}
                        zuoraId={zuoraId}
                        renewedToUpgradedSubscriptionId={renewedToUpgradedSubscriptionId}
                    />
                )}
                <Accounts />
                {isB2B &&
                    zuoraId &&
                    enableWalletFeature &&
                    (state.walletAmount === null || state.walletAmount > 0) && (
                        <Wallet
                            amount={state.walletAmount}
                            error={state.walletError}
                            isB2B={isB2B}
                            currency={currency}
                            zuoraId={zuoraId}
                        />
                    )}
                <AccSwitcher
                    plans={state.applicationData.planInfo.admin}
                    currentPlanId={planId}
                    userHandle={userHandle}
                    path={`${config.subscriptionLifecycle.browserPath}/plans`}
                />
            </div>
        )
    }

    return (
        <>
            {showLoadingDecorator ? (
                <>
                    <CenteredLoadingDecorator />
                </>
            ) : (
                mainContent()
            )}
        </>
    )
}

function Products(props: {
    products: ProductV2[]
    planId: string
    currentTime: number
    showUpgradeLink: boolean
    showModifyExp: boolean
    hasUnpaidInvoices: boolean
    isMultiYear: boolean
    isCPQ: boolean
    subscriptionId: string
    disableTransactionLinks: boolean
    showNewProducts: boolean
    enableWalletFeature: boolean
    isB2B: boolean
    zuoraId: string | null
    renewedToUpgradedSubscriptionId: string | null
}) {
    const {state, setters, actions} = useAppContext()
    const modifiedProducts: ProductV2[] = []
    const noModifiedProducts: any = []

    const currentInfo = state.subscriptionData[props.planId]
    if (currentInfo && currentInfo.loaded && currentInfo.data.subscriptions.length > 0) {
        const subscriptionData = currentInfo.data.subscriptions.map((subscription) => {
            return {
                subscriptionId: subscription.subscriptionId,
                productSKU: subscription.productSKU,
            }
        })
        for (const product of props.products) {
            const subscription = subscriptionData.filter(
                (sub) =>
                    sub.productSKU ===
                    (product.productCatalogInfo.productOption.sfdcProductId &&
                        product.productInfo.inTerm)
            )
            if (subscription.length > 0) {
                const newProduct = {...product, subscriptionId: subscription[0].subscriptionId}
                modifiedProducts.push(newProduct)
            } else {
                noModifiedProducts.push('Data')
            }
        }
    }

    useEffect(() => {
        if (!state.subscriptionHardRefreshDone && modifiedProducts.length === 0) {
            setters.setSubscriptionHardRefreshDone(true)
            actions.loadSubscription(props.planId, true)
        }
    }, [
        actions,
        modifiedProducts.length,
        noModifiedProducts.length,
        props.planId,
        setters,
        state.subscriptionHardRefreshDone,
    ])

    const products = groupAndCollapse(
        modifiedProducts.length > 0 ? modifiedProducts : (props.products as any)
    )

    return (
        <>
            {products.map((p) =>
                product(
                    p,
                    props.planId,
                    props.showUpgradeLink,
                    props.showModifyExp,
                    props.hasUnpaidInvoices,
                    props.isMultiYear,
                    props.isCPQ,
                    props.subscriptionId,
                    props.disableTransactionLinks,
                    state.applicationData.featureConfig.acgiIntegrationActive,
                    state.applicationData.featureConfig.allowNewB2BUpgrade,
                    state.applicationData.featureConfig.enableWalletFeature,
                    props.renewedToUpgradedSubscriptionId
                )
            )}
        </>
    )
}

function sortHandler(type: string) {
    switch (type) {
        case 'Skills':
            return 1
        case 'ACG':
            return 2
        case 'ProServ':
            return 3
        case 'Flow':
            return 4
        default:
            return 0
    }
}

function groupAndCollapse(products: ProductV2[]) {
    const productMap = new Map<string, ProductGroup>()
    products.forEach((p) => {
        const type = p.productCatalogInfo.product.type
        if (productMap.has(type)) {
            // @ts-ignore
            productMap.get(type).products.push(p)
        } else {
            productMap.set(type, {type: type, products: [p], index: sortHandler(type)})
        }
    })
    const groupedProducts = Array.from(productMap.values())
    groupedProducts.sort((a: any, b: any) => (a.index > b.index ? 1 : -1))
    const groupedAndCollapsed = groupedProducts.map((gp) => {
        const type = gp.type
        const list = gp.products
        return {type, products: list}
    })
    return groupedAndCollapsed
}

interface ProductGroup {
    type: string
    products: ProductV2[]
    index?: number
}

function product(
    productGroup: ProductGroup,
    planId: string,
    showUpgradeLink: boolean,
    showModifyExp: boolean,
    hasUnpaidInvoices: boolean,
    isMultiYear: boolean,
    isCPQ: boolean,
    subscriptionId: string,
    disableTransactionLinks: boolean,
    showNewProducts: boolean,
    allowNewPricingAndPackagingB2BUpgrade: boolean,
    enableWalletFeature: boolean,
    renewedToUpgradedSubscriptionId: string | null
) {
    const starterProducts = productGroup.products.filter(
        (p) => !isAddon(p.productCatalogInfo.product.category)
    )
    let currentOrFutureAddons = productGroup.products.filter(
        (p) => isAddon(p.productCatalogInfo.product.category) && isCurrentOrFutureProduct(p)
    )
    currentOrFutureAddons =
        config.hideFutureAddOns15days === 'true'
            ? hideFutureAddOns(currentOrFutureAddons)
            : currentOrFutureAddons
    const hasSpokes = currentOrFutureAddons.length > 0 && starterProducts.length > 0
    // const futureProducts = starterProducts.filter((p) => isFutureDate(p.productInfo.startsAt))
    const currentProduct =
        starterProducts.filter((p) => p.productInfo.inTerm)[0] ||
        starterProducts[0] ||
        currentOrFutureAddons[0]
    const productIcon = getProductIcon(productGroup.type)
    const assignSubsLink = getAssignSubsLink(productGroup.type, planId)
    // const hasFutureState = futureProducts.length > 0
    const customerSource = currentProduct.productCatalogInfo.productOption.customerSource
    const isPSProduct = customerSource === 'Pluralsight'
    const inTermStarterProduct =
        !!starterProducts && starterProducts.length > 0
            ? starterProducts.filter((p) => p.productInfo.inTerm)
            : null
    const upsellProductOptions = !!inTermStarterProduct
        ? starterProducts[0].productCatalogInfo.upsellProductOptions
        : []

    let isUpgradableSku
    if (showNewProducts) {
        isUpgradableSku =
            currentProduct.productCatalogInfo.productOption.sfdcProductId === STARTER_SKU ||
            currentProduct.productCatalogInfo.productOption.sfdcProductId === PROFESSIONAL_SKU ||
            currentProduct.productCatalogInfo.productOption.sfdcProductId === ENTERPRISE_SKU ||
            (allowNewPricingAndPackagingB2BUpgrade &&
                currentProduct.productCatalogInfo.productOption.sfdcProductId ===
                    AI_PLUS_DATA_SKU) ||
            (allowNewPricingAndPackagingB2BUpgrade &&
                currentProduct.productCatalogInfo.productOption.sfdcProductId ===
                    SEC_PLUS_CLOUD_SKU) ||
            (enableWalletFeature &&
                currentProduct.productCatalogInfo.productOption.sfdcProductId === EVERYTHING_SKU)
    } else {
        isUpgradableSku =
            currentProduct.productCatalogInfo.productOption.sfdcProductId === STARTER_SKU ||
            currentProduct.productCatalogInfo.productOption.sfdcProductId === PROFESSIONAL_SKU ||
            currentProduct.productCatalogInfo.productOption.sfdcProductId === ENTERPRISE_SKU ||
            (allowNewPricingAndPackagingB2BUpgrade &&
                currentProduct.productCatalogInfo.productOption.sfdcProductId ===
                    AI_PLUS_DATA_SKU) ||
            (allowNewPricingAndPackagingB2BUpgrade &&
                currentProduct.productCatalogInfo.productOption.sfdcProductId ===
                    SEC_PLUS_CLOUD_SKU) ||
            (enableWalletFeature &&
                currentProduct.productCatalogInfo.productOption.sfdcProductId === EVERYTHING_SKU)
    }
    const checkUpgradeability = showUpgradeLink && isUpgradableSku

    return (
        <div className={`${styles[`${productGroup.type}-border`]}`} key={productGroup.type}>
            <div className={styles.productTypeLabel}>
                {!!productIcon ? productIcon : productGroup.type}
            </div>
            {/* {hasFutureState &&
                futureProducts.map((product: ProductV2, index: number, array: ProductV2[]) => {
                    const previousProduct = index === 0 ? currentProduct : array[index - 1]
                    return (
                        // <div className={styles.lineItemContainer} key={index}>
                        //     <DisplayFutureState
                        //         product={product}
                        //         previousProduct={previousProduct}
                        //         planID={planId}
                        //     />
                        // </div>
                    )
                })} */}
            <ProductDetailsV2
                product={{baseProduct: currentProduct, currentOrFutureAddons, hasSpokes}}
                planId={planId}
                assignSubsLink={assignSubsLink}
                isPSProduct={isPSProduct}
                upsellProductOptions={upsellProductOptions}
                showUpgradeLink={checkUpgradeability}
                showModifyExp={showModifyExp}
                hasUnpaidInvoices={hasUnpaidInvoices}
                isMultiYear={isMultiYear}
                isCPQ={isCPQ}
                subscriptionId={subscriptionId}
                disableTransactionLinks={disableTransactionLinks}
                renewedToUpgradedSubscriptionId={renewedToUpgradedSubscriptionId}
            />
        </div>
    )
}

function getAssignSubsLink(productType: string, planId: string) {
    switch (productType) {
        case 'Skills':
            return `/plans/${planId}/people/org?acta=b2b_uue_click_assign_sub`
        case 'Flow':
            return null //TODO add when we know where to link!
        default:
            return null
    }
}

function getProductIcon(type: string) {
    switch (type) {
        case 'Skills':
            return <SkillsLogoDark />
        case 'ACG':
            return <AcgLogoDark className={styles.acgResize} />
        case 'Flow':
            return <FlowLogoDark />
        case 'ProServ':
            return <ProfessionalServicesLogoDark className={styles.proServeResize} />
        default:
            return null
    }
}

function isCurrentOrFutureProduct(p: ProductV2) {
    return (
        isFutureDate(p.productInfo.startsAt) ||
        (isPastDate(p.productInfo.startsAt) && isFutureDate(p.productInfo.expiresAt))
    )
}

// hide add-ons whose activation date is more than 15 days from today
const hideFutureAddOns = (productList: ProductV2[]): ProductV2[] => {
    productList = productList.filter((p) => {
        const date1 = new Date(p.productInfo.startsAt)
        const differenceInMilliseconds = date1.getTime() - Date.now()
        const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24))
        return differenceInDays <= Number(config.hideFutureAddOnsBeyond)
    })
    return productList
}
