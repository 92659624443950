import Form from '@pluralsight/ps-design-system-form'
import * as Text from '@pluralsight/ps-design-system-text'
import TextArea from '@pluralsight/ps-design-system-textarea'
import Button from '@pluralsight/ps-design-system-button'
import * as React from 'react'
import {useEffect, useState} from 'react'
import Dropdown from '@pluralsight/ps-design-system-dropdown'
import {useAppContext} from '../useAppState'
import {useNavigate, useParams} from 'react-router-dom'
import {ParamTypes} from '../../../definitions/param-types'
import Checkbox from '@pluralsight/ps-design-system-checkbox'
import {api} from '../api/api'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import TextInput from '@pluralsight/ps-design-system-textinput'
import {useAsyncError} from '../useAsyncError'
import styles from './Contact-Sales-Form.module.scss'
import {config} from '../environment-config'

export default function ContactSalesForm(props: {requestType: string}) {
    const bannerMessage = `Thank you for submitting a ${props.requestType} request. Your account representative will reach out to you soon.`

    let {actions, state, setters} = useAppContext()
    let headingText =
        props.requestType === 'upsell'
            ? 'Request Cloud Labs for '
            : `Request to  ${props.requestType}`
    const activateErrorBoundary = useAsyncError()
    const navigate = useNavigate()
    const {planId} = useParams() as ParamTypes
    const subscriptionsToAddOptions = [
        'None',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10 or more',
    ]
    const [subscriptionsToAdd, setSubscriptionsToAdd] = React.useState()
    const [showLoadingDecorator, setShowLoadingDecorator] = React.useState(false)

    const contactMethodOptions = ['Email', 'Phone call']
    const [contactMethod, setContactMethod] = React.useState()

    const [contactInfo, setContactInfo] = React.useState()

    const [displayName, setPlanDisplayName] = useState('' as any)
    const [charLimit, setCharLimit] = useState(250)
    const [notes, setNotes] = useState('')
    const [error, setError] = useState(false)

    const [wantsFlowInformation, setWantsFlowInformation] = useState(false)

    const handleCancel = (e: any) => {
        let cancelUrl = `${config.subscriptionLifecycle.browserPath}/plans/${planId}`
        window.location.assign(cancelUrl)
    }

    let currentSubscriptionCount
    const accountWrapper = state.businessAccountMap[planId]
    if (accountWrapper && accountWrapper.loaded) {
        let product = state.businessAccountMap[planId].data.productState.products.filter(
            (product) => product.productInfo.inTerm
        )[0]
        const productQuantity = product?.productInfo?.quantity
        if (productQuantity) {
            currentSubscriptionCount = `Current subscription count: ${productQuantity}`
        }
    }

    useEffect(() => {
        if (!!planId) {
            actions.loadPlanDisplayName([planId])
        }
    }, [planId, actions])

    useEffect(() => {
        if (!!planId) {
            actions.loadBusinessAccount(planId)
        }
    }, [planId, actions])

    useEffect(() => {
        if (planId in state.planDisplayNames && !state.planDisplayNames[planId].pending) {
            setPlanDisplayName(state.planDisplayNames[planId].displayName)
        }
    }, [state.planDisplayNames, planId])

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (!contactInfo) {
            setError(true)
            return
        }
        setShowLoadingDecorator(true)
        const body = {
            requestType: props.requestType,
            subscriptionsToAdd,
            contactMethod,
            contactInfo,
            notes,
            wantsFlowInformation,
            planDisplayName: displayName,
            user: state.applicationData.user,
        }
        return api
            .post(`plans/${planId}/${props.requestType}-contact-sales`, body)
            .then((res) => {
                if (res.status.success) {
                    setters.setGreenProductBanner({
                        showBanner: true,
                        message: bannerMessage,
                    })
                    navigate(`${config.subscriptionLifecycle.browserPath}/plans/${planId}`)
                } else {
                    activateErrorBoundary(new Error(res.status.errorMessage))
                }
            })
            .catch((error) => activateErrorBoundary(error))
    }

    return showLoadingDecorator ? (
        <CenteredLoadingDecorator />
    ) : (
        <div className={styles.formLayout}>
            <Form.VerticalLayout>
                <Text.Heading>
                    <h2>
                        {headingText} <span style={{fontWeight: 'bold'}}>{displayName}</span>
                    </h2>
                </Text.Heading>
                {props.requestType !== 'upsell' && (
                    <Dropdown
                        label='Would you like to add any subscriptions to your plan?'
                        onChange={(evt: any, value?: any, label?: any) =>
                            setSubscriptionsToAdd(value)
                        }
                        subLabel={currentSubscriptionCount}
                        placeholder={'Select'}
                        menu={subscriptionsToAddOptions.map((opt) => (
                            <Dropdown.Item key={opt} value={opt}>
                                {opt}
                            </Dropdown.Item>
                        ))}
                        value={subscriptionsToAdd}
                    />
                )}
                {props.requestType === 'upsell' && (
                    <TextArea
                        label='Is there anything else you need for your plan? (optional)'
                        name='notes'
                        maxLength={250}
                        subLabel={`${charLimit} characters left`}
                        onChange={(e: any) => {
                            setNotes(e.target.value)
                            setCharLimit(250 - e.target.value.length)
                        }}
                    />
                )}
                <Dropdown
                    label='What is the best way to contact you?'
                    onChange={(evt: any, value?: any, label?: any) => setContactMethod(value)}
                    placeholder={'Select'}
                    menu={contactMethodOptions.map((opt) => (
                        <Dropdown.Item key={opt} value={opt}>
                            {opt}
                        </Dropdown.Item>
                    ))}
                    value={subscriptionsToAdd}
                />
                {!!contactMethod && (
                    <TextInput
                        onChange={(evt: any) => {
                            setError(false)
                            setContactInfo(evt.target.value)
                        }}
                        value={contactInfo}
                        error={error ? true : false}
                        label={contactMethod === 'Email' ? 'Email address' : 'Phone number'}
                        name='contact information'
                        subLabel={error && 'Field is required'}
                    />
                )}
                {props.requestType !== 'upsell' && (
                    <TextArea
                        label='Notes (optional)'
                        name='notes'
                        maxLength={250}
                        subLabel={`${charLimit} characters left`}
                        onChange={(e: any) => {
                            setNotes(e.target.value)
                            setCharLimit(250 - e.target.value.length)
                        }}
                    />
                )}
                {props.requestType !== 'upsell' && (
                    <Checkbox
                        label='I’m also interested in learning about Flow'
                        value='flow'
                        checked={wantsFlowInformation}
                        onCheck={(evt: any, checked: any, value: any, name: any) => {
                            setWantsFlowInformation(checked)
                        }}
                    />
                )}
                <Form.ButtonRow>
                    <Button disabled={!subscriptionsToAdd && !contactMethod} onClick={handleSubmit}>
                        Submit request
                    </Button>
                    <Button appearance={Button.appearances.secondary} onClick={handleCancel}>
                        Cancel
                    </Button>
                </Form.ButtonRow>
            </Form.VerticalLayout>
        </div>
    )
}
