export function formatDate(dateString: string) {
    const date = new Date(dateString)
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]
    return monthNames[date.getUTCMonth()] + ' ' + date.getUTCDate() + ', ' + date.getUTCFullYear()
}

export function shortFormatDate(dateString: string) {
    const date = new Date(dateString)
    const shortMonthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]
    return (
        shortMonthNames[date.getUTCMonth()] + ' ' + date.getUTCDate() + ', ' + date.getUTCFullYear()
    )
}

export function formattedDate(dateString: string) {
    const date = new Date(dateString)
    const day = date.getUTCDate()
    const month = date.getUTCMonth() + 1
    const year = date
        .getUTCFullYear()
        .toString()
        .slice(-2)
    return `${day}/${month}/${year}`
}
