import * as React from 'react'
import GenericTransactionError from './generic-transaction-error'
interface state {
    hasError: boolean
    error?: any
}

export default class TransactionErrorBoundary extends React.Component<any, state> {
    constructor(props: any) {
        super(props)
        this.state = {hasError: false}
    }

    static getDerivedStateFromError(error: any) {
        return {hasError: true}
    }

    render() {
        if (this.state.hasError) {
            return <GenericTransactionError />
        }
        return <>{this.props.children}</>
    }
}
