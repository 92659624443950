import {AI_PLUS_DATA_SKU, SEC_PLUS_CLOUD_SKU} from '../constants'

export function isNewAndUpgradableSKU(sku: string): boolean {
    return sku === AI_PLUS_DATA_SKU || sku === SEC_PLUS_CLOUD_SKU
}

export function getLineItemIndexOfRenewingToSku(currentSku: string): 0 | 1 {
    const urlParams = new URLSearchParams(window.location.search)
    const isUpgradeWithRenewal = urlParams.get('shouldUpgrade') === 'true'
    return isNewAndUpgradableSKU(currentSku) && isUpgradeWithRenewal ? 1 : 0
}
