import React from 'react'
import {useNavigate} from 'react-router-dom'
import Table from '@pluralsight/ps-design-system-table'
import Button from '@pluralsight/ps-design-system-button'

import './promotion-configurations.scss'
import {usePromotionConfigurationState} from './promotion-configuration-context'
import {PromosHeader, PromoRow} from './promotion-table-components'

export function PromotionConfigurations() {
    const navigate = useNavigate()
    const promotionConfigurationState = usePromotionConfigurationState()
    const configurations = promotionConfigurationState.state.configurations
    return (
        <div>
            <div className='create-new'>
                <Button onClick={() => navigateToEditor('new')}>Create new</Button>
            </div>
            <Table>
                <PromosHeader />
                <Table.Body>
                    {configurations.length > 0 ? <Configurations /> : <NoData />}
                </Table.Body>
            </Table>
        </div>
    )

    function Configurations() {
        return (
            <>
                {configurations.map((p) => (
                    <PromoRow
                        key={p.id}
                        className='promo-row'
                        promotion={p}
                        onClick={() => navigateToEditor(p.id)}
                    />
                ))}
            </>
        )
    }
    function NoData() {
        return (
            <Table.Row>
                <Table.Cell
                    className='no-data'
                    style={{
                        marginTop: '25px',
                        fontSize: '20px',
                    }}
                >
                    No Data.
                </Table.Cell>
            </Table.Row>
        )
    }

    function navigateToEditor(id: number | string) {
        navigate(`promotion-config/${id}`)
    }
}
