import React, {ReactNode} from 'react'
import LogoHeader from '../../Renew-V2/Logo-Header/logo-header'
import TransactionAccountSwitcher from './transacttion-account-switcher'
import styles from './styles/transaction-main-page.module.scss'
import {BackToPlanLink} from '../../Plan-Link/BackToPlanLink'
import {useModifyState} from '../../Modify_v2/Modify-Context/modify-context'
export default function TransactionMainPage(props: {
    paymentBox: ReactNode
    reviewAndConfirm: ReactNode
    conditions: ReactNode
    orderSummary: ReactNode
    title: string
    resetState: () => any
}) {
    const {state} = useModifyState()

    return (
        <div className={styles.transactionMainPage}>
            <LogoHeader />
            <div className={styles.mainContent}>
                <div className={styles.backLink}>
                    <BackToPlanLink planId={state.modifyGuard.planId} />
                </div>
                <TransactionAccountSwitcher title={props.title} resetState={props.resetState} />
                <div className={styles.rowLayout}>
                    <div className={styles.cell1}>
                        {props.paymentBox}
                        <br />
                        {props.reviewAndConfirm}
                    </div>
                    <div className={styles.cell2}>
                        {props.orderSummary}
                        {props.conditions}
                    </div>
                </div>
            </div>
        </div>
    )
}
