import * as React from 'react'
import {DisplayNameMap, useAppContext} from '../../useAppState'
import {useState, useEffect} from 'react'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../../definitions/param-types'
import {CenteredLoadingDecorator} from '../../Loading-Decorator/Centered-Loading-Decorator'
import styles from './styles/transaction-main-page.module.scss'

export default function TransactionAccountSwitcher(props: {title: string; resetState: () => any}) {
    const {planId} = useParams() as ParamTypes
    const {state, actions} = useAppContext()
    const planIds = state.applicationData.planInfo.admin
    const [displayName, setDisplayName] = useState({} as DisplayNameMap)
    const [hasDisplayName, setHasDisplayName] = useState(false)
    useEffect(() => {
        async function getDisplayNames() {
            await actions.loadPlanDisplayName(planIds)
            setDisplayName(state.planDisplayNames)
            setHasDisplayName(true)
        }
        getDisplayNames()
    }, [state.planDisplayNames, actions, planIds])

    function SingleAccountDisplay(props: {planId: string}) {
        return (
            <div>
                Account: <span> {displayName[planId] && displayName[planId].displayName}</span>
            </div>
        )
    }
    return (
        <div className={styles.rowLayout}>
            <h2 className={styles.cell1}> {props.title}</h2>
            <>
                {!hasDisplayName ? (
                    <CenteredLoadingDecorator />
                ) : (
                    <div className={styles.cell2}>
                        <SingleAccountDisplay planId={planId as string} />
                        {/* <SubLifeAccountSwitcher
                            planIds={planIds}
                            currentPlanId={planId}
                            displayName={displayName}
                            clearData={props.resetState}
                        /> */}
                    </div>
                )}
            </>
        </div>
    )
}
