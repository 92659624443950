import * as React from 'react'
import {createContext, useContext, useReducer} from 'react'
import {PayPalId, SavedPaymentMethods} from '../../../../definitions/payment-methods'
import {
    CalculateTaxPayload,
    Renew,
    RenewActivity,
    RenewErrorMessage,
    RenewGuardResponse,
    RenewPayloadV2,
    RenewResponse,
} from '../../../../definitions/renew'
import {api} from '../../api/api'
import {createPaypalMethod} from '../../Billing/Saved-Payment-Methods/Payments-Data/create-paypal-method/create-paypal-method'
import {useAppContext} from '../../useAppState'
import RenewErrorBoundary from '../renew-error-boundary'
import {RenewSetters} from '../renew-setters'

const RenewContext = createContext({})

export default function RenewStateProviderV2(props: any) {
    const {state} = useAppContext()

    const initialState: RenewState = {
        renew: {
            data: {} as RenewResponse,
            isInitialized: false,
        },
        renewActivity: {
            data: {} as RenewActivity,
            isInitialized: false,
        },
        payment: {
            paymentMethodId: null,
            savedPayments: [],
            paymentMethodsInitialized: false,
        },
        newPayment: {
            paymentMethodId: null,
            paymentMethodType: '',
        },
        invoiced: false,
        processingRenew: false,
        renewGuard: {
            canRenew: null,
            reason: '' as RenewErrorMessage,
            loadingGuard: true,
            data: null,
        },
        result: {
            processed: false,
            data: {} as RenewResponse,
        },
        tax: {
            data: {
                tax: 0,
            } as any,
        },
        subscriptionId: {} as string,
        subscription: {} as string,
        renewError: false,
        contractId: '',
        subtotal: 0,
    }
    const renewStateReducerV2 = (state: RenewState, action: {type: string; data: any}) => {
        const {
            initializeRenew,
            setProcessing,
            setRenew,
            setResult,
            loadPaymentMethods,
            setPaymentMethodId,
            resetPaymentMethods,
            setRenewGuard,
            setRenewError,
            resetRenew,
            setRenewContactSales,
            calculateTax,
            setSubscription,
            setNewPaymentMethod,
            setSubtotal,
        } = RenewSetters

        switch (action.type) {
            case resetRenew:
                return {
                    ...state,
                    ...initialState,
                }
            case initializeRenew:
                return {
                    ...state,
                    renew: {
                        data: action.data,
                        canRenew: true,
                        isInitialized: true,
                    },
                } as any
            case setRenew:
                return {
                    ...state,
                    renew: {
                        data: action.data,
                        canRenew: true,
                        isInitialized: true,
                    },
                } as any
            case setProcessing:
                return {
                    ...state,
                    processingRenew: true,
                }
            case setResult:
                return {
                    ...state,
                    result: {
                        processed: true,
                        data: action.data,
                    },
                }
            case setSubscription:
                return {
                    ...state,
                    contractId: action.data.subscriptions[0].contractId,
                    subscription: action.data.subscriptions[0],
                }
            case loadPaymentMethods:
                return {
                    ...state,
                    payment: {
                        savedPayments: action.data.paymentMethods.savedPayments,
                        paymentMethodsInitialized: true,
                    },
                }
            case setPaymentMethodId:
                return {
                    ...state,
                    payment: {
                        ...state.payment,
                        paymentMethodId: action.data,
                    },
                }
            case setNewPaymentMethod:
                return {
                    ...state,
                    newPayment: {
                        paymentMethodId: action.data.paymentMethodId,
                        paymentMethodType: action.data.paymentMethodType,
                    },
                }
            case calculateTax:
                return {
                    ...state,
                    tax: {
                        data: action.data,
                    },
                }
            case resetPaymentMethods:
                return {
                    ...state,
                    payment: {
                        savedPayments: [],
                        paymentMethodsInitialized: false,
                        setPaymentMethodId: '',
                    },
                }
            case setRenewGuard:
                return {
                    ...state,
                    renewGuard: {
                        canRenew: action.data.canRenew,
                        reason: action.data.message,
                        loadingGuard: false,
                        data: action.data.data,
                    },
                }
            case setRenewContactSales:
                return {
                    ...state,
                    renewGuard: {
                        canRenew: false,
                        reason: 'sales contact form redirect',
                        loadingGuard: false,
                        data: state.renewGuard.data,
                    },
                }
            case setRenewError:
                return {
                    ...state,
                    renewError: true,
                    result: {
                        processed: true,
                    },
                }
            case setSubtotal:
                return {
                    ...state,
                    subtotal: action.data.cart.customerAmount,
                }
            default:
                return state
        }
    }

    const getRenewActions: RenewActions = React.useMemo(() => {
        return {
            activityId: (renewActivityId: string, planId: string) => {
                return api
                    .getPolling(`plans/${planId}/getActivity/${renewActivityId}`, (result) => {
                        if (
                            result.data?.status === 'SUCCESS' &&
                            result.data?.data?.cartPricingCalculated === true
                        ) {
                            return true
                        } else if (!result.data || result.data?.status === 'ERROR') {
                            return true
                        }
                        return false
                    })
                    .then((response: any) => {
                        if (response.status.success) {
                            if (response?.data?.data) {
                                return response.data.data
                            }
                            return response.data
                        } else {
                            throw new Error(response.status.errorMessage)
                        }
                    })
                    .catch((e) => {
                        throw new Error(e)
                    })
            },
            renew: (
                planId: string,
                contractId: string,
                quantity: number,
                sku: string,
                subscriptionId: string,
                isB2BPilot: boolean,
                additionalQuantity: number,
                shouldUpgrade: boolean
            ) => {
                return api
                    .post(`plans/${planId}/phxrenew`, {
                        contractId: contractId,
                        quantity: quantity,
                        sku: sku,
                        subscriptionId: subscriptionId,
                        isB2BPilot: isB2BPilot,
                        additionalQuantity: additionalQuantity,
                        shouldUpgrade: shouldUpgrade,
                    })
                    .then(validateResponse)
            },
            renewPlan: (payload: RenewPayloadV2, planId: string) => {
                return api.post(`plans/${planId}/checkout`, payload).then(validateResponse)
            },
            loadPaymentMethods: (planId: string) => {
                return api.get(`plans/${planId}/payment-methods`).then(validateResponse)
            },
            getCountryCode: (countryName: string) => {
                return api.get(`country/${countryName}`).then(validateResponse)
            },
            getSubscription: (subscriptionId: string, planId: string) => {
                return api
                    .get(`plans/${planId}/subscription/${subscriptionId}`)
                    .then(validateResponse)
            },

            addPaypalMethod: (planId: string, successMessage: PaypalSuccess) => {
                const body = {
                    paypalBaid: successMessage.paypalBaid,
                    paypalEmail: successMessage.paypalEmail,
                    address: `${successMessage.address.address1} ${successMessage.address.address2}`,
                    city: successMessage.address.city,
                    state: successMessage.address.state,
                    country: successMessage.address.country,
                    zipCode: successMessage.address.postal,
                }
                return createPaypalMethod(
                    state.applicationData.featureConfig.useMulesoftPaypalCreationFlag,
                    planId,
                    body
                ).then(validateResponse)
            },
            canRenew: (planId: string) => {
                return api.get(`plans/${planId}/renew-guard`).then(validateResponse)
            },
            calculateTax: (body: CalculateTaxPayload, planId: string) => {
                return api.post(`plans/${planId}/calculateTax`, body).then(validateResponse)
            },
        }
    }, [state.applicationData.featureConfig.useMulesoftPaypalCreationFlag])

    function validateResponse(response: any) {
        // console.log('validateResponse', response)
        if (response.status.success) {
            return response.data
        } else {
            throw new Error(response.status.errorMessage)
        }
    }

    const context = {
        reducer: useReducer(renewStateReducerV2, initialState),
        actions: getRenewActions,
    }
    return (
        <RenewErrorBoundary>
            <RenewContext.Provider value={context}>{props.children}</RenewContext.Provider>
        </RenewErrorBoundary>
    )
}

export function useRenewState(): {state: RenewState; setter: any; actions: RenewActions} {
    const {reducer, actions} = useContext(RenewContext) as any
    const [state, setter] = reducer
    return {state, setter, actions}
}

interface RenewState {
    tax: any
    subscriptionId: string
    contractId: string
    renew: {
        data: RenewResponse
        isInitialized: boolean
    }
    renewActivity: {
        data: RenewActivity
        isInitialized: boolean
    }
    invoiced: boolean
    processingRenew: boolean
    payment: {
        paymentMethodId: string | null
        savedPayments: Array<any>
        paymentMethodsInitialized: boolean
    }
    newPayment: {
        paymentMethodId: string | null
        paymentMethodType: string
    }
    renewGuard: {
        canRenew: boolean | null
        reason: RenewErrorMessage
        data: any
        loadingGuard: boolean
    }
    result: {
        processed: boolean
        data: RenewResponse
    }
    renewError: boolean
    subscription: any
    subtotal: number
}

interface RenewActions {
    activityId: (renewActivityId: string, planId: string) => Promise<RenewResponse>
    renew: (
        planId: string,
        contractId: string,
        quantity: number,
        sku: string,
        subscriptionId: string,
        isB2BPilot: boolean,
        additionalQuantity: number,
        shouldUpgrade: boolean
    ) => Promise<RenewActivity>
    renewPlan: (payload: RenewPayloadV2, planId: string) => Promise<Renew>
    calculateTax: (body: CalculateTaxPayload, planId: string) => Promise<any>
    loadPaymentMethods: (planId: string) => Promise<SavedPaymentMethods>
    addPaypalMethod: (planId: string, successMessage: PaypalSuccess) => Promise<PayPalId>
    canRenew: (planId: string) => Promise<RenewGuardResponse>
    getSubscription: (subscriptionId: string, planId: string) => Promise<any>
    getCountryCode: (countryName: string) => Promise<any>
}
