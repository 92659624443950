import React from 'react'
import Button from '@pluralsight/ps-design-system-button'
import styles from './thank-you-page.module.scss'
import {config} from '../environment-config'
import {useParams} from 'react-router-dom'
import {ParamTypes} from '../../../definitions/param-types'
import {useExpandContext} from './Expand-Context'
import {ExpandResponseV2} from '../../../definitions/expand'
import {useAppContext} from '../useAppState'
import {PaymentMethod} from '../../../definitions/payment-methods'
import {isQuarterlyPayment, useQuarterlyPayment} from '../utils/quarterly-payment'
import {QUARTER, TOTAL, TOTAL_ANNUAL, TOTAL_QUARTERLY} from '../common/constants'
import {autoApplyWallet} from '../common/utils/autoApplyWallet'
import {calculateFinalPrice} from '../common/utils/calculateFinalPrice'

export default function ThankYouPage() {
    const {state} = useExpandContext()
    const {state: appState} = useAppContext()
    const paymentMethodUsed = state.existingPaymentMethods.filter((paymentMethod) => {
        return paymentMethod.id === state.paymentData.paymentMethodId
    })[0]
    const expand = state.expand
    const expandSuccess = state.expand.loaded
    const firstName = appState.applicationData.user.firstName
    const peopleHost = config.people.url
    const {planId} = useParams() as ParamTypes
    const inviteNewUsersHref = `${peopleHost}/plans/${planId}/people/org`
    const viewInvoiceHref = `${config.billing.url}/billing/history`
    const subHeadingText =
        state.quantityToAdd === 1
            ? `${state.quantityToAdd} license has`
            : `${state.quantityToAdd} licenses have`
    const productName = state.currentSubscription.productName

    return (
        <>
            {expandSuccess ? (
                <div className={styles.layout}>
                    <div className={styles.heading}>
                        Thank You, <span>{firstName}!</span>
                    </div>
                    <div className={styles.subHeading}>
                        {subHeadingText} been added to your <b>{productName}</b> Plan
                    </div>
                    <div className={styles.buttonRow}>
                        <div className={styles.button}>
                            <Button appearance={Button.appearances.stroke} href={viewInvoiceHref}>
                                View invoice
                            </Button>
                        </div>
                        <div className={styles.button}>
                            <Button href={inviteNewUsersHref}>Invite New Users</Button>
                        </div>
                    </div>
                    <hr className={styles.ruling} />
                    <OrderSummary
                        expand={expand}
                        quantityToAdd={state.quantityToAdd}
                        tax={state.taxAmount}
                        productName={productName}
                        discountAmount={state.discountAmount}
                    />
                    <PaymentMethodFormat paymentMethod={paymentMethodUsed} />
                </div>
            ) : (
                <div>Something went wrong</div>
            )}
        </>
    )
}

function PaymentMethodFormat(props: {paymentMethod: PaymentMethod}) {
    if (props.paymentMethod.type === 'PayPal') {
        return (
            <div className={styles.note}>
                Charged to PayPal account:{' '}
                <span style={{display: 'block'}}>{props.paymentMethod.details.paypalEmail}</span>
            </div>
        )
    } else if (props.paymentMethod.type === 'ACH') {
        return (
            <div className={styles.note}>
                Charged to ACH Payment account:{' '}
                <span style={{display: 'block'}}>{props.paymentMethod.id}</span>
            </div>
        )
    } else if (props.paymentMethod.type === 'BankTransfer') {
        return (
            <div className={styles.note}>
                Charged to SEPA Payment account:{' '}
                <span style={{display: 'block'}}>{props.paymentMethod.id}</span>
            </div>
        )
    } else {
        return (
            <div className={styles.note}>
                Charged To Visa ending in {props.paymentMethod.details.cardNumber.slice(-4)}
            </div>
        )
    }
}

function OrderSummary(props: {
    expand: ExpandResponseV2
    quantityToAdd: number
    productName: string
    tax: number
    discountAmount?: number
}) {
    const {state: appState} = useAppContext()
    const {productBillingPeriod = ''} = appState
    const {
        calculatedSubtotal = 0,
        calculatedTaxAmount = 0,
        calculatedTotalPrice = 0,
    } = useQuarterlyPayment(
        appState,
        props.expand?.orderDetails?.totalAmount - props.expand?.orderDetails?.taxAmount,
        props.tax ?? 0,
        props.expand?.orderDetails.totalAmount ?? 0,
        productBillingPeriod
    )
    const totalPrice = calculatedSubtotal + calculatedTaxAmount
    const walletAmount = appState.walletAmount
    const walletFeature = appState.applicationData.featureConfig.enableWalletFeature
    const creditAmount = Number(walletAmount) > Number(totalPrice) ? totalPrice : walletAmount
    const isAutoApplyWallet = autoApplyWallet(walletAmount, totalPrice, walletFeature)
    const currency = props.expand?.orderDetails.currencyISOCode
    const discountAmount =
        props?.discountAmount && props.discountAmount > 0 ? props.discountAmount : null
    const pricePerLicence = discountAmount
        ? (calculatedSubtotal + discountAmount) / props.quantityToAdd
        : calculatedTotalPrice / props.quantityToAdd
    return (
        <div className={styles.orderSum}>
            <div style={{marginBottom: '10px'}}> Order summary</div>
            <div className={styles.row}>
                <div>Plan</div>
                <div>Licenses</div>
                <div>Price</div>
            </div>
            <div className={styles.row}>
                <p>{props.productName}</p>
                <p className={styles.second}>{props.quantityToAdd}</p>
                <p>
                    {formatMoney(pricePerLicence)} {currency}
                </p>
            </div>
            <hr className={styles.ruling1} />
            <div className={styles.midrow}>
                {discountAmount ? (
                    <>
                        <div className={styles.first}>Discounts</div>
                        <div>
                            {props.discountAmount} {currency}
                        </div>
                    </>
                ) : (
                    <></>
                )}
                <div className={styles.first}>{discountAmount ? '(Discounted) ' : ''}Subtotal</div>
                <div>
                    {formatMoney(calculatedSubtotal)} {currency}
                </div>
                <div className={styles.first}>
                    Tax<span className={styles.sym}>✝✝</span>
                </div>
                <div>
                    {formatMoney(calculatedTaxAmount)} {currency}
                </div>
                {isAutoApplyWallet ? (
                    <>
                        <div className={styles.first}>PS Wallet Credit Applied</div>
                        <div>
                            -{Number(creditAmount)?.toFixed(2)} {currency}
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
            <hr className={styles.ruling2} />
            <div className={styles.total}>
                <div>
                    {isQuarterlyPayment(appState, productBillingPeriod ?? '')
                        ? productBillingPeriod === QUARTER
                            ? TOTAL_QUARTERLY
                            : TOTAL_ANNUAL
                        : TOTAL}
                </div>
                <div>
                    {isAutoApplyWallet
                        ? `${formatMoney(
                              calculateFinalPrice(
                                  calculatedTotalPrice + calculatedTaxAmount,
                                  Number(walletAmount)
                              )
                          )} ${currency}`
                        : calculatedTotalPrice === 0
                        ? '--'
                        : `${formatMoney(totalPrice)} ${currency}`}
                </div>
            </div>
        </div>
    )
}

function formatMoney(amount: number) {
    return amount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
