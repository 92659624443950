import Button from '@pluralsight/ps-design-system-button'
import {CodeIcon, LockIcon} from '@pluralsight/ps-design-system-icon'
import * as React from 'react'
import {useState} from 'react'
import {RowHeaderRightIcon} from '../../common/Row-Header/Row-Header'
import Checkbox from '@pluralsight/ps-design-system-checkbox'
import {useModifyState} from '../Modify-Context/modify-context'
import {ModificationsV2} from '../../../../definitions/modify'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../../definitions/param-types'
import {ModifySetters} from '../modify-setters'
import '../../common/Transaction/styles/review-transaction.scss'
import {formatDate} from '../../utils/date-utils/format-date'

export default function ModifyReviewAndConfirm(props: {planId: string}) {
    const {state, setter, actions} = useModifyState()
    const [reviewed, setReviewed] = useState(false)
    const [checkboxError, setCheckboxError] = useState(false)
    let {productOptionId} = useParams() as ParamTypes
    function handleCheck() {
        setReviewed(!reviewed)
        if (checkboxError) {
            setCheckboxError(false)
        }
    }
    function purchase() {
        if (!reviewed) {
            setCheckboxError(true)
        } else {
            const payload: ModificationsV2 = {
                paymentMethodId: state.payment.paymentMethodId,
                paymentGateway: 'Adyen v2.0',
                cartId: state?.preview?.data?.cartId,
            }
            setter({type: ModifySetters.setProcessing, data: null})
            actions
                .checkout(payload)
                .then((res: any) => {
                    if (res?.orderDetails) {
                        let {
                            totalPrice,
                            unitPrice,
                            quantity,
                            product,
                            endDate,
                        } = res?.orderDetails?.orderItems[0].childItems[0]

                        let data = {
                            amount: parseInt(totalPrice),
                            taxAmount: 0,
                            currency: res?.orderDetails?.currencyISOCode,
                            termEndDate: endDate,
                            lineItems: [
                                {
                                    productId: product.productId,
                                    productOptionId: productOptionId,
                                    amount: parseInt(totalPrice),
                                    unitPrice: parseInt(unitPrice),
                                    quantity: quantity,
                                    uom: 'Subscription',
                                    productName: product.sku,
                                },
                            ],
                        }
                        setter({type: ModifySetters.setResult, data})
                    } else {
                        setter({type: ModifySetters.setModifyError, data: null})
                    }
                })
                .catch((e) => {
                    setter({type: ModifySetters.setModifyError, data: null})
                })
        }
    }
    const isOpen = !!state.payment.paymentMethodId
    const addOnMessage = `Your Add-on plan will be billed annually and will renew next on ${formatDate(
        state.preview.data.termEndDate
    )}`
    const checkBoxText =
        'By checking here and continuing, I agree to the Pluralsight Terms of Use unless my company and Pluralsight have executed a written agreement for the services purchased hereunder, in which case such executed agreement shall govern the additional subscriptions purchased hereunder.'
    const renewText =
        'Your selected plan will automatically renew unless you cancel 30 days prior to your renewal date. For further details regarding automatic renewal, cancellations, and our refund policy, please review our Terms of Use.'
    return (
        <>
            <div className='review-transaction'>
                <RowHeaderRightIcon
                    headerText={'Review and confirm'}
                    active={isOpen}
                    complete={isOpen && reviewed}
                    stepNumber={2}
                    alternateText={'Secure checkout'}
                    icon={<LockIcon size={CodeIcon.sizes.medium} />}
                />
                {isOpen && (
                    <div className='review-content'>
                        <div className='add-on-message review-margin'>{addOnMessage}</div>
                        <div className='review-margin'>{renewText}</div>
                        <div style={{display: 'flex'}}>
                            <Checkbox
                                onCheck={handleCheck}
                                error={checkboxError}
                                checked={reviewed}
                                data-testid='review-check'
                            />
                            <span style={{float: 'right'}}>{checkBoxText}</span>
                        </div>
                        <div className='review-margin' />
                        <div className='review-margin'>
                            <Button
                                appearance={Button.appearances.primary}
                                onClick={purchase}
                                data-testid='purchase-button'
                            >
                                Purchase
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
