import {config} from '../environment-config'

export const api = {
    get,
    getPolling,
    post,
    postFile,
    put,
    del,
}

const baseUrl = config.subscriptionLifecycle.apiPathBase

async function get(path: string) {
    return fetch(`${baseUrl}/${path}`).then(surfaceNon200AsError)
}

let apiTimeout: any
let currentWaitTime: number
let retriesLeft: boolean = true

async function fetchPollingApi(
    url: string,
    checkResponseComplete: (data: any) => boolean,
    pollingTimeout: number,
    maxTimeout: number,
    resolve: any,
    reject: any
) {
    console.log(`Polling on ${url} ...`)
    const res = await fetch(retriesLeft ? url : `${url}?retriesLeft=${retriesLeft}`)

    if (res.ok) {
        const result = await res.json()

        console.log(`Response Polling on ${url}`, result)

        if (checkResponseComplete(result)) {
            console.log(`Polling on ${url} => Done`)
            clearTimeout(apiTimeout)
            resolve(result)
        } else {
            currentWaitTime += pollingTimeout
            if (currentWaitTime > 290000 && !(currentWaitTime > maxTimeout)) {
                retriesLeft = false
            }
            console.log(`Polling on ${url} => Waiting ${pollingTimeout}ms`)
            apiTimeout = setTimeout(
                () =>
                    fetchPollingApi(
                        url,
                        checkResponseComplete,
                        pollingTimeout,
                        maxTimeout,
                        resolve,
                        reject
                    ),
                pollingTimeout
            )
        }

        if (currentWaitTime > maxTimeout) {
            clearTimeout(apiTimeout)
            reject(`Calling ${url} take longer than maxTimeout: ${maxTimeout}`)
        }
    } else {
        throw new Error(res.statusText)
    }
}

async function getPolling(
    path: string,
    checkResponseComplete: (data: any) => boolean,
    maxTimeout: number = 5 * 60 * 1000
): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        const pollingTimeout = 10 * 1000
        const url = `${baseUrl}/${path}`
        currentWaitTime = 0
        apiTimeout = setTimeout(
            () =>
                fetchPollingApi(
                    url,
                    checkResponseComplete,
                    pollingTimeout,
                    maxTimeout,
                    resolve,
                    reject
                ),
            pollingTimeout
        )
    })
}

async function post(path: string, body?: any) {
    return fetch(`${baseUrl}/${path}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {'content-type': 'application/json'},
    }).then(surfaceNon200AsError)
}

async function postFile(path: string, body: any) {
    return fetch(`${baseUrl}/${path}`, {
        method: 'POST',
        body: body,
        headers: {'content-type': 'text/plain'},
    }).then(surfaceNon200AsError)
}

async function put(path: string, body?: any) {
    return fetch(`${baseUrl}/${path}`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {'content-type': 'application/json'},
    }).then(surfaceNon200AsError)
}

async function del(path: string) {
    return fetch(`${baseUrl}/${path}`, {
        method: 'DELETE',
        headers: {'content-type': 'application/json'},
    }).then(surfaceNon200AsError)
}

function surfaceNon200AsError(res: Response) {
    if (!res.ok) {
        throw new Error(res.statusText)
    } else {
        return res.json()
    }
}
