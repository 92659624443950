import React, {useEffect, useState} from 'react'
import {useAppContext} from '../useAppState'
import {useParams} from 'react-router'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import {ParamTypes} from '../../../definitions/param-types'

export default function InvoiceData(props: any) {
    const {actions, state} = useAppContext()
    const [showLoadingDecorator, setShowLoadingDecorator] = useState(true)
    let {planId} = useParams() as ParamTypes
    useEffect(() => {
        if (!!planId) {
            actions.loadInvoicesV2(planId)
        }
    }, [planId, actions])

    useEffect(() => {
        if (!!state.invoiceData[planId] && state.invoiceData[planId].loaded) {
            setShowLoadingDecorator(false)
        }
    }, [planId, actions, state.invoiceData])

    return <div>{showLoadingDecorator ? <CenteredLoadingDecorator /> : props.children}</div>
}
