import React, {useState} from 'react'
import Link from '@pluralsight/ps-design-system-link'
import TextInput from '@pluralsight/ps-design-system-textinput'
import Form from '@pluralsight/ps-design-system-form'
import Checkbox from '@pluralsight/ps-design-system-checkbox'
import Button from '@pluralsight/ps-design-system-button'
import Drawer from '../Custom-Drawer/drawer'
import {onlyAcceptNumbers} from '../../utils/only-accept-numbers'
import {RowHeader} from '../Row-Header/Row-Header'
import Banner from '@pluralsight/ps-design-system-banner'
import {MaxExpandSubscriptionCount} from '../../../../definitions/expand'
import styles from './Quantity-Selection.module.scss'
import {formatPrice} from '../utils/formatPrice'

export function QuantitySelection(props: {
    continueEnabled: boolean
    currentSubscriptionCount: number
    isOpen: boolean
    quantityToAdd: number
    onCheck: (event: any, checked: boolean, value: string, name: string) => void
    handleQuantityChange: (event: any) => void
    updateQuantity: (quantity: number) => void
    hasAgreedToTerms: boolean
    resetToQuantitySelection: () => void
    contactSales: () => void
    expandLabel?: boolean
    price?: number
    symbol?: string
}) {
    return (
        <Drawer
            isOpen={props.isOpen}
            base={
                <RowHeader
                    active={props.isOpen}
                    complete={!props.isOpen}
                    headerText={'Quantity'}
                    stepNumber={1}
                    resetToStep={props.resetToQuantitySelection}
                />
            }
            children={QuantityDropdown({
                continueEnabled: props.continueEnabled,
                currentSubscriptionCount: props.currentSubscriptionCount,
                quantityToAdd: props.quantityToAdd,
                updateQuantity: props.updateQuantity,
                handleQuantityChange: props.handleQuantityChange,
                onCheck: props.onCheck,
                hasAgreedToTerms: props.hasAgreedToTerms,
                contactSales: props.contactSales,
                expandLabel: props.expandLabel,
                price: props.price,
                symbol: props.symbol,
            })}
        />
    )
}

function QuantityDropdown(props: {
    continueEnabled: boolean
    currentSubscriptionCount: number
    quantityToAdd: number
    handleQuantityChange: (event: any) => void
    updateQuantity: (quantity: number) => void
    onCheck: (event: any, checked: boolean, value: string, name: string) => void
    hasAgreedToTerms: boolean
    contactSales: () => void
    expandLabel?: boolean
    price?: number
    symbol?: string
}) {
    let [termsRequiredError, setTermsRequiredError] = useState(false)
    function submit(
        hasAgreedToTerms: boolean,
        quantity: number,
        updateExternalState: (quantity: number) => void
    ) {
        return () => {
            if (hasAgreedToTerms) {
                updateExternalState(quantity)
            } else {
                setTermsRequiredError(true)
            }
        }
    }
    let maxSubscriptionCount: MaxExpandSubscriptionCount = 1000
    let exceededMaxSubscriptions =
        props.currentSubscriptionCount + props.quantityToAdd > maxSubscriptionCount

    return (
        <Form.VerticalLayout>
            <QuantityInput
                currentLicenceCount={props.currentSubscriptionCount}
                handleQuantityChange={props.handleQuantityChange}
                quantityToAdd={props.quantityToAdd}
                expandLabel={true}
                price={props.price}
                symbol={props.symbol}
            />
            <MaxSubBanner contactSales={props.contactSales} show={exceededMaxSubscriptions} />
            <Form.Divider />
            <Checkbox
                value={'terms'}
                label={
                    <div>
                        <span>By checking here and continuing, I agree to the </span>
                        <Link appearance={Link.appearances.subtle}>
                            <a
                                href={'https://www.pluralsight.com/terms'}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                Pluralsight Terms of Use
                            </a>
                        </Link>{' '}
                        <span>
                            unless my company and Pluralsight have executed a written agreement for
                            the services purchased hereunder, in which case such executed agreement
                            shall govern the additional subscriptions purchased hereunder.
                        </span>
                    </div>
                }
                onCheck={(event: any, checked: boolean, value: string, name: string) => {
                    setTermsRequiredError(false)
                    props.onCheck(event, checked, value, name)
                }}
                checked={props.hasAgreedToTerms}
                error={termsRequiredError}
            />
            <Form.ButtonRow>
                <Button
                    loading={exceededMaxSubscriptions || !props.continueEnabled}
                    className={styles.button}
                    onClick={submit(
                        props.hasAgreedToTerms,
                        props.quantityToAdd,
                        props.updateQuantity
                    )}
                >
                    Continue
                </Button>
            </Form.ButtonRow>
        </Form.VerticalLayout>
    )
}
function MaxSubBanner(props: {contactSales: () => void; show: boolean}) {
    return props.show ? (
        <Banner color={Banner.colors.yellow}>
            To add more subscriptions, please
            <button onClick={() => props.contactSales()} className={styles.bannerButton}>
                Contact sales
            </button>
        </Banner>
    ) : (
        <span />
    )
}

function QuantityInput(props: {
    currentLicenceCount: number
    quantityToAdd: number
    handleQuantityChange: (event: any) => void
    expandLabel?: boolean
    price?: number
    symbol?: string
}) {
    const label = 'How many licenses would you like to add?*'
    // const subLabel = `Current subscription count: ${props.currentLicenceCount}`
    const subLabel = props.expandLabel
        ? [
              `Current license count: ${props.currentLicenceCount}`,
              `Annual Price Per License: ${props.symbol} ${props.price &&
                  formatPrice(props.price)}`,
          ]
        : `Current license count: ${props.currentLicenceCount}`
    const value = props.quantityToAdd === 0 ? '' : props.quantityToAdd
    return (
        <div className={styles.labelStyles}>
            <TextInput
                onChange={props.handleQuantityChange}
                type={'number'}
                value={value}
                label={label}
                subLabel={
                    Array.isArray(subLabel)
                        ? subLabel?.map((label, index) => (
                              <React.Fragment key={index}>
                                  {label}
                                  <br />
                              </React.Fragment>
                          ))
                        : subLabel
                }
                onKeyDown={onlyAcceptNumbers}
            />
        </div>
    )
}
