import Button from '@pluralsight/ps-design-system-button'
import Link from '@pluralsight/ps-design-system-link'
import React, {useState} from 'react'
import {ProductV2, UpsellProduct} from '../../../../definitions/product-state'
import './styles/product-details.scss'
import {formatDate} from '../../utils/date-utils/format-date'
import BusinessCancelModal from '../../common/Business-Cancel-Modal/business-cancel-modal'
import {getDaysUntilDate} from '../../utils/date-utils/get-days-until-date'
import {getHoursUntilDate} from '../../utils/date-utils/get-hours-until-date'
import Tooltip from '@pluralsight/ps-design-system-tooltip'
import {Below} from '@pluralsight/ps-design-system-position'
import {ArrowRightIcon, InfoIcon} from '@pluralsight/ps-design-system-icon'
import {AmountPicker} from '../../Amount-Picker/Amount-Picker'
import {getBundleSku} from '../../common/constants'
import {config} from '../../environment-config'

export default function ProductDetailsV2({
    product,
    planId,
    assignSubsLink,
    isPSProduct,
    upsellProductOptions,
    showUpgradeLink,
    showModifyExp,
    hasUnpaidInvoices,
    isMultiYear,
    isCPQ,
    subscriptionId,
    disableTransactionLinks,
    renewedToUpgradedSubscriptionId,
}: {
    product: BaseProduct
    planId: string
    assignSubsLink: string | null
    isPSProduct: boolean
    upsellProductOptions: UpsellProduct[]
    showUpgradeLink: boolean
    showModifyExp: boolean
    hasUnpaidInvoices: boolean
    isMultiYear: boolean
    isCPQ: boolean
    subscriptionId: string
    disableTransactionLinks: boolean
    renewedToUpgradedSubscriptionId: string | null
}) {
    const [isClicked, setIsClicked] = useState<boolean>(false)
    const [iscopied, setIsCopied] = useState<boolean>(false)
    const baseProduct = product.baseProduct
    const sku = baseProduct.productCatalogInfo.productOption.sfdcProductId
    let bundleSku = getBundleSku(sku)
    const canExpand = bundleSku.includes('invalid for bundle')
    const currentOrFutureAddons = product.currentOrFutureAddons
    const showRecommendations = false // TODO: Temporary remove Addons for now
    // !cannotPurchaseAddOn(hasUnpaidInvoices, isMultiYear, baseProduct) &&
    // showModifyExp &&
    // upsellProductOptions.length > 0 &&
    // (currentOrFutureAddons.length === 0 ||
    //     !hasLabsAddOn(currentOrFutureAddons, upsellProductOptions))
    const hasFutureStartDate = !baseProduct.productInfo.inTerm
    const productSku = baseProduct.productCatalogInfo.product.sku.includes('PSONE')
    const productID = baseProduct.productCatalogInfo.productOption.id
    const handleCopy = () => {
        return !iscopied
            ? (navigator.clipboard.writeText(productID), setIsCopied(!iscopied))
            : (navigator.clipboard.writeText(''), setIsCopied(!iscopied))
    }
    const tooltip = (
        <Tooltip tailPosition={Tooltip.tailPositions.topCenter}>
            <p>Product ID: {productID}</p>
            <Button
                appearance={Button.appearances.flat}
                size={Button.sizes.xSmall}
                onClick={handleCopy}
            >
                {iscopied ? 'Copied!' : 'Copy'}
            </Button>
        </Tooltip>
    )
    return (
        <div className='productsLayout'>
            {!!baseProduct &&
                formatBaseProduct(
                    planId,
                    baseProduct,
                    assignSubsLink,
                    isPSProduct,
                    showUpgradeLink,
                    productSku,
                    isClicked,
                    setIsClicked,
                    tooltip,
                    subscriptionId,
                    disableTransactionLinks,
                    canExpand,
                    renewedToUpgradedSubscriptionId
                )}
            {product.hasSpokes && formatAddOnProducts(currentOrFutureAddons)}
            {productSku
                ? null
                : showRecommendations &&
                  !hasFutureStartDate &&
                  FormatRecommendedProducts(
                      upsellProductOptions,
                      planId,
                      subscriptionId,
                      baseProduct
                  )}
        </div>
    )
}

//todo remove when labs is active
// eslint-disable-next-line
function cannotPurchaseAddOn(
    hasUnpaidInvoices: boolean,
    isMultiYear: boolean,
    baseProduct: ProductV2
) {
    const billingPeriod = baseProduct.productCatalogInfo.productOption.billingPeriod
    const hasSpecialBilling = !!billingPeriod && billingPeriod !== 'Annual'
    return (
        hasUnpaidInvoices ||
        isMultiYear ||
        hasSpecialBilling ||
        baseProduct.productCatalogInfo.productOption.free ||
        baseProduct.productCatalogInfo.productOption.customerSource !== 'Pluralsight' ||
        baseProduct.productCatalogInfo.product.type !== 'Skills'
    )
}
//todo remove when labs is active
// eslint-disable-next-line
function hasLabsAddOn(addOns: ProductV2[], upsellProductOptions: UpsellProduct[]): boolean {
    const upsellProductOptionId = upsellProductOptions[0].productOptionId
    return addOns.some((addOn) => addOn.productInfo.productOptionId === upsellProductOptionId)
}

interface BaseProduct {
    baseProduct: ProductV2
    currentOrFutureAddons: ProductV2[]
    hasSpokes: boolean
    reccomendedProducts?: any
}

function formatBaseProduct(
    planId: string,
    baseProduct: ProductV2,
    assignSubsLink: string | null,
    isPSProduct: boolean,
    showUpgradeLink: boolean,
    productSku: boolean,
    isClicked: boolean,
    setIsClicked: (clicked: boolean) => void,
    tooltip: JSX.Element,
    subscriptionId: string,
    disableTransactionLinks: boolean,
    canExpand: boolean,
    renewedToUpgradedSubscriptionId: string | null
) {
    const hasFutureStartDate = !baseProduct.productInfo.inTerm
    const newPricingAndPackageProducts: string[] = ['AI + Data', 'Security + Cloud']
    const productLinkEnabled = !disableTransactionLinks && planId && subscriptionId
    const isPilotAndNewPricingAndPackageSku: boolean =
        isPilot(baseProduct) &&
        newPricingAndPackageProducts.includes(baseProduct.productCatalogInfo.product.name)
    const upgradeUrl = isPilotAndNewPricingAndPackageSku
        ? `${config.www.url}/buy?sku=EVERY-NEW-B2B-A&quantity=${baseProduct.productInfo.quantity}`
        : `${config.subscriptionLifecycle.browserPath}/transaction/upgrade/${planId}/${subscriptionId}?acta=b2b_uue_click_upgrade`

    return (
        <div className='productBox'>
            <div className='plan'>PLAN</div>
            {productSku ? (
                <div className='product-links'>{null}</div>
            ) : (
                <div className='product-links'>
                    {!!assignSubsLink && (
                        <>
                            {isPSProduct && (
                                <>
                                    {showUpgradeLink && (
                                        <>
                                            <Link
                                                className={
                                                    productLinkEnabled ? '' : 'link-disabled'
                                                }
                                            >
                                                <a href={upgradeUrl}>Upgrade</a>
                                            </Link>
                                            <span> | </span>
                                        </>
                                    )}
                                    <Link className={productLinkEnabled ? '' : 'link-disabled'}>
                                        {canExpand ? (
                                            <a
                                                href={`${config.subscriptionLifecycle.browserPath}/plans/${planId}/product/${baseProduct.productInfo.productId}/expand/cpq-expand`}
                                            >
                                                Add Licenses
                                            </a>
                                        ) : (
                                            <a
                                                href={`${
                                                    config.subscriptionLifecycle.browserPath
                                                }/transaction/expand/${planId}/${renewedToUpgradedSubscriptionId ??
                                                    subscriptionId}?acta=b2b_uue_click_add_new`}
                                            >
                                                Add Licenses
                                            </a>
                                        )}
                                    </Link>
                                    <span> | </span>{' '}
                                </>
                            )}
                            <Link>
                                <a href={assignSubsLink}>Assign Licenses</a>
                            </Link>
                        </>
                    )}
                </div>
            )}
            <div className='product'>
                {baseProduct.productCatalogInfo.product.name}
                <div className='toolTip'>
                    <Below show={tooltip} when={isClicked}>
                        <InfoIcon
                            color={InfoIcon.colors.textIconLowOnDark}
                            onClick={() => setIsClicked(!isClicked)}
                        />
                    </Below>
                </div>
                {isPilot(baseProduct) && (
                    <div className='trialDetailsBox'>
                        <div className={'trialDetailsText'}>
                            <b>
                                {baseProduct.productCatalogInfo.product.name ===
                                'Business Professional'
                                    ? 'TEAM TRIAL '
                                    : 'PILOT '}
                            </b>
                            | ENDS IN {getDaysUntilDate(baseProduct.productInfo.expiresAt)} DAYS
                        </div>
                    </div>
                )}
            </div>

            <div className='count'>{getProductQuantityAndUom(baseProduct)}</div>
            {hasFutureStartDate && (
                <div className='future-available-date'>
                    Available {formatDate(baseProduct.productInfo.startsAt)}
                </div>
            )}
        </div>
    )
}

function getProductQuantityAndUom(product: ProductV2) {
    const uom = product.productCatalogInfo.productOption.uom
    const quantity = product.productInfo.quantity
    const hasUom = !!uom
    return hasUom
        ? `${quantity} ${uom.replace('Subscription', 'License')}${quantity === 1 ? '' : 's'}`
        : ''
}

function formatAddOnProducts(currentOrFutureAddons: ProductV2[]) {
    return (
        <div className='addOn'>
            {currentOrFutureAddons.map((addOn) => {
                return isPilot(addOn) ? PilotAddon(addOn) : Addon(addOn)
            })}
        </div>
    )
}

function Addon(addOn: ProductV2) {
    const [isClicked, setIsClicked] = useState<boolean>(false)
    const [iscopied, setIsCopied] = useState<boolean>(false)
    let hasFutureStartDate = !addOn.productInfo.inTerm
    const productID = addOn.productCatalogInfo.productOption.id
    const handleCopy = () =>
        !iscopied
            ? (navigator.clipboard.writeText(productID), setIsCopied(!iscopied))
            : (navigator.clipboard.writeText(''), setIsCopied(!iscopied))
    const tooltip = (
        <Tooltip tailPosition={Tooltip.tailPositions.topCenter}>
            <p>Product ID: {productID}</p>
            <Button
                appearance={Button.appearances.flat}
                size={Button.sizes.xSmall}
                onClick={handleCopy}
            >
                {iscopied ? 'Copied!' : 'Copy'}
            </Button>
        </Tooltip>
    )
    return (
        <div className='productBox' key={addOn.productInfo.productOptionId}>
            <div className='addOnHeader'>ADD-ON</div>
            <div className='product-links'>
                <BusinessCancelModal
                    heading='Cancel your add on'
                    hideLink={
                        addOn.productCatalogInfo.productOption.customerSource !== 'Pluralsight'
                    }
                />
            </div>
            <div className='addOnProduct'>
                {addOn.productCatalogInfo.product.name}
                <div className='toolTip'>
                    <Below show={tooltip} when={isClicked}>
                        <InfoIcon
                            className='addOnInfo'
                            color={InfoIcon.colors.textIconLowOnDark}
                            onClick={() => setIsClicked(!isClicked)}
                        />
                    </Below>
                </div>
            </div>
            <div className='addOnCount'>{getProductQuantityAndUom(addOn)}</div>
            {hasFutureStartDate && (
                <div className='future-available-date'>
                    Available {formatDate(addOn.productInfo.startsAt)}
                </div>
            )}
        </div>
    )
}

function PilotAddon(pilotAddOn: ProductV2) {
    return (
        <div className='productBox' key={pilotAddOn.productInfo.productOptionId}>
            <div className='addOnHeader'>ADD-ON</div>
            <div className='product-links'></div>
            <div className='addonPilotRow'>
                <div className='addOnProduct'>{pilotAddOn.productCatalogInfo.product.name}</div>
                <div className='addOnPilot'>
                    Pilot | ends in {getTimeLeftString(pilotAddOn.productInfo.expiresAt)}
                </div>
            </div>
            <div className='addOnCount'>{getProductQuantityAndUom(pilotAddOn)}</div>
        </div>
    )
}

function FormatRecommendedProducts(
    upsellProductOptions: UpsellProduct[],
    planId: string,
    subscriptionId: string,
    baseProduct: any
) {
    const [labsQuantity, setLabsQuantity] = useState(baseProduct.productInfo.quantity)
    function increaseCount() {
        if (labsQuantity >= baseProduct.productInfo.quantity) return
        setLabsQuantity(labsQuantity + 1)
    }
    function decreaseCount() {
        if (labsQuantity < 1) return
        setLabsQuantity(labsQuantity - 1)
    }

    function setCount(quantity: number) {
        if (quantity > baseProduct.productInfo.quantity) return
        quantity = quantity > 0 ? quantity : 1
        setLabsQuantity(quantity)
    }
    return (
        <div>
            <div className='recommendationText'>RECOMMENDED ADD-ONS</div>
            <div className='upsellBox'>
                {upsellProductOptions.map((addOn) => {
                    const upsellHref = `${config.subscriptionLifecycle.browserPath}/transaction/plans/${planId}/productOption/${addOn.productOptionId}/modify/${subscriptionId}/${labsQuantity}?acta=b2b_uue_click_upsell`
                    return (
                        <div className='upsell' key={addOn.productOptionId}>
                            <div className='upsellHeading'>{addOn.productName}</div>
                            <div className='upsellDescription'>
                                Practice lab skills in real AWS, Azure
                                <br />
                                and Google Cloud environments.
                            </div>
                            <div className='upsellFooter'>
                                <div className='upsellText'>
                                    {getPriceText(
                                        addOn.price,
                                        addOn.currency,
                                        addOn.billingPeriod,
                                        addOn.chargeModel,
                                        addOn.uom
                                    )}
                                </div>
                                <div className='upsellQuantityText'>
                                    <span>Licenses</span>
                                    <AmountPicker
                                        numberOfLicences={labsQuantity}
                                        increase={increaseCount}
                                        decrease={decreaseCount}
                                        setAmount={setCount}
                                    />
                                </div>
                                <div className='upsellText'>=</div>
                                <div className='upsellText'>
                                    {getPriceText(
                                        addOn.price * labsQuantity,
                                        addOn.currency,
                                        addOn.billingPeriod
                                    )}
                                </div>
                            </div>
                            <div className='upsellFooter'>
                                <div>
                                    <Button
                                        className='upsellNewButton'
                                        appearance={Button.appearances.stroke}
                                        onClick={() => {
                                            window.location.href = upsellHref
                                        }}
                                        data-testid='upsell-add'
                                    >
                                        Add & Continue to Checkout &nbsp; <ArrowRightIcon />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

function getPriceText(
    price: number,
    currency: string,
    billingPeriod: string,
    chargeModel?: string,
    uom?: string
) {
    const priceWithCurrency = currency === 'USD' ? `$${price}` : `${price} ${currency}`
    chargeModel = chargeModel === 'PerUnit' ? `per` : chargeModel
    billingPeriod = billingPeriod === 'Annual' ? 'yr' : billingPeriod
    if (chargeModel) {
        return `${priceWithCurrency}/${billingPeriod} ${chargeModel} ${uom}`
    } else {
        return `${priceWithCurrency}/${billingPeriod}`
    }
}

function getTimeLeftString(date: string) {
    const daysUntilDate = getDaysUntilDate(date)
    if (daysUntilDate > 0) {
        return `${daysUntilDate} Days`
    } else {
        return `${getHoursUntilDate(date)} Hours`
    }
}

function isPilot(product: ProductV2) {
    return product.productCatalogInfo.productOption.name.toLowerCase().includes('pilot')
}
