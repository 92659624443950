import React from 'react'
import {NavLink as RouterLink} from 'react-router-dom'
import './Nav-Bar.scss'

export function NavBar({linkConfigs}: {linkConfigs: LinkConfig[]}) {
    return (
        <div className='TabLayout'>
            {linkConfigs.map((l, index) => {
                return (
                    <RouterLink
                        to={l.to}
                        key={index}
                        className={({isActive}: {isActive: boolean}) =>
                            'navTabs' + (isActive ? ' activeTab' : '')
                        }
                    >
                        {l.label}
                    </RouterLink>
                )
            })}
        </div>
    )
}

export interface LinkConfig {
    to: string
    label: string
}
