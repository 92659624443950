import React, {useState} from 'react'
import {useParams} from 'react-router'
import Table from '@pluralsight/ps-design-system-table'
import Button from '@pluralsight/ps-design-system-button'
import {useAppContext} from '../useAppState'
import {shortFormatDate} from '../utils/date-utils/format-date'
import {SubLifeContent} from '../Sub-Life-Content/SubLifeContent'
import {config} from '../environment-config'
import {ParamTypes} from '../../../definitions/param-types'
import {Invoice} from '../../../definitions/invoice'
import styles from './Invoice.module.scss'
import {InvoiceDownloadIcon} from './Invoice-Download-Icon'

function InvoiceRowHeader(props: {
    displayPaymentColumnHeader: boolean
    unpaidInvoicesHeader: boolean
}) {
    const displayPaymentHeader = props.displayPaymentColumnHeader ? 'Status' : ''
    const invoiceHeader = props.unpaidInvoicesHeader ? 'Due Date' : 'Payment Date'
    return (
        <Table.Row>
            <Table.Header role='columnheader' scope='col'>
                {styledColumnHeader(invoiceHeader)}
            </Table.Header>
            <Table.Header role='columnheader' scope='col'>
                {styledColumnHeader('Invoice #')}
            </Table.Header>
            <Table.Header role='columnheader' scope='col'>
                {styledColumnHeader('Total')}
            </Table.Header>
            <Table.Header role='columnheader' scope='col'>
                {styledColumnHeader('Balance')}
            </Table.Header>
            <Table.Header role='columnheader' scope='col' align={Table.alignments.center}>
                {styledColumnHeader('Download')}
            </Table.Header>
            <Table.Header role='columnheader' scope='col'>
                {styledColumnHeader(displayPaymentHeader)}
            </Table.Header>
        </Table.Row>
    )
}

function styledColumnHeader(header: string) {
    return <span className={styles.invoiceColumnHeader}>{header}</span>
}

function styledCell(data: any) {
    return <span className={styles.invoiceCell}>{data}</span>
}

function InvoiceDetail(props: InvoiceDetails) {
    const {planId} = useParams() as ParamTypes
    return (
        <Table.Row>
            <Table.Cell>{styledCell(shortFormatDate(props.date))}</Table.Cell>
            <Table.Cell>{styledCell(props.invoiceNumber)}</Table.Cell>
            <Table.Cell>{styledCell(`${props.total} ${props.currency}`)}</Table.Cell>
            <Table.Cell>{styledCell(`${props.balance} ${props.currency}`)}</Table.Cell>
            <Table.Cell align={Table.alignments.center}>
                <a
                    href={downloadInvoice(props.id, planId as string)}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <InvoiceDownloadIcon />
                </a>
            </Table.Cell>
            {props.balance === '0.00' ? (
                <Table.Cell>{styledCell('PAID')}</Table.Cell>
            ) : (
                <Table.Cell>
                    <button
                        onClick={() =>
                            window.open(`${config.billing.url}/invoice/${props.id}`, '_blank')
                        }
                        className={styles.payInvoiceButton}
                    >
                        Pay Invoice
                    </button>
                </Table.Cell>
            )}
        </Table.Row>
    )
}

function ListInvoiceDetails(props: {invoices: Invoice[]}) {
    return (
        <Table.Body>
            {props.invoices.map((invoice: any) => {
                return (
                    <InvoiceDetail
                        key={invoice.invoiceNumber}
                        id={invoice.id}
                        balance={formatNumber(invoice.balance)}
                        date={invoice.dueDate}
                        invoiceNumber={invoice.invoiceNumber}
                        total={formatNumber(invoice.amount)}
                        currency={invoice.currency}
                    />
                )
            })}
        </Table.Body>
    )
}

function InvoiceTable(props: {
    invoices: Invoice[]
    displayPaymentColumnHeader: boolean
    titleText: string
    unpaidInvoicesHeader: boolean
}) {
    const [displayAllInvoices, setDisplayAllInvoices] = useState(false)
    const displayLoadMoreButton = props.invoices.length > 5 && !displayAllInvoices
    const firstFiveInvoices = props.invoices.slice(0, 4)
    const invoicesToDisplay = displayAllInvoices ? props.invoices : firstFiveInvoices
    return (
        <SubLifeContent label={props.titleText}>
            <div className={styles.invoiceTable}>
                <Table className={styles.scrollableTable}>
                    <Table.Head>
                        <InvoiceRowHeader
                            displayPaymentColumnHeader={props.displayPaymentColumnHeader}
                            unpaidInvoicesHeader={props.unpaidInvoicesHeader}
                        />
                    </Table.Head>
                    <hr className={styles.tableHeaderLine} />
                    <ListInvoiceDetails invoices={invoicesToDisplay} />
                    {displayLoadMoreButton && (
                        <Button
                            onClick={() => setDisplayAllInvoices(true)}
                            appearance={Button.appearances.stroke}
                            className={styles.loadMoreButton}
                        >
                            Load More
                        </Button>
                    )}
                </Table>
            </div>
        </SubLifeContent>
    )
}

function UnpaidInvoices(props: {invoices: Invoice[]}) {
    return (
        <InvoiceTable
            invoices={props.invoices}
            displayPaymentColumnHeader={false}
            titleText={'Unpaid invoices'}
            unpaidInvoicesHeader={true}
        />
    )
}

export function Invoices() {
    const {planId} = useParams() as ParamTypes
    const {state} = useAppContext()
    const hasUnpaidInvoices = state.invoiceData[planId].data.unpaidInvoices.length > 0
    return (
        <div>
            {hasUnpaidInvoices && (
                <div className={styles.sectionWrap}>
                    <UnpaidInvoices invoices={state.invoiceData[planId].data.unpaidInvoices} />
                </div>
            )}
        </div>
    )
}

function downloadInvoice(invoiceId: string, planId: string) {
    return `/subscription-lifecycle/api/${planId}/${invoiceId}/download`
}

function formatNumber(num: number) {
    return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

interface InvoiceDetails {
    id: string
    date: string
    invoiceNumber: string
    total: string
    balance: string
    currency: string
}
