import * as React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import Link from '@pluralsight/ps-design-system-link'
import {config} from '../environment-config'

export function PlanLink(props: {linkText: string; planId: string}) {
    return (
        <Link>
            <RouterLink to={`${config.subscriptionLifecycle.browserPath}/plans/${props.planId}`}>
                {props.linkText}
            </RouterLink>
        </Link>
    )
}
