import Button from '@pluralsight/ps-design-system-button'
import React from 'react'
import './styles/renewQuantityModal.scss'
import {ReactComponent as InfoIcon} from '../../images/info-icon.svg'
import {ReactComponent as GreenTickIcon} from '../../images/green-tick-icon.svg'
import {currencySymbols, monthlyPriceForEverythingPlan} from '../../common/constants'
import {config} from '../../environment-config'

const getContactSalesMessage = (isMultiYear: boolean = false, hasAddOns: boolean = false) => {
    const replaceText =
        isMultiYear && hasAddOns
            ? 'multi-year plans and plans with add-ons'
            : isMultiYear
            ? 'multi-year plans'
            : hasAddOns
            ? 'plans with add-ons'
            : ''
    return `We need more information to renew ${replaceText}. Please contact our sales team to renew your organization’s subscription.`
}

export default function RenewWithUpgradeModal(props: {
    setShowUpgradeWithRenewModal: any
    planId?: string
    isMultiYear?: boolean
    hasAddOns?: boolean
    setShouldUpgradeWithRenew?: any
    setOpen?: any
    currency?: string
}) {
    const planID = props.planId!
    const contactSalesUrl = `${config.subscriptionLifecycle.browserPath}/plans/${planID}/contact-sales/renew`
    const currency = props.currency!
    const currencySign = !!currencySymbols[currency] ? currencySymbols[currency] : ''
    const price = !!monthlyPriceForEverythingPlan[currency]
        ? monthlyPriceForEverythingPlan[currency]
        : ''

    if (props.isMultiYear || props.hasAddOns) {
        return (
            <div className='info-container'>
                <div id='info-icon' className='center-info'>
                    <InfoIcon />
                </div>
                <div className='error-body center-info'>
                    {getContactSalesMessage(props.isMultiYear, props.hasAddOns)}
                </div>
                <div className='error-action center-info'>
                    <a href={contactSalesUrl}>
                        <Button className='continue-button'>Contact Sales</Button>
                    </a>
                </div>
            </div>
        )
    }

    const handleUpgrade = (value: Boolean) => {
        props.setShowUpgradeWithRenewModal(false)
        props.setShouldUpgradeWithRenew(value)
    }

    return (
        <div className='container'>
            <div className='content-heading'>
                <button
                    className='close-button'
                    onClick={() => {
                        props.setShowUpgradeWithRenewModal(false)
                        props.setOpen(false)
                    }}
                >
                    &times;
                </button>
                <h2 className='renew-text-headind'>Renew Subscription</h2>
            </div>
            <div className='special-text'>
                <div className='special-offer'>Special Offer</div>
            </div>
            <div className='upgrade-plan-details'>
                <div className='upgrade-everything-text'>Upgrade to Everything Plan</div>
                <div className='copy-text'>{`${currencySign}${price}/month per license`}</div>
                <div className='what-included-text'>You’ll unlock:</div>
                <div className='plan-include-details'>
                    <div>
                        <GreenTickIcon className='green-tick-mark' />
                        Our full library of 6,500+ courses
                    </div>
                    <div>
                        <GreenTickIcon className='green-tick-mark' />
                        All hands-on labs and learning paths
                    </div>
                    <div>
                        <GreenTickIcon className='green-tick-mark' />
                        AI and custom cloud sandboxes
                    </div>
                    <div>
                        <GreenTickIcon className='green-tick-mark' />
                        All certification practice exams
                    </div>
                </div>
                <div className='renew-button'>
                    <a href='javascript:void(0)'>
                        <Button className='upgrade-button' onClick={() => handleUpgrade(true)}>
                            Upgrade Now
                        </Button>
                    </a>
                </div>
                <div className='renew-button'>
                    <a href='javascript:void(0)'>
                        <Button
                            className='current-plan-button'
                            onClick={() => handleUpgrade(false)}
                        >
                            Continue with Current Plan
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    )
}
