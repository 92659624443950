import Button from '@pluralsight/ps-design-system-button'
import React, {useEffect, useState} from 'react'
import './styles/renewQuantityModal.scss'
import {useAppContext} from '../../useAppState'
import {ReactComponent as InfoIcon} from '../../images/info-icon.svg'
import {config} from '../../environment-config'

const INR_MAX_LICENSES_ALLOWED = 130
const ZAR_MAX_LICENSES_ALLOWED = 55

const getContactSalesMessage = (isMultiYear: boolean = false, hasAddOns: boolean = false) => {
    const replaceText =
        isMultiYear && hasAddOns
            ? 'multi-year plans and plans with add-ons'
            : isMultiYear
            ? 'multi-year plans'
            : hasAddOns
            ? 'plans with add-ons'
            : ''
    return `We need more information to renew ${replaceText}. Please contact our sales team to renew your organization’s subscription.`
}

export default function RenewQuantityModal(props: {
    setOpen: any
    planId?: string
    subscriptionId?: string
    subscriptionQuantity?: number
    currentProductName?: string
    isMultiYear?: boolean
    hasAddOns?: boolean
    shouldUpgrade?: boolean | null
    setShouldUpgradeWithRenew?: any
    setShowUpgradeWithRenewModal?: any
}) {
    const [seats, setSeats] = useState(0)
    const [maxLicensesAllowed, setMaxLicensesAllowed] = useState(999)
    const [isMaxSeatError, setIsMaxSeatError] = useState(false)
    const [isRenewButtonDisabled, setIsRenewButtonDisabled] = useState(false)

    const currentPlanSeats = props.subscriptionQuantity!
    const planID = props.planId!
    const shouldUpgrade = props?.shouldUpgrade ?? false
    const link = `${config.subscriptionLifecycle.browserPath}/transaction/renew/${planID}/${props.subscriptionId}/${seats}/?acta=b2b_uue_click_renew&shouldUpgrade=${shouldUpgrade}`
    const contactSalesUrl = `${config.subscriptionLifecycle.browserPath}/plans/${planID}/contact-sales/renew`
    const {state} = useAppContext()

    useEffect(() => {
        const currency: string = state.businessAccountMap[planID]?.data?.billingInfo?.currency
        if (currency === 'INR') setMaxLicensesAllowed(INR_MAX_LICENSES_ALLOWED)
        else if (currency === 'ZAR') setMaxLicensesAllowed(ZAR_MAX_LICENSES_ALLOWED)
    }, [state.businessAccountMap, planID])

    useEffect(() => {
        if (seats + currentPlanSeats <= maxLicensesAllowed) {
            setIsMaxSeatError(false)
            setIsRenewButtonDisabled(false)
        } else {
            setIsMaxSeatError(true)
            setIsRenewButtonDisabled(true)
        }
    }, [seats, maxLicensesAllowed, currentPlanSeats])

    const handleSeatChange = (e: any) => {
        if (typeof e === 'object') {
            let enteredSeats = e.target.value
            enteredSeats = Number(enteredSeats.replace(/[^0-9]/g, ''))
            setSeats(Math.min(enteredSeats, 999))
        } else {
            setSeats(seats + e)
        }
    }

    if (props.isMultiYear || props.hasAddOns) {
        return (
            <div className='info-container'>
                <div id='info-icon' className='center-info'>
                    <InfoIcon />
                </div>
                <div className='error-body center-info'>
                    {getContactSalesMessage(props.isMultiYear, props.hasAddOns)}
                </div>
                <div className='error-action center-info'>
                    <a href={contactSalesUrl}>
                        <Button className='continue-button'>Contact Sales</Button>
                    </a>
                </div>
            </div>
        )
    }

    return (
        <div className='container'>
            <div className='content-heading'>
                <button
                    className='close-button'
                    onClick={() => {
                        props.setShouldUpgradeWithRenew(false)
                        props.setOpen(false)
                        props.setShowUpgradeWithRenewModal(true)
                    }}
                >
                    &times;
                </button>
                <h2 className='renew-text-headind'>Renew Subscription</h2>
            </div>
            <div className='para-text'>
                Would you like to add more licenses to your subscription?
            </div>
            <h5>Number of licenses</h5>
            <div className='Border-container'>
                <div className='plan-details'>
                    <div>
                        <p className='current-plan-text'>Current Plan</p>
                        <p className='sku-text'>{props.currentProductName}</p>
                    </div>
                    <div>
                        <p className='seats-text'>{currentPlanSeats} Licenses</p>
                    </div>
                </div>
                <hr className='horizontal-line' />
                <div className='seat-adjustment'>
                    <div>
                        <p className='addtional-seats-text'>Additional Licenses</p>
                    </div>
                    <div className='quantity-button'>
                        <button
                            onClick={() => handleSeatChange(-1)}
                            disabled={seats <= 0}
                            className={seats <= 0 ? 'dis-button' : 'active-button'}
                        >
                            -
                        </button>
                        <input
                            value={seats}
                            style={{
                                color: '#FFFFFF',
                                fontWeight: 350,
                                fontSize: '15px',
                                border: 'none',
                                background: 'none',
                                outline: 'none',
                                marginTop: '2px',
                                maxWidth: seats >= 100 ? '33.5%' : '24%',
                                marginLeft: seats <= 99 || seats === 111 ? '9px' : '',
                                marginRight: seats >= 20 && seats <= 99 ? '2px' : '',
                            }}
                            onChange={handleSeatChange}
                        />
                        <button
                            onClick={() => handleSeatChange(1)}
                            disabled={seats >= 999}
                            className={seats >= 999 ? 'dis-button' : 'active-button'}
                        >
                            +
                        </button>
                    </div>
                </div>
            </div>
            {isMaxSeatError && (
                <div className='error-box'>
                    To add more than {maxLicensesAllowed} total licences, please&nbsp;
                    <a href={contactSalesUrl} style={{color: '#000'}}>
                        contact sales.
                    </a>
                </div>
            )}
            <div className='button-Section'>
                <Button
                    className='go-back-button'
                    appearance={Button.appearances.flat}
                    onClick={() => {
                        props.setShouldUpgradeWithRenew(false)
                        props.setOpen(false)
                        props.setShowUpgradeWithRenewModal(true)
                    }}
                >
                    Go Back
                </Button>
                <a href={link}>
                    <Button className='continue-button' disabled={isRenewButtonDisabled}>
                        Continue to Renew
                    </Button>
                </a>
            </div>
        </div>
    )
}
