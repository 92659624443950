import {Route, Routes} from 'react-router-dom'
import React from 'react'
import CacheProvisioning from '../Cache-Provisioning/cache-provisioning'
import styles from './team-support.module.scss'
import {B2bUpgradeRequests} from './b2b-upgrade-requests'
import {NavBar} from '../Nav-Bar/Nav-Bar'
import {PromotionStateProvider} from './promotion-configuration/promotion-configuration-context'
import PromotionConfigurationRoutes from './promotion-configuration/promotion-configuration-routes'
import BatchUpdateSubscriptions from './BatchExtend/batchExtendSubscriptions'
import PhoenixProduct from './phoenix-product'
import {config} from '../environment-config'

export default function TeamSupport() {
    const links = [
        {
            to: `${config.subscriptionLifecycle.browserPath}/team/cache-provisioning`,
            label: 'Cache Provisioning',
        },
        {
            to: `${config.subscriptionLifecycle.browserPath}/team/transaction-permissions`,
            label: 'Transaction Permissions',
        },
        {
            to: `${config.subscriptionLifecycle.browserPath}/team/b2b-upgrade-requests`,
            label: 'B2B Upgrade requests',
        },
        {
            to: `${config.subscriptionLifecycle.browserPath}/team/promotion-config`,
            label: 'Promotion Config',
        },
        {
            to: `${config.subscriptionLifecycle.browserPath}/team/batch-extend`,
            label: 'Batch Extend Subscription',
        },
        {
            to: `${config.subscriptionLifecycle.browserPath}/team/phoenix-product`,
            label: 'Phoenix Product',
        },
    ]
    return (
        <div className={styles.contentArea}>
            <NavBar linkConfigs={links} />
            <Routes>
                <Route path={'cache-provisioning'} element={<CacheProvisioning />} />
                <Route path={'b2b-upgrade-requests'} element={<B2bUpgradeRequests />} />
                <Route path={'batch-extend'} element={<BatchUpdateSubscriptions />} />
                <Route
                    path={'promotion-config'}
                    element={
                        <PromotionStateProvider>
                            <PromotionConfigurationRoutes />
                        </PromotionStateProvider>
                    }
                />
                <Route path={'phoenix-product'} element={<PhoenixProduct />} />
            </Routes>
        </div>
    )
}
