import React from 'react'
import {ReactComponent as PaypalIcon} from '../images/paypal-payment-method-logo.svg'
import {ReactComponent as VisaIcon} from '../images/visa_expand.svg'
import {ReactComponent as BankIcon} from '../images/bank_icon.svg'
import {WarningIcon} from '@pluralsight/ps-design-system-icon'
import styles from './payment-method.module.scss'

interface CreditCardProps {
    selectFunction: () => void
    selected: boolean
    cardNumber: string
    expires: string
}

interface ExpiredCreditCardProps {
    cardNumber: string
    disabled: boolean
}

interface PayPalProps {
    selectFunction: () => void
    selected: boolean
    paypalEmail: string
}

interface ACHProps {
    bankName: string
    bankAccountType: string
    bankAccountNumber: string
    selectFunction: () => void
    selected: boolean
    key: string
}

interface SEPAProps {
    bankName: string
    bankTransferType: string
    bankAccountName: string
    bankAccountNumberMask: string
    selectFunction: () => void
    selected: boolean
    key: string
}

export function CreditCardPaymentButton(props: CreditCardProps) {
    let lastFour = props.cardNumber?.substring(props.cardNumber?.length - 4)
    let ariaLabel = `Visa ${lastFour}`

    return (
        <button
            className={styles.paymentMethod}
            onClick={props.selectFunction}
            aria-label={ariaLabel}
        >
            <div>
                <VisaIcon />
            </div>
            <div className={styles.creditCardMargin}>
                <div className={styles.paymentButtonHeader}>Payment Card</div>
                <div className={styles.paymentCard}>ending in {lastFour}</div>
            </div>
            <span className={`${styles.dot} ${props.selected ? styles.activeDot : ''}`}>
                {props.selected && <span className={styles.checkmark} />}
            </span>
        </button>
    )
}

export function ExpiredCreditCardDisplay(props: ExpiredCreditCardProps) {
    let lastFour = props.cardNumber?.substring(props.cardNumber?.length - 4)
    if (props.disabled) {
        return null
    }
    return (
        <div className={styles.expiredPaymentMethod}>
            <div className={styles.visaInfo}>
                <div>
                    <VisaIcon />
                </div>
                <div className={styles.cardDisplayMargin}>
                    <div className={styles.paymentButtonHeader}>Payment Card</div>
                    <div>ending in {lastFour}</div>
                </div>
            </div>
            <div className={styles.errorLabel}>
                <WarningIcon color={WarningIcon.colors.red} size={WarningIcon.sizes.xSmall} />
                <span style={{marginLeft: '5px'}}>Card expired, please add new one below</span>
            </div>
        </div>
    )
}

export function PayPalPaymentButton(props: PayPalProps) {
    let ariaLabel = `Paypal ${props.paypalEmail}`
    return (
        <button
            className={styles.paymentMethod}
            onClick={props.selectFunction}
            aria-label={ariaLabel}
        >
            <div>
                <PaypalIcon />
            </div>
            <div className={styles.paypalMargin}>
                <div className={styles.paymentButtonHeader}>PayPal Account</div>
                <div className={styles.paypalEmail}>{props.paypalEmail}</div>
            </div>
            <span className={`${styles.dot} ${props.selected ? styles.activeDot : ''}`}>
                {props.selected && <span className={styles.checkmark} />}
            </span>
        </button>
    )
}

export function ACHPaymentButton(props: ACHProps) {
    let ariaLabel = `ACH ${props.bankName} ${props.bankAccountType} ${props.bankAccountNumber}`
    return (
        <button
            className={styles.paymentMethod}
            onClick={props.selectFunction}
            aria-label={ariaLabel}
        >
            <div>
                <BankIcon />
            </div>
            <div className={styles.achMargin}>
                <div className={styles.paymentButtonHeader}>ACH Account</div>
                <div className={styles.bankName}>{props.bankName}</div>
                <div className={styles.bankAccountNumber}>ending in {props.bankAccountNumber}</div>
            </div>
            <span className={`${styles.dot} ${props.selected ? styles.activeDot : ''}`}>
                {props.selected && <span className={styles.checkmark} />}
            </span>
        </button>
    )
}

export function SEPAPaymentButton(props: SEPAProps) {
    let ariaLabel = `SEPA ${props.bankName} ${props.bankTransferType} ${props.bankAccountNumberMask}`
    return (
        <button
            className={styles.paymentMethod}
            onClick={props.selectFunction}
            aria-label={ariaLabel}
        >
            <div>
                <BankIcon />
            </div>
            <div className={styles.sepaMargin}>
                <div className={styles.paymentButtonHeader}>SEPA Account</div>
                <div className={styles.bankName}>{props.bankName}</div>
                <div className={styles.bankAccountNumber}>
                    ending in {props.bankAccountNumberMask}
                </div>
            </div>
            <span className={`${styles.dot} ${props.selected ? styles.activeDot : ''}`}>
                {props.selected && <span className={styles.checkmark} />}
            </span>
        </button>
    )
}
