import React from 'react'
import style from './Upgrade-PriceLock-Message.module.scss'
import {ReactComponent as WalletImg} from '../images/wallet.svg'
import {formatDate} from '../utils/date-utils/format-date'
import {UPGRADE_PRICE_LOCK_MESSAGES} from '../common/constants'

export default function DisplayPriceLockMessage(renewalDate: string) {
    const formattedRenewalDate = renewalDate ? formatDate(renewalDate) : ''

    return (
        <>
            <div className={style.priceLockContainer}>
                <div className={style.walletIconWrapper}>
                    <WalletImg className={style.walletIcon} />
                </div>
                <div>
                    <div className={style.headingContainer}>
                        {UPGRADE_PRICE_LOCK_MESSAGES.heading}
                    </div>
                    <p className={style.priceLockText}>
                        {UPGRADE_PRICE_LOCK_MESSAGES.subheading}
                        {formattedRenewalDate && ` on ${formattedRenewalDate}`}
                    </p>
                </div>
            </div>
        </>
    )
}
