import React from 'react'
import style from './Upgrade-Wallet-Message.module.scss'
import {ReactComponent as WalletImg} from '../images/wallet.svg'
import {currencySymbols} from '../common/constants'

interface Props {
    amount: number
    currency: string
}

export default function UpgradeWalletMessage(props: Props) {
    const currencySign = !!currencySymbols[props.currency] ? currencySymbols[props.currency] : ''
    const walletAmount = props.amount || props.amount === 0 ? Math.abs(props.amount).toFixed(2) : 0

    return (
        <div className={style.walletTextContainer}>
            <div className={style.walletIconWrapper}>
                <WalletImg className={style.walletIcon} />
            </div>
            <p className={style.walletText}>
                A credit of {`${currencySign}${walletAmount}`} will be added to your PS Wallet. You
                can view your credit balance in the My Account section.
            </p>
        </div>
    )
}
