export default function getUpgradedProductName(productName: string, showNewProducts: boolean) {
    if (showNewProducts) {
        if (productName === 'Business - Starter') {
            return 'Everything'
        } else if (
            productName?.includes('Enhanced') ||
            productName?.includes('Enterprise') ||
            productName?.includes('Professional') ||
            productName?.includes('AI + Data') ||
            productName?.includes('Security + Cloud') ||
            productName?.includes('Everything')
        ) {
            return 'Everything'
        } else {
            return '--'
        }
    } else {
        if (productName === 'Business - Starter') {
            return 'Everything'
        } else if (productName === 'Business - Professional') {
            return 'Everything'
        } else if (
            productName?.includes('AI + Data') ||
            productName?.includes('Security + Cloud') ||
            productName?.includes('Everything') ||
            productName?.includes('Enterprise')
        ) {
            return 'Everything'
        } else {
            return 'Business'
        }
    }
}
