import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ForbiddenErrorPage} from '@pluralsight/ps-design-system-errors'
import Radio from '@pluralsight/ps-design-system-radio'
import Button from '@pluralsight/ps-design-system-button'
import {EqualColumnLayout} from '@pluralsight/ps-design-system-layout'
import {useAppContext} from '../useAppState'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import styles from './PlanSelection.module.scss'
import {textData} from './Constant'
import {CheckCircleFilledIcon} from '@pluralsight/ps-design-system-icon'
import {config} from '../environment-config'

export function PlanSelection(props: {
    destinationFn: (planId: string, productOptionId: string) => string
}) {
    const navigate = useNavigate()
    const {state, actions} = useAppContext()
    const [planId, setPlanId] = useState('')
    const [indexs, setIndexs] = useState(0)
    const [showLoadingDecorator, setShowLoadingDecorator] = useState(true)
    const productOptionId: string = config.upsellProductOptionId.productOptionId as string
    let path = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
    const adminPlans = state.applicationData.planInfo.admin.map((planId) => {
        const displayName =
            state.planDisplayNames[planId] && state.planDisplayNames[planId].displayName
        return {planId, displayName}
    })

    const clickHandler = async (plan: string, index: number) => {
        setPlanId(plan)
        setIndexs(index)
    }
    useEffect(() => {
        const planIds = state.applicationData.planInfo.admin
        const planDisplayNamesLoaded =
            planIds.filter(
                (planId) =>
                    !!state.planDisplayNames[planId] && !state.planDisplayNames[planId].pending
            ).length === planIds.length
        if (planDisplayNamesLoaded) {
            setShowLoadingDecorator(false)
        } else {
            actions.loadPlanDisplayName(planIds)
        }
    }, [state.applicationData.planInfo.admin, state.planDisplayNames, actions])

    const isAdmin = adminPlans.length > 0

    return (
        <>
            {showLoadingDecorator ? (
                <CenteredLoadingDecorator />
            ) : isAdmin ? (
                <Plans />
            ) : (
                <ForbiddenErrorPage size={ForbiddenErrorPage.sizes.large} />
            )}
        </>
    )

    function Plans() {
        return (
            <div className={styles.planBox}>
                <h2 className={styles.plan_head_text}>{textData[path]}</h2>
                <div>
                    <EqualColumnLayout count={2} className={styles.radio}>
                        {adminPlans.map((plan, index) => (
                            <Radio.Group key={index} name='controlled'>
                                <Radio.Button
                                    value={planId}
                                    className={
                                        planId && indexs === index
                                            ? `${styles.plans} ${styles.btn_disable}`
                                            : styles.plans
                                    }
                                    label={plan.displayName}
                                    onClick={() => {
                                        clickHandler(plan.planId, index)
                                    }}
                                />
                                {planId && indexs === index && (
                                    <CheckCircleFilledIcon
                                        style={{color: '#2EA0D6'}}
                                        className={styles.check_icon}
                                    />
                                )}
                            </Radio.Group>
                        ))}
                    </EqualColumnLayout>
                </div>
                <div className={styles.cont_button}>
                    <Button
                        disabled={planId && productOptionId ? false : true}
                        appearance={Button.appearances.primary}
                        onClick={() => navigates(planId, productOptionId)}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        )
    }

    function navigates(planId: string, productOptionId: string) {
        navigate(props.destinationFn(planId, productOptionId))
    }
}
