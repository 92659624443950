import {colorsBackgroundDark, colorsBlue, colorsTextIcon} from '@pluralsight/ps-design-system-core'
import Dialog from '@pluralsight/ps-design-system-dialog'
import Link from '@pluralsight/ps-design-system-link'
import React from 'react'
import './business-cancel-modal.scss'

export default function BusinessCancelModal(props: {heading: string; hideLink: boolean}) {
    const [isOpen, setOpen] = React.useState(false)
    return (
        <>
            {props.hideLink ? null : (
                <div aria-hidden={isOpen}>
                    <Link>
                        <span onClick={() => setOpen(!isOpen)} style={{cursor: 'pointer'}}>
                            Cancel
                        </span>
                    </Link>
                </div>
            )}
            {isOpen && (
                <Dialog
                    className='xIcon'
                    modal
                    onClose={() => setOpen(false)}
                    aria-label={'cancel-plan'}
                    disableFocusOnMount
                    style={{
                        zIndex: 1,
                        background: colorsBackgroundDark[2],
                        padding: '16px',
                        width: '520px',
                    }}
                >
                    <div className='pop-up-modal'>
                        <div className='pop-up-heading'>
                            <div style={{color: colorsTextIcon.highOnDark}}>{props.heading}</div>
                        </div>
                        <div className='pop-up-text' style={{color: colorsTextIcon.highOnDark}}>
                            We are sorry to see that you are thinking about canceling your
                            Pluralsight subscription. To inquire about removing licenses or
                            canceling your entire plan, please reach out to your Customer Success
                            Manager at Pluralsight directly. If you don’t know who your designated
                            Pluralsight Customer Success Manager is, please email{' '}
                            <span>
                                <Link>
                                    <a
                                        href={`mailto:renewals@pluralsight.com`}
                                        style={{color: colorsBlue[6]}}
                                    >
                                        renewals@pluralsight.com
                                    </a>
                                </Link>
                            </span>
                            .
                        </div>
                    </div>
                </Dialog>
            )}
        </>
    )
}
