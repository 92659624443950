import React, {useEffect, useState} from 'react'
import Dialog from '@pluralsight/ps-design-system-dialog'
import PluralsightLogoMark from '../images/pluralsight-logo-mark.svg'
import styles from './Expand.module.scss'

interface ExpandModalProps {
    isOpen: boolean
    onClose: () => void
}

const ExpandModal: React.FC<ExpandModalProps> = ({isOpen, onClose}) => {
    const [open, setOpen] = useState(isOpen)
    const [logoLoaded, setLogoLoaded] = useState(false)

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    // Preload the Pluralsight logo image when the component mounts
    useEffect(() => {
        const preloadImage = (url: string) => {
            const img = new Image()
            img.src = url
            // Set logoLoaded to true when the image is loaded
            img.onload = () => setLogoLoaded(true)
        }

        preloadImage(PluralsightLogoMark)
    }, [])

    return open ? (
        <>
            <div className={styles.modalOverlay}></div>
            <Dialog
                modal
                onClose={onClose}
                aria-label='Processing'
                className={styles.modal}
                disableCloseButton={true}
                disableCloseOnEscape={true}
                disableCloseOnOverlayClick
            >
                <h3 className={styles.modalHeader}>Updating your Order Summary</h3>
                <hr />
                <div className={styles.modalBody}>
                    {/* Render the logo only if it has been preloaded */}
                    {logoLoaded && <img src={PluralsightLogoMark} alt='Pluralsight Logo' />}
                    <b>
                        Please do not refresh the page,
                        <br /> we are currently updating your cart.
                    </b>
                </div>
            </Dialog>
        </>
    ) : null
}

export default ExpandModal
